// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_BASE_URL: 'http://0b4a8f65.ngrok.io/'
  // API_BASE_URL: 'http://52.8.197.168:7000/', //LIVE
  //API_BASE_URL: 'http://13.56.106.222:8001/',   //Dev
  //OMS_LINK: 'http://54.153.10.250:5004'
  // API_BASE_URL: 'https://api.trukkin.com/',
  // OMS_LINK: 'http://13.56.106.222:5003/',
  API_BASE_URL: "https://api.trukkin.com/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
