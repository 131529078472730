import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
// import {RouteInfo} from "../sidebar/sidebar.component";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { SessionService } from "../../services/session.service";
import { model } from "./../../shared/common.constant";
import { FormControl } from "@angular/forms";

// import {ChildrenItems} from "../sidebar/sidebar.component";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/maps",
    title: "Maps",
    type: "link",
    icontype: "ni-pin-3 trukkin-theme",
  },
  {
    path: "/drivers",
    title: "Drivers",
    type: "link",
    icontype: "ni-pin-3 trukkin-theme",
  },
  {
    path: "/asset-info",
    title: "Asset Info",
    type: "link",
    icontype: "ni-money-coins trukkin-theme",
  },
  {
    path: "/trips",
    title: "Trips",
    type: "link",
    icontype: "ni-pin-3 trukkin-theme",
  },
  {
    path: "/zones",
    title: "Zones",
    type: "link",
    icontype: "ni-pin-3 trukkin-theme",
  },
  {
    path: "/alerts",
    title: "Alerts",
    type: "link",
    icontype: "ni-pin-3 trukkin-theme",
  },
];

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public menuItems: any[];
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  public subscription: any;
  public isInternet = true;
  public username: any;
  public workingCountries = [];
  public countryArray: any;
  checkUrl = true;
  myModel = new model();
  token = "";
  sringifiedCountryArray = "";

  currentCountry = new FormControl();
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.location = location;
    //this.workingCountries = this.myModel.workingCountries;
    //debugger

    // this.currentCountry = "UAE"
    console.log(this.router);
    this.router.events.subscribe((event: Event) => {
      // console.log(this.router.routerState.root.pathFromRoot)

      if (event instanceof NavigationStart) {
        // Show loading indicator
        //debugger
        if (
          event.url == "/maps" ||
          event.url == "/drivers" ||
          event.url == "/trips" ||
          event.url == "/zones" ||
          event.url == "/asset-info" ||
          event.url == "/alerts"
        ) {
          this.checkUrl = true;
        } else {
          this.checkUrl = false;
        }
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    this.countryArray = this.sessionService.get("userWorkingCountry");

    this.token = this.sessionService.get("access_token");
    this.workingCountries = [];
    this.countryArray.forEach((element) => {
      this.workingCountries.push({ value: element, isSelected: false });
    });
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.sringifiedCountryArray = JSON.stringify(this.currentCountry);
    this.subscription = this.sessionService.onlineStatus.subscribe((res) => {
      if (res === false) {
        this.isInternet = res;
        return false;
      }
      this.isInternet = true;
    });
    this.username = this.sessionService.get("username");
    // this.listTitles = ROUTES.filter(listTitle => listTitle);
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  updateCurrentCountry(e) {
    console.log(e);
    if (e.value.length) {
      this.sessionService.set("selectedCountry", e.value);
      this.sessionService.updateWorkingCountry(e.value);
    } else {
      this.sessionService.error("Please select at least one working country!");
    }
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }

  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }

  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }
}
