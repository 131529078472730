import {Component, OnInit} from '@angular/core';
import {model} from './../../shared/common.constant'
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {API_CONSTANT} from './../../shared/api.constants'
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-add-hardware',
    templateUrl: './add-hardware.component.html',
    styleUrls: ['./add-hardware.component.scss']
})
export class AddHardwareComponent implements OnInit {
    model;
    hardwareForm: FormGroup;
    apiConstant;
    constructor(private fb: FormBuilder,
                private api: ApiService,
                private route: ActivatedRoute
    ) {
        this.model = new model();
        this.apiConstant = API_CONSTANT;

    }

    ngOnInit() {
        this.hardwareForm = this.fb.group({
            imeiNumber: new FormControl(''),
            description: new FormControl(''),
            serialNumber: new FormControl(''),
            softwareIgnitionStatus: new FormControl(''),
            ignitionSensingOverride: new FormControl(''),
            odometerOverride: new FormControl(''),
            deviceType: new FormControl(''),
            reportingTimeZone: new FormControl(''),
          simCardId: new FormControl(''),
        });
        this.getListing();
        this.route.queryParams.subscribe(param => {
            if (param) {
                this.model.editId = param.id;
                this.model.heading = 'Edit';
                this.getDataByID();
            }
        });
    }

    getDataByID() {
        let body = {};
        body['hardwareId'] = this.model.editId;
        this.api.getData(this.apiConstant.SIM_CARD.LIST_SIM_CARD_BY_ID.URL, body, true).subscribe(res => {
            if (res.data.length) {
                this.model.alldata = res.data[0];
                this.patchValues();
            }
        });
    }



    patchValues() {
        this.hardwareForm.patchValue({
            gsmNo: this.model.alldata.gsmNo,
            description: this.model.alldata.description,
            serialNumber: this.model.alldata.serialNumber,
            networkProvider: this.model.alldata.networkProvider,
            puk1Code: this.model.alldata.puk1Code,
            puk2Code: this.model.alldata.puk2Code,
            pin1: this.model.alldata.pin1,
            pin2: this.model.alldata.pin2,
        });
    }

    getListing() {

        this.api.getData(this.apiConstant.SIM_CARD.LIST_SIM_CARD.URL, '', true).subscribe(res => {
            if (res.data.length) {
                this.model.alldata = res.data;
            }
        });
    }

    onSubmit() {
        this.api.postData(this.apiConstant.HARDWARE.ADD_HARDWARE.URL, this.hardwareForm.value, true).subscribe(res => {
          console.log('done');
        });
    }




}
