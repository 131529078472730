import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {of, Observable, BehaviorSubject} from 'rxjs';
import {API_CONSTANT} from '../shared/api.constants';
import {SessionService} from './session.service';
import {IndividualConfig, ToastrService} from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiEndpoint: any;
  apiConstants: any;

  config: Partial<IndividualConfig> = {
    timeOut: 1000
  };
  checkOfflineStatus = true;
  constructor(private http: HttpClient, 
    private toastr: ToastrService,
    private sessionService: SessionService) {
    this.apiConstants = API_CONSTANT;
    this.apiEndpoint = environment.API_BASE_URL;

  }

  getData(url, obj, load) {
    let params = new HttpParams();
    Object.keys(obj).forEach(key => {
      if (obj[key] !== '' && obj[key] !== undefined) {
        params = params.set(key, obj[key].trim());
      }
    });
    
    return this.http.get<any>(this.apiEndpoint + url, {params, reportProgress: load});
  }

  postData(url, obj, load) {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
        if(typeof obj[key] === 'object' && obj[key].length){
          formData.append(key, JSON.stringify(obj[key]));
        }
        else
          formData.append(key, obj[key]);
      }
    });
    return this.http.post<any>(this.apiEndpoint + url, formData, { reportProgress: load});
  }



  // post(url, data) {
  //   url = environment.API_ENDPOINT + url;
  //   let headers;
  //   if (localStorage.getItem('access_token')) {
  //     headers = {
  //       authorization: localStorage.getItem('access_token')
  //     };
  //   }
  //   // data.body['marketplace_reference_id'] = this.config.marketplaceReferenceId;
  //   // if (!('language' in data.body)) {
  //
  //   // }
  //   return this.http.post(url, data, {headers});
  // }
  //
  // private mapDataToUrl(data: any) {
  //   if (data.body) {
  //     const obj = {
  //       ...data.body
  //     };
  //
  //     const params = [];
  //     Object.keys(obj).forEach(key => {
  //       params.push(
  //         `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
  //       );
  //     });
  //     return params.join('&');
  //   } else {
  //     return '';
  //   }
  // }
  //
  // public get(data) {
  //   let url = '';
  //
  //   url = environment.API_ENDPOINT + data.url;
  //
  //   const params = this.mapDataToUrl(data);
  //   url += '?' + params;
  //   return this.http
  //     .get(url, {params: data.params})
  //     .pipe(map(this.tookanResponse));
  // }
  //
  //
  // tookanResponse(res: any) {
  //   return res;
  // }
}
