import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { AgmMap } from "@agm/core";
import { API_CONSTANT } from "../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { model } from "../../shared/common.constant";
import { ClusterStyle } from "@agm/js-marker-clusterer/services/google-clusterer-types";
import { SocketService } from "../../services/socket.service";
import { SessionService } from "../../services/session.service";
import { Route, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { IndividualConfig, ToastrService } from "ngx-toastr";

declare var google: any;
// const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
//     mozRequestFullScreen(): Promise<void>;
//     webkitRequestFullscreen(): Promise<void>;
//     msRequestFullscreen(): Promise<void>;
// };
//
// const docWithBrowsersExitFunctions = document as Document & {
//     mozCancelFullScreen(): Promise<void>;
//     webkitExitFullscreen(): Promise<void>;
//     msExitFullscreen(): Promise<void>;
// };
@Component({
  selector: "app-driver",
  templateUrl: "./driver.component.html",
  styleUrls: ["./driver.component.scss"],
})
export class DriverComponent implements OnInit {
  public hasFullView = false;
  public ListingBtn = true;
  private isfullscreen = false;

  constructor(
    private api: ApiService,
    private socket: SocketService,
    private sessionService: SessionService,
    private changeDetection: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService
  ) {


    // this.sessionService.assignCurrentDriver.subscribe(res => {
    //   if(res){
    //   console.log('---assign----')
    //   this.getAssets();}
    // });
    // this.sessionService.unassignCurrentDriver.subscribe(res => {
    //   //console.log('--unassign----')
    //   if(res){
    //     console.log('---assign----')
    //     this.getAssets();}
    // });
    this.clusterStyles = [
      {
        textColor: "#000000",
        url: "assets/img/map-icons/m1.png",
        height: 36,
        width: 58,
        backgroundPosition: "center",
      },
    ];
  }

  @ViewChild("AgmMap", { static: false }) agmMap: AgmMap;
  @ViewChild("searchElement", { static: true }) searchElement: ElementRef;
  @ViewChild("infoWindow", { static: false }) infoWindow: ElementRef;

  clusterStyles: ClusterStyle[];
  zoomIn = 15;
  minZoom = 3;
  infoWindowOpened = null;
  previous_info_window = null;
  myModel = new model();
  apiConstant = API_CONSTANT;
  zoom = 8;
  assetValue = false;
  arrLatLong = [];
  assetFilter = [
    {
      value: "all",
      text: "All",
      selected: false,
      img: "assets/img/grey_driver.png",
    },
    {
      value: "false",
      text: "Assigned Driver",
      selected: false,
      img: "assets/img/green-driver.png",
    },
    {
      value: "true",
      text: "Free Driver",
      selected: false,
      img: "assets/img/red-driver.png",
    },
  ];
  tabOnMap = [];
  searchType = "driverId";
  filterMap = {
    serach: false,
    status: false,
    category: false,
    label: false,
  };
  subCategories = [];
  selectedCategory = "";
  selectedSubCat = "";
  selectedSubCatName = "";
  search = "";
  assignDriver = "all";
  isAll = "false";
  maxsize = 2;

  private mapBounds: any;
  private mapInstance: any;
  private selectedAsset: any;
  public currentCountry: any;
  // public index: any;
  public address: any;
  public tempData;
  latLong = [76.7796, 30.7334];
  url =
    "https://www.google.com.sa/maps/search/" +
    30.7220257 +
    "," +
    76.8462109 +
    "," +
    "12.21z?hl + =en";
  public selectedImei: any;

  openedWindow = 0; // alternative: array of numbers

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");

    fromEvent(this.searchElement.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => {
          console.log(event, "eventttttt");
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2 || res.length === 0),
        // Time in milliseconds between key events
        debounceTime(1000)
        // If previous query is diffent from curren
        // distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.search = text;
        this.searchAssets(text);
      });

    this.sessionService.driverCurrentData.subscribe((res) => {
      // console.log("driver socket get data")
      if (res) {
        this.tempData = res;
        //  console.log("driver socket get data1",res)
        this.arrLatLong.forEach((ele, index) => {
          if (ele.driverId === this.tempData.driverId) {
            // console.log(ele, index, 'before');
            Object.keys(this.tempData).forEach((key) => {
              ele[key] = this.tempData[key];
              this.arrLatLong[index][key] = this.tempData[key];
              // ele['currentLatLong'] = this.latLong;
             // console.log(this.tempData[key], "value change");
            });
          }
        });
        // this.updateBounds();
      }
    });
    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        //console.log("change country", res);
        this.currentCountry = res;
        //  this.clearFilter('all');
        if (this.router.url === "/drivers") {
          // this.clearFilter("all");
          this.getAssets();
        }
      }
    });

    this.getAssets();
  }

  updateBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
    this.arrLatLong.forEach((marker) => {
      if (marker.currentLocation) {
        const tempPosition = new google.maps.LatLng(
          marker.currentLocation[1],
          marker.currentLocation[0]
        );
        this.mapBounds.extend(tempPosition);
      }
    });
    // console.log(this.mapInstance, this.mapBounds);
    this.mapInstance.fitBounds(this.mapBounds);
    this.mapInstance.setZoom(8);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mapBounds = new google.maps.LatLngBounds();
    }, 1000);
    this.agmMap.mapReady.subscribe((map) => {
      this.mapInstance = map;
      this.updateBounds();
    });
  }
  getPageChangeEvent(event) {
    this.myModel.pageNumber = event.page;
    this.myModel.skip = (this.myModel.pageNumber - 1) * 10;
    // this.getAssets();
    let obj = {};
    obj["skip"] = this.myModel.skip;
    obj["limit"] = this.myModel.limit;
    obj["workingCountry"] = this.currentCountry;
    if (this.assignDriver) {
      obj["assignDriver"] = this.assignDriver;
    }
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    this.api
      .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data.list;
          this.myModel.alldata.forEach((e) => {
            e.isCollapsed = true;
            e.showLabel = false;
          });
          this.arrLatLong = res.data.list;
          this.myModel.totalData = res.data.totalData;
        }
      });
  }
  getAssets() {
    this.assignDriver = "all";
    this.myModel.limit = 200;
    let obj = {
      skip: this.myModel.skip,
      limit: this.myModel.limit,
    };
    obj["workingCountry"] = this.currentCountry;
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }

    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    if (this.assignDriver) {
      obj["assignDriver"] = this.assignDriver;
    }
    this.api
      .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data.list;
          this.myModel.alldata.forEach((e) => {
            e.isCollapsed = true;
            e.showLabel = false;
          });
         // console.log(res.data.list);
          this.myModel.totalData = res.data.totalData;
          this.arrLatLong = res.data.list;
          //  this.mapInstance.setZoom(4);
          // this.zoom = 4;
          //this.updateBounds();
        }
      });
  }

  driverInfo(asset) {
    // this.index = i;
    this.selectedImei = asset.driverId;
    this.selectedAsset = asset;
    if (this.selectedAsset.currentLocation) {
      //  this.getaddress(this.selectedAsset);
      this.mapInstance.setCenter(
        new google.maps.LatLng(
          this.selectedAsset.currentLocation[1],
          this.selectedAsset.currentLocation[0]
        )
      );
      this.mapInstance.setZoom(12);
    } else {
      this.toastr.error("No location is fetched from the driver application "+'('+asset.driverId+')');
    }

    // if (type === 'assetList') {
    //     this.close_window();
    // }
  }

  async getaddress(selectedAsset) {
    this.address = await this.sessionService.getAddressusingLatLong(
      selectedAsset.currentLocation[1],
      selectedAsset.currentLocation[0]
    );
  }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }

  select_marker(infoWindow) {
    /*if (this.previous_info_window == null)
             this.previous_info_window = infoWindow;
         else {
             this.infoWindowOpened = infoWindow;
            this.previous_info_window.close();
         }
        this.previous_info_window = infoWindow;*/
  }

  openWindow(id) {
    this.openedWindow = id; // alternative: push to array of numbers

    //console.log(id, 'id')
  }

  openLabel(index) {
    this.arrLatLong[index].showLabel = true;
  }

  isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  // isInfoWindowOpen(index) {
  //     console.log(this.index, index, 'called');
  //     return this.index === index; // alternative: check if id is in array
  // }
  showListing = false;

  calculateOdometerDistance(lat1, lon1, lat2, lon2) {
    return this.sessionService.calculateOdometerDistance(
      lat1,
      lon1,
      lat2,
      lon2
    );
  }

  openTracking(id) {
    this.router.navigate(["/track-driver"], {
      queryParams: { assignmentId: id },
    });
  }

  searchAssets(value) {
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    const obj = {
      newSearchText: value,
      skip: 0,
      limit: this.myModel.limit,
    };
    if (obj.newSearchText) obj["searchType"] = this.searchType;
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }

    /*if(this.assignDriver){
            obj['assignDriver'] = this.assignDriver;
        }*/
    obj["workingCountry"] = this.currentCountry;
    if (this.assignDriver) {
      obj["assignDriver"] = this.assignDriver;
    }
    this.api
      .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data.list;
          this.myModel.alldata.forEach((e) => {
            e.isCollapsed = true;
            e.showLabel = false;
          });
          this.arrLatLong = res.data.list;
          this.myModel.totalData = res.data.totalData;
        }
      });
  }

  fetchAssetsOnCategory() {
    if (this.selectedSubCat) {
      const obj = {
        truckType: [],
      };
      obj["skip"] = 0;
      obj["limit"] = this.myModel.limit;
      obj["workingCountry"] = this.currentCountry;
      obj.truckType.push(this.selectedSubCat);
      if (this.search) {
        obj["searchType"] = this.searchType;
        obj["newSearchText"] = this.search;
      }
      if (this.assignDriver) {
        obj["assignDriver"] = this.assignDriver;
      }
      //  console.log(obj, this.assignDriver);
      this.api
        .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
        .subscribe((res) => {
          if (res.data) {
            this.myModel.alldata = res.data.list;
            this.myModel.alldata.forEach((e) => {
              e.isCollapsed = true;
              e.showLabel = false;
            });
            this.arrLatLong = res.data.list;
            this.myModel.totalData = res.data.totalData;
          }
        });
    }
  }

  filterAssets(i, value) {
    this.assetFilter[i].selected = true;
    this.assetFilter.forEach((key, index) => {
      if (index === i) key.selected = true;
      else key.selected = false;
    });
    this.tabOnMap = [];
    this.tabOnMap = this.assetFilter.filter((k, index) => {
      if (k.selected) return k;
    });
    console.log(this.tabOnMap);
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    this.assignDriver = value;

    if (value == "all" && this.assetFilter[i].selected) {
      this.isAll = value;
    } else {
      this.isAll = "false";
    }

    const obj = {
      assignDriver: value,
    };

    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }

    obj["skip"] = 0;
    obj["limit"] = this.myModel.limit;
    obj["workingCountry"] = this.currentCountry;

    if (this.search) {
     // console.log(this.search,'--------');
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    this.api
      .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data.list;
          this.myModel.alldata.forEach((e) => {
            e.isCollapsed = true;
            e.showLabel = false;
          });
          this.arrLatLong = res.data.list;
          this.myModel.totalData = res.data.totalData;
        }
      });
  }

  updateSubCatList() {
    this.selectedSubCat = "";
    this.myModel.TruckArray.forEach((x) => {
      if (this.selectedCategory === x.value) this.subCategories = x.sub;
    });
    if (this.selectedCategory === "") this.clearFilter("truckType");
  }

  clearFilter(type) {
    if (type === "search") {
      this.searchElement.nativeElement.value = "";
      this.search = "";
    }
    if (type === "status") {
      this.assignDriver = "all";
      this.isAll = "false";
    }
    if (type === "truckType") {
      this.selectedSubCat = "";
      this.selectedCategory = "";
      this.subCategories = [];
    }
    if (type === "all") {
      this.searchElement.nativeElement.value = "";
      this.search = "";
      this.assignDriver = "all";
      this.selectedSubCat = "";
      this.selectedCategory = "";
      this.subCategories = [];
    }

    let obj = {};
    obj["skip"] = 0;
    obj["limit"] = this.myModel.limit;
    if (this.assignDriver) {
      obj["assignDriver"] = this.assignDriver;
    }
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.DRIVER_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data.list;
          this.myModel.alldata.forEach((e) => {
            e.isCollapsed = true;
            e.showLabel = false;
          });
          this.arrLatLong = res.data.list;
          this.myModel.totalData = res.data.totalData;
        }
      });
  }

  trackFilter(index, item) {
    return this.assetFilter ? this.assetFilter : undefined;
  }

  toggleLabels() {
   // console.log(this.filterMap.label);
    if (this.filterMap.label) {
      this.myModel.alldata.forEach((e) => {
        e.showLabel = true;
      });
    } else {
      this.myModel.alldata.forEach((e) => {
        e.showLabel = false;
      });
    }
  }

  /* zoomInMap() {
        this.zoomIn = 10 + this.zoomIn;
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLocation[1], this.selectedAsset.currentLocation[0]));
        this.mapInstance.setZoom(this.zoomIn);

    }*/

  zoomOutMap2() {
    this.zoomIn = 10 - this.zoomIn;
  }
  /* zoomOutMap() {
        this.zoomIn = 10 - this.zoomIn;
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLocation[1], this.selectedAsset.currentLocation[0]));
        this.mapInstance.setZoom(this.zoomIn);
    }*/

  openNav() {
    this.hasFullView = false;
    document.getElementById("myNav").classList.toggle("toggleSideList");
    document.getElementById("myMap").classList.toggle("toggleMap");
    if (document.getElementById("myNav").classList.contains("toggleSideList")) {
      this.hasFullView = true;
    }
  }

  openMap() {
    this.ListingBtn = true;
    document.getElementById("myMap").classList.toggle("mapFullscreen");
    if (document.getElementById("myMap").classList.contains("mapFullscreen")) {
      this.ListingBtn = false;
    }
  }

  onInfoWindowClose() {
    // this.openedWindow = 0;
   // console.log(this.openedWindow);
  }
}
