import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit, OnDestroy {

  @Input() show: boolean;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnChanges() {
    console.log(this.show, 'showwwwww');
    if (this.show) {
      const element = document.getElementById('confirmationModal') as HTMLElement;
      element.click();
    } else {
      this.close();
    }
  }


  ngOnInit() {
  }

  close() {
    let element = document.getElementById('closeModal') as HTMLElement;
    element.click();
  }

  accept() {
    const obj = {}

    obj['show'] = false;
    obj['delete'] = true;
    this.close();
    this.closeModal.emit(obj);

  }

  reject() {
    const obj = {};
    obj['show'] = false;
    obj['delete'] = false;
    this.close();
    this.closeModal.emit(obj);

  }

  handler(){
    this.reject();
  }

  ngOnDestroy(): void {
    this.close();
  }

}
