import {Directive, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GoogleMapsAPIWrapper} from '@agm/core';

export interface ILatLng {
  latitude: number;
  longitude: number;
}

// the will keep typescript from throwing errors w.r.t the google object
declare var google: any;
@Directive({
  selector: '[appDirection]'
})

export class DirectionDirective implements OnInit, OnChanges {
  @Input() origin: ILatLng;
  @Input() destination: ILatLng;
  @Input() showDirection: boolean;

  // We'll keep a single google maps directions renderer instance so we get to reuse it.
  // using a new renderer instance every time will leave the previous one still active and visible on the page
  private directionsRenderer: any;
  

  // We inject AGM's google maps api wrapper that handles the communication with the Google Maps Javascript
  constructor(private gmapsApi: GoogleMapsAPIWrapper) {}
  

  ngOnInit() {
    this.drawDirectionsRoute();
  }

  drawDirectionsRoute() {
      this.gmapsApi.getNativeMap().then(map => {
        if (!this.directionsRenderer) {
          // if you already have a marker at the coordinate location on the map, use suppressMarkers option
          // suppressMarkers prevents google maps from automatically adding a marker for you
          this.directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#6B6B6B",
              strokeOpacity: 0,
              strokeWeight: 4,
              icons: [{
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: '#6B6B6B',
                  fillOpacity: 1,
                  scale: 2,
                  strokeColor: '#6B6B6B',
                  strokeOpacity: 1,
                },
                offset: '0',
                repeat: '10px'
              }]
            },
          
            
           
            
        });
          
        }
        const directionsRenderer = this.directionsRenderer;

        if ( this.showDirection && this.destination ) {
            const directionsService = new google.maps.DirectionsService;
           // const markerArray = new google.maps.Marker;

            
            directionsRenderer.setMap(map);
            
            directionsService.route({
                origin: {lat: this.origin.latitude, lng: this.origin.longitude},
                destination: {lat: this.destination.latitude, lng: this.destination.longitude},
                waypoints: [],
                optimizeWaypoints: true,
                travelMode: google.maps.TravelMode.DRIVING,
                provideRouteAlternatives : true
               
            }, (response, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(response);
                    console.log("latlat",response)
                    
                } else {
                    console.log('Directions request failed due to ' + status);
                }
            });
        }

      });
  }

  

  ngOnChanges(changes: SimpleChanges) {
      if (changes.destination || changes.showDirection) {
          // this checks if the show directions input changed, if so the directions are removed
          // else we redraw the directions
          if (changes.showDirection && !changes.showDirection.currentValue) {
              if (this.directionsRenderer !== undefined) { // check this value is not undefined
                  this.directionsRenderer.setDirections({routes: []});
                  return;
              }
          } else {
              this.drawDirectionsRoute();
          }
      }
  }

}
