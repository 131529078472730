import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { AgmMap } from "@agm/core";
import { ClusterStyle } from "@agm/js-marker-clusterer/services/google-clusterer-types";
import { model } from "../../shared/common.constant";
import { API_CONSTANT } from "../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { SocketService } from "../../services/socket.service";
import { SessionService } from "../../services/session.service";
import * as moment from "moment";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";

declare var google: any;

// declare var moment: any;

@Component({
  selector: "app-trips",
  templateUrl: "./trips.component.html",
  styleUrls: ["./trips.component.scss"],
})
export class TripsComponent implements OnInit {
  @ViewChild("AgmMap", { static: true }) agmMap: AgmMap;
  @ViewChild("searchElement", { static: true }) searchElement: ElementRef;
  //ss@ViewChild('searchElement2', {static: true}) searchElement2: ElementRef;

  @ViewChild("infoWindow", { static: true }) infoWindow: ElementRef;
  clusterStyles: ClusterStyle[];
  public hasFullView = false;
  minZoom = 3;
  selectedItem = "single";
  infoWindowOpened = null;
  showListing = false;
  previous_info_window = null;
  myModel = new model();
  apiConstant = API_CONSTANT;
  zoom = 3;
  assetValue = false;
  arrLatLong = [];
  bsValue = new Date();
  yesterday = new Date();
  tripDate: any;
  bsRangeValue = [this.bsValue, this.bsValue];
  route_imeiNumber: any;
  private mapBounds: any;
  private mapInstance: any;
  private mapInstance2: any;
  public selectedAsset: any;
  public index: any;
  public address: any;
  public tempData;
  latLong = [76.7796, 30.7334];
  url =
    "https://www.google.com.sa/maps/search/" +
    30.7220257 +
    "," +
    76.8462109 +
    "," +
    "12.21z?hl + =en";
  public multipastLocations = [];
  private route_date: any;
  errorMsg = false;
  bsConfig: any;
  currentCountry: any;

  constructor(
    private api: ApiService,
    private socket: SocketService,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.yesterday.setDate(this.bsValue.getDate() - 1);
    this.bsRangeValue = [this.yesterday, this.bsValue];
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
        isAnimated: true,
        containerClass: "theme-orange",
      }
    );
    this.clusterStyles = [
      {
        textColor: "#000000",
        url: "assets/img/map-icons/m1.png",
        height: 36,
        width: 58,
        backgroundPosition: "center",
      },
    ];
  }

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.route.queryParams.subscribe((param) => {
      this.route_imeiNumber = param["imeiNumber"] ? param["imeiNumber"] : "";
      this.route_date = param["date"] ? param["date"] : "";
    });

    fromEvent(this.searchElement.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => {
          console.log(event, "eventttttt");
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2 || res.length === 0),
        // Time in milliseconds between key events
        debounceTime(1000)
        // If previous query is diffent from curren
        // , distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.searchAssets(text);
      });
    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        console.log("change country", res);
        this.currentCountry = res;

        if (this.router.url === "/trips") {
          this.getAssets();
        }

        //this.getAssets();
      }
    });
    this.getAssets();

    /* fromEvent(this.searchElement2.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                console.log(event, 'eventttttt');
                return event.target.value;
            })
            // if character length greater then 2
            , filter(res => (res.length > 2 || res.length === 0))
            // Time in milliseconds between key events
            , debounceTime(1000)
            // If previous query is diffent from curren
            , distinctUntilChanged()
            // subscription for response
        ).subscribe((text: string) => {
            this.searchAssets(text);
        });*/
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.mapBounds = new google.maps.LatLngBounds();
    }, 2000);
    this.agmMap.mapReady.subscribe((map) => {
      this.mapInstance = map;
      // this.updateBounds();

      // this.updateMarkerList();
    });
    // this.agmMap2.mapReady.subscribe(map => {
    //     this.mapInstance = map;
    //     // this.updateBounds();
    // });
  }

  updateBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
    if (this.selectedItem === "single") {
      this.arrLatLong.forEach((marker) => {
        const tempPosition = new google.maps.LatLng(
          marker.latLong[1],
          marker.latLong[0]
        );
        this.mapBounds.extend(tempPosition);
      });
    }
    if (this.selectedItem === "multiple") {
      this.myModel.multipleAssetData.forEach((marker) => {
        marker.pastLocation.forEach((location) => {
          const tempPosition = new google.maps.LatLng(
            location.latLong[1],
            location.latLong[0]
          );
          this.mapBounds.extend(tempPosition);
        });
      });
    }

    this.mapInstance.fitBounds(this.mapBounds);
    this.mapInstance.fitBounds(this.mapBounds);
    // this.mapInstance.setZoom(8);
  }

  getAssets() {
    let obj = {};
    obj["workingCountry"] = this.currentCountry;
    if (this.searchElement.nativeElement.value) {
      obj["searchText"] = this.searchElement.nativeElement.value;
    }
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          // this.arrLatLong = res.data;
          this.selectedAsset = this.myModel.alldata[0];
          console.log(this.selectedAsset);
          //  debugger
          if (this.selectedAsset) {
            this.getSingleAssetTripDetail();
          }
        }
      });
  }

  getDate(event) {
    if (event) {
      this.myModel.myDate = moment(event).format("YYYY-MM-DD");
      if (this.selectedAsset && this.selectedAsset.imeiNumber) {
        this.getSingleAssetTripDetail();
      }
    }
  }

  getMultipleDate(event) {
    if (event.length > 0) {
      this.myModel.startDate = moment(event[0]).format("YYYY-MM-DD");
      this.myModel.endDate = moment(event[1]).format("YYYY-MM-DD");
      if (this.myModel.imeiArray.length) {
        this.getMultipleTripDetails();
      }
    }
  }

  getSingleAssetTripDetail() {
    const obj = {};
    this.arrLatLong = [];
    this.showListing = false;
    if (this.selectedAsset && this.selectedAsset.imeiNumber) {
      obj["imeiNumber"] = this.selectedAsset.imeiNumber;
    }
    if (this.myModel.myDate) {
      obj["date"] = this.myModel.myDate;
    }
    obj["timeZoneOffset"] = new Date().getTimezoneOffset() * -1;

    this.api
      .postData(this.apiConstant.ASSETS.SINGLE_ASSET_TRIP_DETAIL.URL, obj, true)
      .subscribe(async (res) => {
        if (res.data) {
          this.myModel.singleAssetData = res.data;
          // if (this.myModel.singleAssetData.pastLocation.length) {
          //     this.arrLatLong = this.myModel.singleAssetData.pastLocation;
          //     this.updateBounds();
          // }

          // tslint:disable-next-line:max-line-length
          if (
            this.myModel.singleAssetData &&
            this.myModel.singleAssetData.tripArray &&
            this.myModel.singleAssetData.tripArray.length
          ) {
            this.myModel.singleAssetData.tripArray.forEach((ele) => {
              ele.duration = this.breakTime(ele.duration);
            });
          }
        }
      });
  }

  addAddressInArray(element, index) {
    // return new Promise((resolve, reject) => {
    if ("moving" === element.movingStatus) {
      // console.log(element);
      let type;
      for (let i = 1; i <= 2; i++) {
        if (i === 1) {
          type = element.startLatLong;
        } else {
          type = element.endLatLong;
        }
        console.log(type, index);
        // setTimeout( () => {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          {
            location: {
              lat: type[1],
              lng: type[0],
            },
          },
          (results, status) => {
            console.log(results, status, index);
            // resolve(results);
            if (status === google.maps.GeocoderStatus.OK) {
              if (results && results[1]) {
                if (i === 1) {
                  element.startAddress = results[1].formatted_address;
                }
                if (i === 2) {
                  element.endAddress = results[1].formatted_address;
                }
                // element.address = (results[0].formatted_address);
              }
            }
          }
        );
        // }, 750 * index);
      }
    } else {
      // setTimeout( () => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: element.latLong[1],
            lng: element.latLong[0],
          },
        },
        (results) => {
          console.log(results, index, "parked-address");
          if (results && results[1]) {
            element.address = results[1].formatted_address;
            // element.address = (results[0].formatted_address);
          }
        }
      );
      // }, 750 * index);
    }
    // });
  }

  show24Hr() {
    if (this.myModel.singleAssetData.pastLocation.length) {
      this.arrLatLong = this.myModel.singleAssetData.pastLocation;
      this.updateBounds();
    } else {
      Swal.fire("Not Found", "No Trip found for this asset", "error");
    }
  }

  getMultipleTripDetails() {
    const obj = {};
    this.multipastLocations = [];
    if (!this.myModel.imeiArray.length) {
      this.toastr.error("Please select atleast one asset");
      return false;
    }
    this.showListing = false;
    obj["imeiNumber"] = JSON.stringify(this.myModel.imeiArray);
    if (this.myModel.startDate) {
      obj["startDate"] = this.myModel.startDate;
      obj["endDate"] = this.myModel.endDate;
    }

    obj["timeZoneOffset"] = new Date().getTimezoneOffset() * -1;

    this.api
      .postData(
        this.apiConstant.ASSETS.MULTIPLE_ASSET_TRIP_DETAIL.URL,
        obj,
        true
      )
      .subscribe((res) => {
        if (res.data) {
          this.myModel.multipleAssetData = res.data;
          if (this.myModel.multipleAssetData.length === 0) {
            Swal.fire("Not Found", "No Trip found for this asset", "error");
            return false;
          }
          this.updateBounds();
          this.myModel.multipleAssetData.forEach((ele, index) => {
            console.log(ele.pastLocation.length, index);
            if (ele.pastLocation.length) {
              ele.pastLocation[index].imeiNumber = ele.imeiNumber;
              this.multipastLocations.push(ele.pastLocation[0]);
              this.multipastLocations.push(
                ele.pastLocation[ele.pastLocation.length - 1]
              );
            }
          });
          console.log(this.multipastLocations, "multipast----------");
        }
      });
  }

  getAllImeiNumber(element) {
    this.errorMsg = false;
    if (this.myModel.imeiArray.length > 0) {
      const index: number = this.myModel.imeiArray.indexOf(element.imeiNumber);
      console.log(index);
      if (index !== -1) {
        this.myModel.imeiArray.splice(index, 1);
      } else {
        if (this.myModel.imeiArray.length >= 5) {
          this.errorMsg = true;
          // this.sessionService.warninglength();
          return false;
        }
        this.myModel.imeiArray.push(element.imeiNumber);
      }
    } else {
      this.myModel.imeiArray.push(element.imeiNumber);
    }
  }

  checkElement(element) {
    if (this.myModel.imeiArray.length > 0) {
      const index: number = this.myModel.imeiArray.indexOf(element.imeiNumber);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  clearData() {
    this.myModel.imeiArray = [];
    this.multipastLocations = [];
    this.myModel.multipleAssetData = [];
  }

  assetInfo(asset, type) {
    // this.index = i;
    this.selectedAsset = asset;
    // this.selectedAsset = this.arrLatLong[i];
    console.log(this.selectedAsset, "selectedAsset");
    this.getSingleAssetTripDetail();
  }

  openInfoWindow(asset, type) {
    console.log("info_Window", asset);
    if (asset && asset.latLong) {
      this.getaddress(asset);
      // this.updateBounds();

      this.mapInstance.setCenter(
        new google.maps.LatLng(asset.latLong[1], asset.latLong[0])
      );
      this.mapInstance.setZoom(16);

      if (type === "assetList") {
        this.close_window();
      }
    }
  }

  async getaddress(selectedAsset) {
    this.address = await this.sessionService.getAddressusingLatLong(
      selectedAsset.latLong[1],
      selectedAsset.latLong[0]
    );
    if (this.address) {
      console.log(
        this.address,
        selectedAsset.latLong[1],
        selectedAsset.latLong[0]
      );
      // return this.address;
    }
  }

  // async getaddressForTrips(selectedAsset) {
  //     let address =  await this.sessionService.getAddressusingLatLong(selectedAsset.startLatLong[1], selectedAsset.startLatLong[0]);
  //     console.log(address, 'address');
  //     // if (this.address) {
  //     //     // console.log(this.address, selectedAsset.latLong[1], selectedAsset.latLong[0]);
  //     //     return this.address;
  //     // }
  // }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }

  select_marker(infoWindow) {
    if (this.previous_info_window == null) {
      this.previous_info_window = infoWindow;
    } else {
      this.infoWindowOpened = infoWindow;
      this.previous_info_window.close();
    }
    this.previous_info_window = infoWindow;
  }

  isInfoWindowOpen(index) {
    console.log(this.index, index, "called");
    return this.index === index; // alternative: check if id is in array
  }

  calculateOdometerDistance(lat1, lon1, lat2, lon2) {
    return this.sessionService.calculateOdometerDistance(
      lat1,
      lon1,
      lat2,
      lon2
    );
  }

  searchAssets(value) {
    // if (value.length > 6 || value.length === 0) {
    const obj = {
      searchText: value,
    };
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
        //  debugger;
          this.myModel.alldata = res.data;
          this.showListing = true;
          if (res.data.length == 0) {
            this.myModel.singleAssetData = {};
            this.selectedAsset = {};
            this.showListing = false;
          } else {
            this.selectedAsset = this.myModel.alldata[0];
            //  console.log(this.selectedAsset);
            //  debugger
            if (this.selectedAsset) {
              this.getSingleAssetTripDetail();
            }
          }
        }
      });
  }

  breakTime(duration) {
    // console.log(minut?e1);
    let time = duration.split(":");
    return time;
  }

  openNav() {
    this.hasFullView = false;
    document.getElementById("myNav").classList.toggle("toggleSideList");
    document.getElementById("myMap").classList.toggle("toggleMap");
    if (document.getElementById("myNav").classList.contains("toggleSideList")) {
      this.hasFullView = true;
    }
  }
}
