import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {API_CONSTANT} from './../../shared/api.constants';
import {ApiService} from "../../services/api.service";

var misc: any = {
    sidebar_mini_active: true
};

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    type?: string;
    collapse?: string;
    children?: ChildrenItems2[];
    isCollapsed?: boolean;
}

export interface ChildrenItems2 {
    path?: string;
    title?: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    // {
    //     path: "",
    //     title: "Dashboard",
    //     type: "sub",
    //     icontype: "ni-ungroup trukkin-theme",
    //     isCollapsed: true,
    //     children: [
    //         // { path: "vehicles", title: "Vehicles", type: "link" },
    //         {path: "hardware-devices", title: "Hardware Devices", type: "link"},
    //         {path: "sim-cards", title: "SIM Cards", type: "link"},
    //         // { path: "user-roles", title: "User Roles", type: "link" },
    //     ]
    // },
    // {
    //   path: "/maps",
    //   title: "Maps",
    //   type:"link",
    //   icontype: "ni-chart-pie-35 trukkin-theme",
    // },
    // {
    //   path: "/asset-info",
    //   title: "Asset Info",
    //   type:"link",
    //   icontype: "ni-bell-55 trukkin-theme",
    // },
    // {
    //   path: "/zones",
    //   title: "Zones",
    //   type:"link",
    //   icontype: "ni-world trukkin-theme",
    // },
    // {
    //   path: "/trips",
    //   title: "Trips",
    //   type:"link",
    //   icontype: "ni-delivery-fast trukkin-theme",
    // },
    {
        path: "/asset-info",
        title: "Asset Info",
        type: "link",
        icontype: "ni-money-coins trukkin-theme",
    },
    {
        path: "/maps",
        title: "Maps",
        type: "link",
        icontype: "ni-pin-3 trukkin-theme",
    }
];

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;
    apiConstant = API_CONSTANT;

    constructor(private router: Router,
                private api: ApiService) {
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe(event => {
            this.isCollapsed = true;
        });
        this.minimizeSidebar();
        this.onMouseEnterSidenav();
        this.onMouseLeaveSidenav();
    }

    onMouseEnterSidenav() {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.add("g-sidenav-show");
        }
    }

    onMouseLeaveSidenav() {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
        }
    }

    minimizeSidebar() {
        const sidenavToggler = document.getElementsByClassName(
            "sidenav-toggler"
        )[0];
        const body = document.getElementsByTagName("body")[0];
        if (body.classList.contains("g-sidenav-pinned")) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove("g-sidenav-pinned");
            body.classList.add("g-sidenav-hidden");
            sidenavToggler.classList.remove("active");
            misc.sidebar_mini_active = false;
        } else {
            body.classList.add("g-sidenav-pinned");
            body.classList.remove("g-sidenav-hidden");
            sidenavToggler.classList.add("active");
            misc.sidebar_mini_active = true;
        }
    }



}
