import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { API_CONSTANT } from "../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { model } from "../../shared/common.constant";
import { AgmMap } from "@agm/core";
import { SessionService } from "../../services/session.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import * as moment from "moment";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { fromEvent } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

declare var google: any;

@Component({
  selector: "app-asset-info",
  templateUrl: "./asset-info.component.html",
  styleUrls: ["./asset-info.component.scss"],
})
export class AssetInfoComponent implements OnInit {
  @ViewChild("AgmMap", { static: false }) agmMap: AgmMap;
  @ViewChild("searchElement", { static: true }) searchElement: ElementRef;

  myModel = new model();
  bsValue = new Date();
  odometer: any = ["0", "0", "0", "0", ".", "0", "0"];
  apiConstant = API_CONSTANT;
  EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  // index = 0;
  selectedImei: any;
  selectedwallet = "home";
  selectedAsset: any;
  address: any;
  private mapBounds: any;
  private mapInstance: any;
  private searchChangeObserver: any;
  private tempData: any;
  closeResult: string;
  public hour: any;
  locationForm: FormGroup;
  private minute: any;
  public selectedDate: Date;
  tripDate: any;
  public bsConfig: {};
  data: any;
  location: any;
  currentCountry: any;

  constructor(
    private api: ApiService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.tripDate = moment(new Date()).format("DD-MM-YYYY");
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
      }
    );
  }

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.createform();
    this.myModel.myDate = moment(new Date()).format("YYYY-MM-DD");
    this.getAssets();

    fromEvent(this.searchElement.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => {
          // console.log(event, 'eventttttt');
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2 || res.length === 0),
        // Time in milliseconds between key events
        debounceTime(1000)
        // If previous query is diffent from curren
        // , distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.searchAssets(text);
      });
    this.sessionService.currentData.subscribe((res) => {
      if (res) {
        this.tempData = res;
        console.log("socket data", res);
        this.myModel.alldata.forEach((ele, index) => {
          if (ele.imeiNumber === this.tempData.imeiNumber) {
            // console.log(ele, index, 'before');
            Object.keys(this.tempData).forEach((key) => {
              ele[key] = this.tempData[key];
              this.myModel.alldata[index][key] = this.tempData[key];
              // ele['currentLatLong'] = this.latLong;
            });
          }
        });
      }
    });

    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        //console.log("change country", this.searchElement.nativeElement.value)
        this.currentCountry = res;
        // this.searchElement.nativeElement.value = "";
        // this.myModel.activeDevice = "";
        //debugger
        if (this.router.url === "/asset-info") {
          this.getAssets();
        }
      }
    });

    //
  }

  createform() {
    const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    this.locationForm = this.fb.group({
      assignmentId: ["", Validators.required],
      expiredAt: ["", Validators.required],
      date: ["", Validators.required],
      time: ["", Validators.required],
      minutes: [""],
      email: new FormControl("", [
        Validators.required,
        this.sessionService.noWhitespaceValidator,
        Validators.pattern(EMAIL_REGEX),
      ]),
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mapBounds = new google.maps.LatLngBounds();
    }, 1000);
    this.agmMap.mapReady.subscribe((map) => {
      this.mapInstance = map;
    });
  }

  restFilert() {
    this.searchElement.nativeElement.value = "";
    this.myModel.activeDevice = "";
    this.getAssets();
  }
  getAssets() {
    let obj = {};
    if (this.myModel.activeDevice) {
      obj["activeDevice"] = [this.myModel.activeDevice];
    }
    if (this.searchElement.nativeElement.value) {
      obj["searchText"] = this.searchElement.nativeElement.value;
    }
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          console.log(res);
          if (res.data[0] && res.data[0].imeiNumber) {
            this.selectedImei = res.data[0].imeiNumber;
            this.getSingleAssetInfo();
          }
        }
      });
  }

  getLastLocation() {
    let obj = {};
    console.log(this.selectedAsset, "selectedAsset");
    obj["imeiNumber"] = this.selectedImei;
    if (this.selectedAsset && this.selectedAsset.assignmentId) {
      obj["assignmentId"] = this.selectedAsset.assignmentId;
    }

    this.api
      .postData(this.apiConstant.ASSETS.LAST_LOCATION_TRIPS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.location = res.data;
        }
      });
  }

  // calculateOdometerDistance(lat1, lon1, lat2, lon2) {
  //     let data = this.sessionService.calculateOdometerDistance(lat1, lon1, lat2, lon2);
  //     this.odometer = [];
  //     let str = data.toFixed(2).toString();
  //     for (let i = 0; i < str.length; i++) {
  //         this.odometer.push(str[i]);
  //         // console.info(str[i]);
  //     }
  // }

  setOdometer(value) {
    this.odometer = [];
    if (value) {
      let str = value.toFixed(2).toString();
      for (let i = 0; i < str.length; i++) {
        this.odometer.push(str[i]);
      }
    } else {
      this.odometer = ["0", "0", "0", "0", ".", "0", "0"];
    }
    console.log(this.odometer, "odometereeeee");
  }

  getSingleAssetInfo() {
    let obj = {
      imeiNumber: this.selectedImei,
    };

    this.api
      .postData(this.apiConstant.ASSETS.SINGLE_ASSET_INFO.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.selectedAsset = res.data[0];
          //this.getLastLocation();
          this.setTransform(this.selectedAsset.direction);
          this.getaddress(this.selectedAsset);
          this.setOdometer(this.selectedAsset.odometerValue);
          // if (this.selectedAsset.assignedStatus && this.selectedAsset.sourceLatLong) {
          //         this.setOdometer(this.selectedAsset.odometerValue);
          //     // this.calculateOdometerDistance(this.selectedAsset.sourceLatLong[1], this.selectedAsset.sourceLatLong[0], this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]);
          // } else {
          //     this.odometer = ['0', '0', '0', '0', '.', '0', '0'];
          // }
        }
      });
  }

  assetInfo(asset) {
    // this.index = i;
    this.selectedImei = asset.imeiNumber;
    // this.selectedAsset = asset;
    this.getSingleAssetInfo();
    this.getLastLocation();
    // this.getSingleAssetTripDetail();
    this.setTransform(asset.direction);
    this.setOdometer(this.selectedAsset.odometerValue);
    // if (this.selectedAsset.assignedStatus && this.selectedAsset.sourceLatLong) {
    //     this.setOdometer(this.selectedAsset.odometerValue);
    //     // this.calculateOdometerDistance(this.selectedAsset.sourceLatLong[1], this.selectedAsset.sourceLatLong[0], this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]);
    // } else {
    //     this.odometer = ['0', '0', '0', '0', '.', '0', '0'];
    // }
  }

  async getaddress(selectedAsset) {
    if (selectedAsset.currentLatLong) {
      this.address = await this.sessionService.getAddressusingLatLong(
        selectedAsset.currentLatLong[1],
        selectedAsset.currentLatLong[0]
      );
    } else {
      this.selectedAsset["currentLatLong"] = [];
      this.selectedAsset.currentLatLong[0] = 0;
      this.selectedAsset.currentLatLong[1] = 0;
    }
  }

  setTransform(rotationArg) {
    const element = document.getElementById("direction-circle");
    if (element) {
      element.style.transform = "rotate(" + rotationArg + "deg)";
    }

    //   this.getaddress(this.selectedAsset)
    // this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
    //this.mapInstance.setZoom(15);
  }

  openModal() {
    console.log("modal called");
    let element = document.getElementById(
      "shareLiveLocationModal"
    ) as HTMLElement;
    element.click();
  }

  open(content) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.closeResult = "Closed with: $result";
      },
      (reason) => {
        this.closeResult = "Dismissed $this.getDismissReason(reason)";
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return "with: $reason";
    }
  }

  getDate(date) {
    this.selectedDate = date;
    console.log(date);
    if (date) {
      this.locationForm.controls["expiredAt"].setValue(this.selectedDate);
    }
    this.locationForm.controls["time"].setValue("");
    this.locationForm.controls["minutes"].setValue("");
  }

  getHour(time) {
    let timme = time;
    let time1 = timme.split(":");
    this.hour = time1[0];
    this.selectedDate.setHours(this.hour);
    this.locationForm.controls["expiredAt"].setValue(this.selectedDate);
  }

  getMinute(minute) {
    let minute1 = minute;
    console.log(minute1);
    let min = minute1.split(":");
    this.selectedDate.setMinutes(min[1]);
    this.locationForm.controls["expiredAt"].setValue(this.selectedDate);
  }

  sendLocation() {
    this.locationForm.controls["assignmentId"].setValue(
      this.selectedAsset.assignmentId
    );
    if (this.locationForm.valid) {
      this.locationForm.controls["expiredAt"].setValue(
        this.selectedDate.toISOString()
      );
      let obj = {};
      Object.assign(obj, this.locationForm.value);
      delete obj["date"];
      delete obj["time"];
      delete obj["minutes"];
      console.log(this.locationForm.value, "form_value");
      this.api
        .postData(this.api.apiConstants.ASSETS.SHARE_LOCATION.URL, obj, true)
        .subscribe((res) => {
          let element = document.getElementById("closeModal") as HTMLElement;
          element.click();
          Swal.fire(
            "Sent Successfully",
            "Location sent successfully!",
            "success"
          );

          // this.setActivePath(typeConst.VERIFY_OTP);
        });
    } else {
      this.sessionService.setTouched(this.locationForm);
    }
  }

  getdateEvent(event) {
    console.log(event);
    if (event) {
      this.tripDate = moment(event).format("LL");
      this.myModel.myDate = moment(event).format("YYYY-MM-DD");
      this.getSingleAssetTripDetail();
    }
  }

  searchAssets(value) {
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    const obj = {
      searchText: value,
    };
    if (this.myModel.activeDevice && this.myModel.tab == "filter") {
      obj["activeDevice"] = [this.myModel.activeDevice];
    }
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
        }
      });
  }

  getSingleAssetTripDetail() {
    let obj = {};
    if (this.selectedAsset && this.selectedAsset.imeiNumber) {
      obj["imeiNumber"] = this.selectedAsset.imeiNumber;
    }
    if (this.myModel.myDate) {
      obj["date"] = this.myModel.myDate;
    }

    obj["timeZoneOffset"] = new Date().getTimezoneOffset() * -1;

    this.api
      .postData(this.apiConstant.ASSETS.SINGLE_ASSET_TRIP_DETAIL.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.singleAssetData = res.data;

          // if (this.myModel.singleAssetData && this.myModel.singleAssetData.tripArray && this.myModel.singleAssetData.tripArray.length) {
          //     this.myModel.singleAssetData.tripArray.forEach(async (element, index) => {
          //         console.log(element, index, 'address');
          //         if (element.movingStatus === 'moving') {
          //             // console.log(element);
          //
          //             const geocoder = new google.maps.Geocoder();
          //             await geocoder.geocode({
          //                 'location': {
          //                     lat: element.startLatLong[1],
          //                     lng: element.startLatLong[0]
          //                 }
          //             }, (results) => {
          //                 console.log(results, index, 'start-address');
          //                 if (results && results[1]) {
          //                     element['startAddress'] = results[1].formatted_address;
          //                     // element.address = (results[0].formatted_address);
          //                 }
          //             });
          //
          //
          //             await geocoder.geocode({
          //                 'location': {
          //                     lat: element.endLatLong[1],
          //                     lng: element.endLatLong[0]
          //                 }
          //             }, (results) => {
          //                 console.log(results, index, 'end-address');
          //                 if (results && results[1]) {
          //                     element['endAddress'] = results[1].formatted_address;
          //                     // element.address = (results[0].formatted_address);
          //                 }
          //             });
          //         } else {
          //             const geocoder = new google.maps.Geocoder();
          //             await geocoder.geocode({
          //                 'location': {
          //                     lat: element.latLong[1],
          //                     lng: element.latLong[0]
          //                 }
          //             }, (results) => {
          //                 console.log(results, index, 'parked-address');
          //                 if (results && results[1]) {
          //                     element['address'] = results[1].formatted_address;
          //                     // element.address = (results[0].formatted_address);
          //                 }
          //             });
          //         }
          //     });
          //     console.log(this.myModel.singleAssetData.tripArray, 'triparray');
          // }
        }
      });
  }

  // setTransform(element, rotationArg, scaleArg, skewXArg, skewYArg) {
  //     let element = document.getElementById('')
  //     element.style.transform = ("rotate(" + rotationArg + "deg ) scale(" + scaleArg
  //         + ") skewX(" + skewXArg + "deg ) skewY(" + skewYArg + "deg )");
  // }
}
