import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { AgmMap } from "@agm/core";
import { AgmOverlays } from "agm-overlays"
import { AgmCoreModule } from '@agm/core';
import { API_CONSTANT } from "../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { model } from "../../shared/common.constant";
import { ClusterStyle } from "@agm/js-marker-clusterer/services/google-clusterer-types";
import { SocketService } from "../../services/socket.service";
import { SessionService } from "../../services/session.service";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { id } from "@swimlane/ngx-datatable/release/utils";

declare var google: any;
// const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
//     mozRequestFullScreen(): Promise<void>;
//     webkitRequestFullscreen(): Promise<void>;
//     msRequestFullscreen(): Promise<void>;
// };
//
// const docWithBrowsersExitFunctions = document as Document & {
//     mozCancelFullScreen(): Promise<void>;
//     webkitExitFullscreen(): Promise<void>;
//     msExitFullscreen(): Promise<void>;
// };
@Component({
  selector: "app-agm-maps",
  templateUrl: "./agm-maps.component.html",
  styleUrls: ["./agm-maps.component.scss"],
})
export class AgmMapsComponent implements OnInit {
  public hasFullView = false;
  public ListingBtn = true;
  private isfullscreen = false;
  angle: any;

  constructor(
    private api: ApiService,
    private socket: SocketService,
    private sessionService: SessionService,
    private changeDetection: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute
  )
  
   {
    // this.sessionService.assignCurrentData.subscribe(res => {
    //  if(res){
    //   this.getAssets();}
    // });
    // this.sessionService.unassignCurrentData.subscribe(res => {
    //   if(res){
     
    //   this.getAssets();}
    // });




    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
        isAnimated: true,
        containerClass: "theme-orange",
      }
    );
    this.clusterStyles = [
      {
        textColor: "#000000",
        url: "assets/img/map-icons/m1.png",
        height: 36,
        width: 58,
        backgroundPosition: "center",
      },
    ];
  }
 

  @ViewChild("AgmMap", { static: false }) agmMap: AgmMap;
  @ViewChild("searchElement", { static: true }) searchElement: ElementRef;
  @ViewChild("infoWindow", { static: false }) infoWindow: ElementRef;

  clusterStyles: ClusterStyle[];
  zoomIn = 15;
  // minZoom = 3;
  infoWindowOpened = null;
  previous_info_window = null;
  myModel = new model();
  apiConstant = API_CONSTANT;
  // zoom = 8;
  oldIndex: any;
  assetValue = false;
  validTime = true;
  validDate = true;
  validFuture = true;
  isEmailScheduled = false;

  arrLatLong = [];
  assetFilter = [
    {
      value: "moving",
      text: "Moving IMEIs",
      selected: false,
      img: "assets/img/filter-green-truck.png",
    },
    {
      value: "parked",
      text: "Parked IMEIs",
      selected: false,
      img: "assets/img/filter-orange-truck.png",
    },
    {
      value: "idle",
      text: "Idle IMEIs",
      selected: false,
      img: "assets/img/filter-grey-truck.png",
    },
  ];

  tabOnMap = [];
  searchType = "imeiNumber";
  filterMap = {
    serach: false,
    status: false,
    category: false,
    label: false,
  };
  closeFilter = false;
  subCategories = [];
  selectedCategory = "";
  selectedSubCat = "";
  selectedSubCatName = "";
  search = "";
  allSelected = false;
  freeSelected = false;
  movingStatus = "all";
  isAssigned = false;
  isfilterApplied = false;
  assignedStatusFilter = [];
  isSelectedFilter = false;
  truckArray = [];
  private mapBounds: any;
  private mapInstance: any;
  private selectedAsset: any;
  private directionsService: any;
  private directionsRenderer: any;
  public heading = "Add";
  // public index: any;
  public address: any;
  public tempData;
  latLong = [76.7796, 30.7334];
  url =
    "https://www.google.com.sa/maps/search/" +
    30.7220257 +
    "," +
    76.8462109 +
    "," +
    "12.21z?hl + =en";
  public selectedImei: any;
  private closeResult: any;
  public emailForm: FormGroup;
  maxDate = new Date();
  time = "";
  public bsConfig: {};
  isMeridian = false;
  showSpinners = false;
  selectDate = "";
  assignmentID = "";
  selectedImeiNumber = "";
  currentCountry = "";
  currentMarker;

  openedWindow = 0;
  
  
  // alternative: array of numbers

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.getAssets();
    this.createForm();
    localStorage.setItem("newSearchText", "");
    this.truckArray = this.myModel.TruckArray;
    fromEvent(this.searchElement.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => {
          // console.log(event, 'eventttttt');
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2 || res.length === 0),
        // Time in milliseconds between key events
        debounceTime(1000)
        // If previous query is diffent from curren
        // distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.search = text;
        this.searchAssets(text);
      });

    this.sessionService.currentData.subscribe((res) => {
      if (res) {
        this.tempData = res;
        // console.log("socket data", res);
        this.arrLatLong.forEach((ele, index) => {
          if (ele.imeiNumber === this.tempData.imeiNumber) {
            // console.log(ele, index, "before");
            Object.keys(this.tempData).forEach((key) => {
              ele[key] = this.tempData[key];
              this.arrLatLong[index][key] = this.tempData[key];
              // ele['currentLatLong'] = this.latLong;
            });
          }
        });
      }
    });

    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        // console.log("change country", res, this.activeRoute, this.router.url);
        this.currentCountry = res;
        //this.getAssets();
        if (this.router.url === "/maps") {
          // this.clearFilter("All");
          this.getAssets();
        }
      }
    });
  }

  setDirection(rotationArg) {
    let angle = rotationArg;
    let element;
    element = document.querySelectorAll(
      'img[src="/assets/img/orange-icon-svg.svg"]'
    );
    if (element.length) {
      element[0].style.transform = "rotate(" + angle + "deg)";
    }
    if (angle < 0) {
      angle = 360 + angle;
    }
    if (angle > 360) {
      angle = angle - 360;
    }
    //  this.mapInstance.setHeading(angle);
    // console.log("angle", angle);
  }

  updateBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
    this.arrLatLong.forEach((marker) => {
      if(marker["currentLatLong"]){
        const tempPosition = new google.maps.LatLng(
          marker.currentLatLong[1],
          marker.currentLatLong[0]
        );
        this.mapBounds.extend(tempPosition);
      }
      
    });
    this.mapInstance.fitBounds(this.mapBounds);
    //this.mapInstance.setZoom(8);
  }
  findIcon(vehicleStatus) {
    if (vehicleStatus === "moving") {
      return this.myModel.icon[1];
    } else if (vehicleStatus === "parked") {
      return this.myModel.icon[2];
    } else if (vehicleStatus === "idle") {
      return this.myModel.icon[0];
    } else {
      return this.myModel.icon[2];
    }
  }
  ngAfterViewInit() {
    this.agmMap.mapReady.subscribe((map) => {
      this.mapInstance = map;
      this.directionsRenderer = map;
    });
    setTimeout(() => {
      let element = document.querySelectorAll(
        'img[src="/assets/img/orange-icon-svg.svg"]'
      );
      // console.log(element);
      // element.style.transform = ("rotate(" + 180 + "deg)");
    }, 2000);
    setTimeout(() => {
      this.mapBounds = new google.maps.LatLngBounds();
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
    }, 1000);
  }

  getAssets() {
    //this.searchType =  localStorage.getItem('searchType');
    // this.search =    localStorage.getItem('newSearchText');
    //this.selectedSubCat =  localStorage.getItem('truckType');
    this.movingStatus = "all";
    this.myModel.limit = 10;
    let obj = {};
    obj["activeDevice"] = [];
    obj["movingStatus"] = [];
    obj["workingCountry"] = this.currentCountry;
    let movingStatus = [];
    let activeDevice = [];
    this.assetFilter.forEach((key, index) => {
      if (key.selected) {
        movingStatus.push(key.value);
      }
    });

    if (movingStatus.length > 0 || this.allSelected) {
      activeDevice.push("active");
    }

    if (movingStatus.length) {
      obj["movingStatus"] = movingStatus;
    } else {
      delete obj["movingStatus"];
    }

    if (this.freeSelected) {
      activeDevice.push("inactive");
    }

    if (activeDevice.length) {
      obj["activeDevice"] = activeDevice;
    } else {
      delete obj["activeDevice"];
    }
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    /*obj['skip'] = this.myModel.skip;
        obj['limit']= this.myModel.limit;*/

    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          this.myModel.alldata.forEach((e,index) => {
            this.setTransform(e.direction ,index);
            e.isCollapsed = true;
            e.showLabel = false;
            e.isCollapsedEmail = false;
            e.scheduleTime = "";
            e.scheduleDate = "";
          });
          this.arrLatLong = res.data;
          this.updateBounds();
        }
      });
  }
  getPageChangeEvent(event) {
    this.myModel.pageNumber = event.page;
    this.myModel.skip = (this.myModel.pageNumber - 1) * 10;
    // this.getAssets();
    let obj = {};
    /* obj['skip'] = this.myModel.skip;
       obj['limit']= this.myModel.limit;*/
    this.movingStatus = "all";
    obj["activeDevice"] = [];
    obj["movingStatus"] = [];
    obj["workingCountry"] = this.currentCountry;
    let movingStatus = [];

    this.assetFilter.forEach((key, index) => {
      if (key.selected) {
        movingStatus.push(key.value);
      }
    });

    if (movingStatus.length > 0 || this.allSelected) {
      obj["activeDevice"].push("active");
    }

    if (movingStatus.length) {
      obj["movingStatus"] = movingStatus;
    } else {
      delete obj["movingStatus"];
    }

    if (this.freeSelected) {
      obj["activeDevice"].push("inactive");
    }
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;

          this.myModel.alldata.forEach((e, index) => {
            this.setTransform(e.direction ,index);
            e.isCollapsed = true;
            e.showLabel = false;
            e.isCollapsedEmail = false;
            e.scheduleTime = "";
            e.scheduleDate = "";
          });
          this.arrLatLong = res.data;
          this.updateBounds();
        }
      });
  }
  setTransform(rotationArg , i) {
    setTimeout(()=>{
      let rotation = rotationArg;
      let x = `marker-${i}`;
    let element = document.getElementById(x);
    // console.log(rotation);
    if(element)
      element.style.transform = "rotate(" + rotation + "deg)";
    }, 2000);
    
  }
  assetInfo(asset) {
    // this.index = i;
    this.selectedImei = asset.imeiNumber;
    this.selectedAsset = asset;
    //  this.getaddress(this.selectedAsset);
    this.selectedAsset.address = this.address;
    this.mapInstance.setCenter(
      new google.maps.LatLng(
        this.selectedAsset.currentLatLong[1],
        this.selectedAsset.currentLatLong[0]
      )
    );
    this.mapInstance.setZoom(19);
    // if (type === 'assetList') {
    //     this.close_window();
    // }
  }

  async getaddress(selectedAsset) {
    this.address = await this.sessionService.getAddressusingLatLong(
      selectedAsset.currentLatLong[1],
      selectedAsset.currentLatLong[0]
    );
  }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }

  select_marker(infoWindow) {
    /*if (this.previous_info_window == null)
             this.previous_info_window = infoWindow;
         else {
             this.infoWindowOpened = infoWindow;
            this.previous_info_window.close();
         }
        this.previous_info_window = infoWindow;*/
  }

  openModal(value) {
    this.selectDate = "";
    this.assignmentID = value.assignmentId;
    this.selectedImeiNumber = value.imeiNumber;
    this.isEmailScheduled = value.isEmailScheduled;
    if (value.isEmailScheduled && !value.isCollapsedEmail) {
      let obj = {};
      obj["assignmentId"] = this.assignmentID;
      obj["imeiNumber"] = this.selectedImeiNumber;
      this.api
        .postData(this.apiConstant.ALERTS.GET_EMAIL_TRIGGER.URL, obj, true)
        .subscribe((res) => {
          if (res.data) {
            this.myModel.alldata.forEach((e) => {
              if (e.assignmentId == this.assignmentID) {
                e.scheduleDate = res.data[0].date;
                e.scheduleTime = res.data[0].time;
                return;
              }
            });
            // const element = document.getElementById('scheduleEmailModal') as HTMLElement;
            // element.click();
          }
        });
    } else {
      if (!value.isCollapsedEmail) {
        this.isEmailScheduled = false;
        const element = document.getElementById(
          "scheduleEmailModal"
        ) as HTMLElement;
        element.click();
      }
    }
  }

  open(content) {
    this.modalService
      .open(content, {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "sm",
      })
      .result.then(
        (result) => {
          this.closeResult = "Closed with: $result";
        },
        (reason) => {
          // console.log("closed");
          this.closeResult = "Dismissed $this.getDismissReason(reason)";
        }
      );
  }

  closeModal() {
    this.assignmentID = "";
    this.selectDate = "";
    this.time = "";
    this.validTime = true;
    this.validDate = true;
    this.validFuture = true;
    this.selectedImeiNumber = "";
    const element = document.getElementById("closeModal") as HTMLElement;
    element.click();
  }
  openWindow(id) {
    this.openedWindow = id; // alternative: push to array of numbers

    // console.log(id, "id");
  }

  openLabel(index) {
    if (!this.filterMap.label) {
      this.arrLatLong.forEach((x, i) => {
        x.showLabel = false;
      });
    }

    this.arrLatLong[index].showLabel = true;
  }

  isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  // isInfoWindowOpen(index) {
  //     console.log(this.index, index, 'called');
  //     return this.index === index; // alternative: check if id is in array
  // }
  showListing = false;

  calculateOdometerDistance(lat1, lon1, lat2, lon2) {
    return this.sessionService.calculateOdometerDistance(
      lat1,
      lon1,
      lat2,
      lon2
    );
  }

  openTracking(id) {
    this.router.navigate(["/track-truck"], {
      queryParams: { assignmentId: id },
    });
  }

  searchAssets(value) {
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    const obj = {
      newSearchText: value,
      movingStatus: [],
      activeDevice: [],
    };
    if (obj.newSearchText) obj["searchType"] = this.searchType;
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    let movingStatus = [];
    let activeDevice = [];
    this.assetFilter.forEach((key, index) => {
      if (key.selected) {
        movingStatus.push(key.value);
      }
    });

    if (movingStatus.length > 0 || this.allSelected) {
      activeDevice.push("active");
    }
    if (movingStatus.length) {
      obj["movingStatus"] = movingStatus;
    } else {
      delete obj["movingStatus"];
    }

    if (this.freeSelected) {
      activeDevice.push("inactive");
    }

    if (activeDevice.length) {
      obj["activeDevice"] = activeDevice;
    } else {
      delete obj["activeDevice"];
    }
    /* obj['skip'] = 0;
            obj['limit']= this.myModel.limit;*/
    /*if(this.allSelected){
                this.assignedStatusFilter.push("All");
            }*/
    localStorage.setItem("searchType", this.searchType);
    localStorage.setItem("newSearchText", this.search);
    localStorage.setItem("truckType", this.selectedSubCat);
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          this.myModel.alldata.forEach((e, index) => {
            this.setTransform(e.direction ,index);
            e.isCollapsed = true;
            e.isCollapsedEmail = false;
            e.showLabel = false;
            e.scheduleTime = "";
            e.scheduleDate = "";
          });
          this.arrLatLong = res.data;
          this.updateBounds();
        }
      });
  }

  fetchAssetsOnCategory() {
    if (this.selectedSubCat) {
      const obj = {
        truckType: [],
        movingStatus: [],
        activeDevice: [],
      };
      obj.truckType.push(this.selectedSubCat);
      if (this.search) {
        obj["searchType"] = this.searchType;
        obj["newSearchText"] = this.search;
      }

      let movingStatus = [];
      let activeDevice = [];
      //obj['activeDevice'] = [''];
      this.assetFilter.forEach((key, index) => {
        if (key.selected) {
          movingStatus.push(key.value);
        }
      });

      if (movingStatus.length > 0 || this.allSelected) {
        activeDevice.push("active");
      }

      if (movingStatus.length > 0) {
        obj["movingStatus"] = movingStatus;
      } else {
        delete obj["movingStatus"];
      }

      if (this.freeSelected) {
        activeDevice.push("inactive");
      }
      if (activeDevice.length) {
        obj["activeDevice"] = activeDevice;
      } else {
        delete obj["activeDevice"];
      }
      /*if(this.allSelected){
                this.assignedStatusFilter.push("All");
            }*/
      localStorage.setItem("searchType", this.searchType);
      localStorage.setItem("newSearchText", this.search);
      /*obj['skip'] = 0;
            obj['limit']= this.myModel.limit;*/
      obj["workingCountry"] = this.currentCountry;
      this.api
        .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
        .subscribe((res) => {
          if (res.data) {
            this.myModel.alldata = res.data;
            this.myModel.alldata.forEach((e, index) => {
              this.setTransform(e.direction ,index);
              e.isCollapsed = true;
              e.showLabel = false;
              e.isCollapsedEmail = false;
              e.scheduleTime = "";
              e.scheduleDate = "";
            });
            this.arrLatLong = res.data;
            this.updateBounds();
          }
        });
    }
  }

  filterAssets() {
    //this.assetFilter[i].selected /*  = true;
    /* this.assetFilter.forEach((key, index)=>{if(index === i) key.selected=true; else key.selected = false;});
        this.tabOnMap = [];
        this.tabOnMap = this.assetFilter.filter((k, index)=>{if(k.selected) return k;});
        console.log(this.tabOnMap);
        // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
        //     return false;
        // }*/
    let movingStatus = [];
    const obj = {
      movingStatus: [],
      activeDevice: [],
    };

    this.isSelectedFilter = false;
    this.assetFilter.forEach((key, index) => {
      if (key.selected) {
        movingStatus.push(key.value);
      }
    });

    if (!movingStatus.length && !this.freeSelected && !this.allSelected) {
      this.isSelectedFilter = true;
      return;
    }

    this.assignedStatusFilter = [];
    if (movingStatus.length > 0 || this.allSelected) {
      obj["activeDevice"].push("active");
    }
    if (movingStatus.length) {
      obj["movingStatus"] = movingStatus;
    } else {
      delete obj["movingStatus"];
    }

    if (this.freeSelected) {
      obj["activeDevice"].push("inactive");
    }
    if (this.allSelected) {
      this.assignedStatusFilter.push("All");
    }
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }

    if (this.freeSelected && !this.allSelected) {
      this.assignedStatusFilter.push("Free");
    }
    if (movingStatus.length > 0 && !this.allSelected) {
      this.assetFilter.forEach((key, index) => {
        if (key.selected) {
          this.assignedStatusFilter.push(key.value);
        }
      });
    }
    /* obj['skip'] = 0;
        obj['limit']= this.myModel.limit;*/

    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.isfilterApplied = true;
          this.myModel.alldata = res.data;
          this.myModel.alldata.forEach((e, index) => {
            this.setTransform(e.direction ,index);
            e.isCollapsed = true;
            e.showLabel = false;
            e.isCollapsedEmail = false;
            e.scheduleTime = "";
            e.scheduleDate = "";
          });
          this.arrLatLong = res.data;
          this.updateBounds();
        }
      });
  }

  updateSubCatList() {
    this.selectedSubCat = "";
    this.myModel.TruckArray.forEach((x) => {
      if (this.selectedCategory === x.value) this.subCategories = x.sub;
    });
    if (this.selectedCategory === "") this.clearFilter("truckType");
  }

  clearFilter(type) {
    if (type === "search") {
      this.searchElement.nativeElement.value = "";
      this.search = "";
    }
    if (type === "Free") {
      this.freeSelected = false;
      let index = -1;
      index = this.assignedStatusFilter.indexOf("Free");
      if (index! - 1) {
        this.assignedStatusFilter.splice(index, 1);
      }
      this.allSelected = false;
    }
    if (type === "moving") {
      this.assetFilter.forEach((key, index) => {
        if (type == key.value) {
          key.selected = false;
        }
      });
      let index = -1;
      index = this.assignedStatusFilter.indexOf("moving");
      if (index != -1) {
        this.assignedStatusFilter.splice(index, 1);
      }
      this.allSelected = false;
    }
    if (type === "idle") {
      this.assetFilter.forEach((key, index) => {
        if (type == key.value) {
          key.selected = false;
        }
      });
      let index = -1;
      index = this.assignedStatusFilter.indexOf("idle");
      if (index != -1) {
        this.assignedStatusFilter.splice(index, 1);
      }
      this.allSelected = false;
    }
    if (type === "parked") {
      this.assetFilter.forEach((key, index) => {
        if (type == key.value) {
          key.selected = false;
        }
      });
      let index = -1;
      index = this.assignedStatusFilter.indexOf("parked");
      if (index != -1) {
        this.assignedStatusFilter.splice(index, 1);
      }
      this.allSelected = false;
    }
    if (type === "truckType") {
      this.selectedSubCat = "";
      this.selectedCategory = "";
      this.subCategories = [];
    }
    if (type === "filterAll") {
      this.allSelected = false;
      this.freeSelected = false;
      this.isfilterApplied = false;
      this.assignedStatusFilter = [];
    }
    if (type === "All") {
      this.searchElement.nativeElement.value = "";
      this.search = "";
      this.allSelected = false;
      this.freeSelected = false;
      this.isfilterApplied = false;
      this.assignedStatusFilter = [];
      this.selectedSubCat = "";
      this.selectedCategory = "";
      this.subCategories = [];
      this.assetFilter.forEach((key, index) => {
        if (key.selected) {
          key.selected = false;
        }
      });
    }

    let obj = {};
    obj["activeDevice"] = [];
    obj["movingStatus"] = [];
    let movingStatus = [];
    let activeDevice = [];
    this.assetFilter.forEach((key, index) => {
      if (key.selected) {
        movingStatus.push(key.value);
      }
    });

    if (movingStatus.length > 0 || this.allSelected) {
      activeDevice.push("active");
    }
    if (movingStatus.length) {
      obj["movingStatus"] = movingStatus;
    } else {
      delete obj["movingStatus"];
    }

    if (this.freeSelected) {
      activeDevice.push("inactive");
    }
    if (activeDevice.length) {
      obj["activeDevice"] = activeDevice;
    } else {
      delete obj["activeDevice"];
    }
    /* if(this.allSelected){
            this.assignedStatusFilter.push("All");
        }*/
    if (this.selectedSubCat) {
      obj["truckType"] = [];
      obj["truckType"].push(this.selectedSubCat);
    }
    if (this.search) {
      obj["searchType"] = this.searchType;
      obj["newSearchText"] = this.search;
    }
    /*obj['skip'] = 0;
        obj['limit']= this.myModel.limit;*/
    if (type === "filterAll") {
      delete obj["activeDevice"];
      delete obj["movingStatus"];
    }
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          this.myModel.alldata.forEach((e, index) => {
            this.setTransform(e.direction ,index);
            e.isCollapsed = true;
            e.showLabel = false;
            e.isCollapsedEmail = false;
            e.scheduleTime = "";
            e.scheduleDate = "";
          });
          this.arrLatLong = res.data;
          this.updateBounds();
        }
      });
  }

  trackFilter(index, item) {
    return this.assetFilter ? this.assetFilter : undefined;
  }

  truckType(value) {
    let truckInfo = "NA";
    this.truckArray.forEach((truckTypeArray) => {
      truckTypeArray.sub.forEach((subTruckTypeArray) => {
        if (subTruckTypeArray.value == value) {
          let data = {
            truckType: truckTypeArray.value,
            truckName: truckTypeArray.text,
            subTruckType: subTruckTypeArray.value,
            subTruckName: subTruckTypeArray.text,
          };
          truckInfo = truckTypeArray.text + "(" + subTruckTypeArray.text + ")";
          return;
        }
      });
    });
    return truckInfo;
  }

  toggleLabels() {
    // console.log(this.filterMap.label);
    if (this.filterMap.label) {
      this.myModel.alldata.forEach((e) => {
        e.showLabel = true;
      });
    } else {
      this.myModel.alldata.forEach((e) => {
        e.showLabel = false;
      });
    }
  }

  /* zoomInMap() {
        
        this.zoomIn = 10 + this.zoomIn;
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
        this.mapInstance.setZoom(this.zoomIn);
        console.log(this.zoomIn,"console.log(this.zoomIn)")

    }*/

  zoomOutMap2() {
    this.zoomIn = 10 - this.zoomIn;
  }
  /*zoomOutMap() {
        this.zoomIn = 10 - this.zoomIn;
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
        this.mapInstance.setZoom(this.zoomIn);
    }*/

  openNav() {
    this.hasFullView = false;
    document.getElementById("myNav").classList.toggle("toggleSideList");
    document.getElementById("myMap").classList.toggle("toggleMap");
    if (document.getElementById("myNav").classList.contains("toggleSideList")) {
      this.hasFullView = true;
    }
    this.removeClass(1);
  }

  openMap() {
    this.ListingBtn = true;
    document.getElementById("myMap").classList.toggle("mapFullscreen");
    if (document.getElementById("myMap").classList.contains("mapFullscreen")) {
      this.ListingBtn = false;
    }
  }

  onInfoWindowClose() {
    // this.openedWindow = 0;
    // console.log(this.openedWindow);
  }
  removeFilters() {
    this.allSelected = false;
    this.freeSelected = false;
    for (let i = 0; i < this.assetFilter.length; i++) {
      this.assetFilter[i].selected = false;
    }
  }
  checkUncheckAll() {
    for (let i = 0; i < this.assetFilter.length; i++) {
      this.assetFilter[i].selected = this.allSelected;
    }
    this.freeSelected = this.allSelected;
    this.isSelectedFilter = false;
  }

  isAllSelected() {
    let checkAssigned = this.assetFilter.every(function (item: any) {
      return item.selected == true;
    });
    this.allSelected = false;
    if (checkAssigned && this.freeSelected) {
      this.allSelected = true;
      this.isSelectedFilter = false;
    }
  }

  addclass(index) {
    // const elem: HTMLElement = document.getElementById('mySidenav-right');
    // if (elem) {
    //     elem.hasClass
    // }

    // console.log("opened", this.oldIndex);
    // if( document.getElementById('submenu' + index))
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
    }
    this.oldIndex = index;
    this.isAssigned = !this.isAssigned;
    // document.getElementById('submenu' + index).classList.add('show');

    // document.getElementById('mobile-logo').classList.add('intro');
  }

  removeClass(index) {
    this.closeFilter = !this.closeFilter;
    if (!this.isfilterApplied) {
      this.removeFilters();
    }
    //document.getElementById('submenu1').classList.remove('show');
    document.getElementById("mainmenu").classList.remove("show");
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
      //this.closeFilter = !this.closeFilter;
    }
  }

  removeClassMain() {
    document.getElementById("mainmenu").classList.remove("show");
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
    }
  }

  deletEmail(value) {
    this.assignmentID = value.assignmentId;
    this.selectedImeiNumber = value.imeiNumber;

    Swal.fire({
      text: "Are you want to delete this.",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "#2196F3",
      confirmButtonColor: "#ff8c12",
    }).then((result) => {
      if (result.value) {
        let obj = {};
        obj["assignmentId"] = this.assignmentID;
        obj["imeiNumber"] = this.selectedImeiNumber;
        // obj['workingCountry'] = this.currentCountry;
        this.api
          .postData(this.apiConstant.ALERTS.DELETE_EMAIL_TRIGGER.URL, obj, true)
          .subscribe((res) => {
            if (res.data) {
              this.myModel.alldata.forEach((element ,index) => {
                if(this.assignmentID ==element.assignmentId){
                  this.myModel.alldata[index].isCollapsedEmail=false;
                  this.myModel.alldata[index].isEmailScheduled=false;

                   //this.isCollapsedEmail=true;
                 }
                 });
              //this.getAssets();
              // this.closeModal();
              this.toastr.success("Deleted Successfully!");
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // this.closeModal();
      }
    });
  }

  sendEmail() {
   // debugger;
    if (!this.selectDate) {
      this.validDate = false;
      return;
    }
    if (!this.time) {
      this.validTime = false;
      return;
    }
    /*if(this.time > this.selectDate) {
            this.validTime = false;
            return;
        }*/
    let date = new Date(this.selectDate);
    let time = new Date(this.time);
    let dateValue = date.getTime();
    let timeValue = time.getTime();
    let dateString = date.toDateString();
    let timeString = time.toDateString();

    /* if(dateString == timeString){
            if(dateValue>timeValue){
                this.validFuture = false;
                return;
            } 
        }*/

    if (this.selectDate && this.time) {
      let obj = {};
      let m = date.getMonth() + 1;
      let month = "";
      let d = date.getDate();

      let date1 = "";
      if (m < 10) {
        month = "0" + m.toString();
      } else {
        month = m.toString();
      }
      if (d < 10) {
        date1 = "0" + d.toString();
      } else {
        date1 = d.toString();
      }
      obj["date"] = date.getFullYear() + "/" + month + "/" + date1;
      let hours = "";
      let min = "";

      if (time.getHours() < 10) {
        hours = "0" + time.getHours();
      } else {
        hours = time.getHours().toString();
      }
      if (time.getMinutes() < 10) {
        min = "0" + time.getMinutes();
      } else {
        min = time.getMinutes().toString();
      }
      obj["time"] = hours + ":" + min;
      obj["assignmentId"] = this.assignmentID;
      obj["imeiNumber"] = this.selectedImeiNumber;
      //obj['workingCountry'] = this.currentCountry;
      this.api
        .postData(this.apiConstant.ALERTS.CREATE_EMAIL_TRIGGER.URL, obj, true)
        .subscribe((res) => {
          if (res.data) {
            this.toastr.success("Added Successfully!");
             this.myModel.alldata.forEach((element ,index) => {
            if(this.assignmentID ==element.assignmentId){
              this.myModel.alldata[index].isEmailScheduled=true;
               //this.isCollapsedEmail=true;
             }
             });
            this.closeModal();
           // this.getAssets();
          }
        });
    } else {
      return;
    }
  }

  createForm() {}

  isValid() {
    this.validTime = this.time != null ? true : false;
  }
  isValidTime(event: boolean): void {
    // this.valid = event;
  }
  getdateEvent(event) {
    if (event) {
      this.validDate = true;
    }
  }
}
