import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    //test
    localStorage.setItem(
      "access_token",
      JSON.stringify(
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN1a3JpdEB2b2x1bWV0cmVlLmNvbSIsInR5cGUiOiJBRE1JTiIsImlhdCI6MTYxNDY4MTQ4ODE3N30.gJQ0uMI5Z-qrANnrG6ifi5qlTaZk64cs-NLVpZetJn4"
      )
    );
    //dev
    //localStorage.setItem('access_token', JSON.stringify("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFuamFsaUB2b2x1bWV0cmVlLmNvbSIsInR5cGUiOiJBRE1JTiIsImlhdCI6MTYwMzQzMjI1NzQ1NX0.W1FoqN-qKFn_3PMEyA47nZxNSLoKNfhqV7lJs5U_RvQ"));
    this.router.navigate(["/maps"]);
  }
}
