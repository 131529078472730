import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import {ApiService} from "./api.service";
import {environment} from "../../environments/environment";
import {SessionService} from "./session.service";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private url = environment.API_BASE_URL;
  private socket;
  private accessToken;


  constructor( public sessionService: SessionService,  
               private api: ApiService) {
    this.accessToken = this.sessionService.get('access_token')
    this.socket = io(this.url, {
      query: {
        adminToken:  this.accessToken
      },
      autoConnect: true,
    });
    this.socket.on('connect', () => {
      this.socket.io.opts.transports = ['polling', 'websocket'];
      console.log(this.socket.connected, 'Connected', this.socket.id); 
      // 'G5p5...'
    });

    this.socket.on('latestLocation', (data) => {
      this.sessionService.onNewData(data);
      
    });
    // this.socket.on('assignAsset', (data) => {
    //   this.sessionService.onAssignNewData(data);
      
    // });
    // this.socket.on('unassignAsset', (data) => {
    //   this.sessionService.onUnassignNewData(data);
      
    // });
    // this.socket.on('mappingAssignDriver', (data) => {
    //   this.sessionService.onAssignDriver(data);
      
    // });
    // this.socket.on('mappingUnassignDriver', (data) => {
    //   this.sessionService.onUnassignDriver(data);
      
    // });

    this.socket.on('latestDriverLocation', (data) => {
      this.sessionService.onNewDriverData(data);
    });


    this.socket.on('alertCount', (data) => {
      console.log("hello")
      this.sessionService.onNewAlertCount(data);
    });
    
  
  
  }

  public sendMessage(message) {
    console.log('sent', message);
    this.socket.emit('message', message);
  }


  ngOnDestroy() {
    this.socket.on('disconnect', function () {
      console.log('disconnected');
    });
  }
}
