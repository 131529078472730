import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-listing',
  templateUrl: './asset-listing.component.html',
  styleUrls: ['./asset-listing.component.scss']
})
export class AssetListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
