import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AgmMap} from "@agm/core";
import {model} from "../../../shared/common.constant";
import {API_CONSTANT} from "../../../shared/api.constants";
import {SessionService} from "../../../services/session.service";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ILatLng} from '../../../../Directive/direction.directive';
import {SocketService} from '../../../services/socket.service';


declare var google: any;

@Component({
  selector: 'app-driver-live-location',
  templateUrl: './driver-live-location.component.html',
  styleUrls: ['./driver-live-location.component.scss']
})
export class DriverLiveLocationComponent implements OnInit {

    @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;
    @ViewChild('infoWindow', {static: false}) infoWindow: ElementRef;
    minZoom = 3;
    displayDirections = true;
    origin: ILatLng = {
        latitude: 0,
        longitude: 0
      };
      // New York City, NY, USA
    destination: ILatLng = {
        latitude: 0,
        longitude: 0
    };
    origin1: ILatLng = {
        latitude: 0,
        longitude: 0
      };
      // New York City, NY, USA
    destination1: ILatLng = {
        latitude: 0,
        longitude: 0
    };
    infoWindowOpened = null;
    previous_info_window = null;
    myModel = new model();
    apiConstant = API_CONSTANT;
    zoom = 2;
    assetValue = false;
    arrLatLong = [];
    private mapBounds: any;
    private mapInstance: any;
    private selectedAsset: any;
    public index: any;
    public address: any;
    public tempData;
    latLong = [76.7796, 30.7334];
    pastLocations = [];

    private id: any;
    public markers = [];
    public tempArr = [];
    token='';
    username='';
    count = 0;
    directionsService : any 
    directionsRenderer: any;
    constructor(private sessionService: SessionService,
                private api: ApiService,
                private router: Router,
                private route: ActivatedRoute, 
                private socket: SocketService) {
                    
        
        this.route.queryParams.subscribe(param => {
            console.log(param);
            
            if(param['key']){
                this.token = param['key'];
                this.id = param['assignmentId'];
                this.username = param['username'];
                this.sessionService.set('username', this.username);
                this.sessionService.set('access_token', this.token);
                this.verifyToken();
            }
            else{
                this.id = param['assignmentId'];
                this.trackAsset();
            }
        });

        this.sessionService.driverCurrentData.subscribe(res => {
            console.log(this.count, res, this.myModel.alldata['driverData'], "socket driver tracking")
            
            if (res) {
                this.tempData = res;
                
           

                 if( this.myModel.alldata['driverData'] && res.driverId == this.myModel.alldata['driverData'].driverId){
                    if( this.myModel.alldata['assignmentId'] && res.assignmentId == this.myModel.alldata['assignmentId']){
                     this.count = this.count + 1;
                      console.log( this.count, res, this.myModel.alldata['driverData'].driverId, "socket driver tracking")
                    
                    this.address = res.currentLocationAddress;
                    let lat = JSON.parse(this.tempData['currentLocation'][1])
                    let lng =  JSON.parse(this.tempData['currentLocation'][0])
                    this.pastLocations.push({lat: lat, lng: lng});
                    this.origin= {
                        latitude: this.tempData['currentLocation'][1],
                        longitude: this.tempData['currentLocation'][0]
                    };
                   this.myModel.alldata['driverData'].distance = this.myModel.alldata['driverData'].distance + this.calculateOdometerDistance( this.markers[1][1],  this.markers[1][0],this.origin.latitude,this.origin.longitude);
                   console.log("distance",this.myModel.alldata['driverData'].distance,this.count)
                   this.markers[1] = res.currentLocation;
                   this.myModel.alldata['driverData'].locationUpdatedAt = this.tempData.locationUpdatedAt;
                   this.directionsRenderer.setDirections({routes: []});
                     
                    this.directionsRenderer.setMap(this.mapInstance);
                    
                    this.directionsService.route({
                        origin: {lat: this.origin.latitude, lng: this.origin.longitude},
                        destination: {lat: this.destination.latitude, lng: this.destination.longitude},
                        waypoints: [],
                        optimizeWaypoints: true,
                        travelMode: google.maps.TravelMode.DRIVING,
                        provideRouteAlternatives : true
                    
                    }, (response, status) => {
                        if (status === 'OK') {
                            this.directionsRenderer.setDirections(response);
                            console.log("latlat",response)
                            
                        } else {
                            console.log('Directions request failed due to ' + status);
                        }
                    });
                 
                 }
                }
              
               
            }
        });
        
    }

    ngOnInit() {}

    verifyToken() {
        let obj = {};
        // obj['token'] = this.token;
        this.api.postData(this.apiConstant.VALIDATE.VALIDATING_USER.URL, obj, true).subscribe(res => {
            if (res) {
                this.sessionService.set('access_token', this.token);
                this.trackAsset();
            }
        }, error => {
            this.router.navigate(['/invalid-user']);
        });
    }


    close_window() {
        if (this.previous_info_window != null) {
            this.previous_info_window.close();
        }
    }

    select_marker(infoWindow) {
        if (this.previous_info_window == null)
            this.previous_info_window = infoWindow;
        else {
            this.infoWindowOpened = infoWindow;
            this.previous_info_window.close();
        }
        this.previous_info_window = infoWindow;
    }

    calculateOdometerDistance(lat1, lon1, lat2, lon2) {
        return this.sessionService.calculateOdometerDistance(lat1, lon1, lat2, lon2);

    }

    assetInfo(i, type) {
        this.index = i;
        this.selectedAsset = this.arrLatLong[i];
        this.getaddress(this.selectedAsset);
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
        // this.mapInstance.setZoom(8);
        if (type === 'assetList') {
            // this.close_window();
        }
    }

    trackAsset() {
        let obj = {
            assignmentId: this.id
        }
        this.pastLocations = [];
        this.api.postData(this.apiConstant.MAP.DRIVER_PATH_DETAILS.URL, obj, true).subscribe(res => {
            if (res.data) {
                this.myModel.alldata = res.data[0];
                if(this.myModel.alldata['driverData']['pastLocation']) {
                    this.myModel.alldata['driverData']['pastLocation'].forEach(e => {
                        this.pastLocations.push({lat:e[1], lng:e[0]});
                        //console.log(e);
                    });
                }
               
                
                this.markersArray();
               
               // this.updateBounds();

            }
            // console.log(this.myModel.alldata['driverData'].pastLocation,'array-pasttttttttttt');
        });

    }

    ngAfterViewInit() {
        this.agmMap.mapReady.subscribe(map => {
            this.mapInstance = map;
            this.directionsService = new google.maps.DirectionsService;
            if (!this.directionsRenderer) {
                // if you already have a marker at the coordinate location on the map, use suppressMarkers option
                // suppressMarkers prevents google maps from automatically adding a marker for you
                this.directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#6B6B6B",
                    strokeOpacity: 0,
                    strokeWeight: 4,
                    icons: [{
                      icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#6B6B6B',
                        fillOpacity: 1,
                        scale: 2,
                        strokeColor: '#6B6B6B',
                        strokeOpacity: 1,
                      },
                      offset: '0',
                      repeat: '10px'
                    }]
                  },
              });
                
              }
        });
        setTimeout(() => {
            this.mapBounds = new google.maps.LatLngBounds();
        }, 500);
       
    }

    openInfoWindow(asset) {
        console.log('info_Window', asset)
        if (asset) {
            this.getaddress(asset);
            // this.updateBounds();
            this.mapInstance.setCenter(new google.maps.LatLng(asset[1], asset[0]));
            //this.mapInstance.setZoom(12);
        }
    }


    updateBounds() {
        this.mapBounds = new google.maps.LatLngBounds();
        let driverData = this.myModel.alldata['driverData']['pastLocation'];
        driverData.forEach(marker => {
            if (marker && marker && marker.latlong) {
                const tempPosition = new google.maps.LatLng(marker.latLong[1], marker.latLong[0]);
                this.mapBounds.extend(tempPosition);
            }

        });
        console.log(this.mapInstance, this.mapBounds);
        this.mapInstance.fitBounds(this.mapBounds);
        this.mapInstance.setZoom(50);
    }

    async getaddress(selectedAsset) {
        this.address = await this.sessionService.getAddressusingLatLong(selectedAsset[1], selectedAsset[0]);
    }

    markersArray() {
        let data = this.myModel.alldata
        console.log(data);
        let obj = {};
        let tempArr = [];
        this.markers = [];
        obj['updatedAt'] = this.myModel.alldata['startDateTime']
        obj['latLong'] = data['sourceLatLong']
       
        this.origin= {
            latitude: data['driverData']['currentLatLong'][1],
            longitude: data['driverData']['currentLatLong'][0]
        };
            // New York City, NY, USA
        this.destination = {
            latitude: data['destinationLatLong'][1],
            longitude: data['destinationLatLong'][0]
        };

        if(this.myModel.alldata['driverData'].pastLocation && this.myModel.alldata['driverData'].pastLocation.length){
            this.destination1 = {
                latitude: this.myModel.alldata['driverData'].pastLocation[0][1],
                longitude: this.myModel.alldata['driverData'].pastLocation[0][0]
            };
            this.origin1= {
                latitude: data['sourceLatLong'][1],
                longitude: data['sourceLatLong'][0]
            };
            this.myModel.alldata['driverData'].pastLocation = [obj, ...this.myModel.alldata['driverData'].pastLocation];
        } else {
           
            
        }
        
        
       // this.directionsRenderer.setMap(this.mapInstance);
        
        this.directionsService.route({
            origin: {lat: this.origin.latitude, lng: this.origin.longitude},
            destination: {lat: this.destination.latitude, lng: this.destination.longitude},
            waypoints: [],
            optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives : true
           
        }, (response, status) => {
            if (status === 'OK') {
                this.directionsRenderer.setDirections(response);
                console.log("latlat",response)
                
            } else {
                console.log('Directions request failed due to ' + status);
            }
        });

                

        this.markers.push(data['sourceLatLong']);
        this.markers.push(data['driverData']['currentLatLong']);
        this.markers.push(data['destinationLatLong']);

        //this.mapInstance.setCenter(new google.maps.LatLng(this.markers[1][1], this.markers[1][0]));
        console.log(this.markers, 'markersssssssssssssssss')
        // this.markers.push(markers);
        // console.log(this.myModel.alldata['driverData'].pastLocation[0]);
    }

}
