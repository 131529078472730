export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
export const API_CONSTANT = {
    VALIDATE: {
        VALIDATING_USER: {
            URL: 'dmsAdmin/validateToken'
        }
    },
    HARDWARE: {
        ADD_HARDWARE: {
            URL: 'hardware/create'
        },
        EDIT_HARDWARE: {
            URL: 'hardware/updateHardware'
        },
        LIST_HARDWARE: {
            URL: 'hardware/view'
        },
        LIST_HARDWARE_BY_ID: {
            URL: 'hardware/viewHardware'
        },

    },
    SIM_CARD: {
        ADD_SIM_CARD: {
            URL: 'simcard/create'
        },
        EDIT_SIM_CARD: {
            URL: 'simcard/updateSimcard'
        },
        LIST_SIM_CARD: {
            URL: 'simcard/view'
        },
        LIST_SIM_CARD_BY_ID: {
            URL: 'simcard/viewSimcard'
        }
    },
    ASSETS: {
        ASSET_LISTING: {
            URL: 'assets/assetListing'
        },
        SINGLE_ASSET_TRIP_DETAIL: {
            URL: 'assets/getTripDetails'
        },
        MULTIPLE_ASSET_TRIP_DETAIL: {
            URL: 'assets/getMultiTripDetails'
        },
        SINGLE_ASSET_INFO: {
            URL: 'assets/getAsset'
        },
        SHARE_LOCATION: {
            URL: 'assets/shareLiveLocation'
        },
        TRACK_ASSET: {
            URL: 'assets/trackLiveLocation'
        },
        LAST_LOCATION_TRIPS: {
            URL: 'assets/previousTrips'
        },
        
        DRIVER_LISTING: {
            URL: 'assets/getAllDrivers'
        },
    },
    MAP: {
        TRACK_ASSET_LIVE: {
            URL: 'dmsAdmin/assignmentPathDetails'
        },
        DRIVER_PATH_DETAILS: {
            URL: 'dmsAdmin/driverPathDetails'
        },
        TRACK_ASSET_LOGS: {
            URL: 'assets/activityTimeline'
        },
    },
    ZONES: {
        GET_ZONE_TYPES: {
            URL: 'zone/getZoneTypes'
        },
        CREATE_ZONE: {
            URL: 'zone/createZone'
        },
        LIST_ZONES: {
            URL: 'zone/zoneListing'
        },
        EDIT_ZONE: {
            URL: 'zone/editZone'
        },
        ZONE_ASSETS: {
            URL: 'zone/assetsInZone'
        },
        ZONE_ACTIVITY: {
            URL: 'zone/getZoneActivity'
        },
        ZONE_GET_ZONES_ALERTS:{
            URL: 'zone/getZonesForAlerts'
        }
    },
    ALERTS: {
        LIST_ALERTS: {
            URL: 'alert/alertListing'
        },
        LIST_CONTACT: {
            URL: 'alert/getContacts'
        },
        CREATE_ALERT: {
            URL: 'alert/createAlert'
        },
        VALIDATE_ALERT_NAME: {
            URL: 'alert/validateAlertName'
        },
        GET_ALERT_LOGS: {
            URL: 'alert/getAlertLogsInAlert'
        },
        GET_ALERT_ASSETS: {
            URL: 'alert/getAssetsInAlert'
        },
        CREATE_EMAIL_TRIGGER: {
            URL: 'alert/scheduleEmail'
        },
        GET_EMAIL_TRIGGER: {
            URL: 'alert/getScheduledEmail'
        },
        DELETE_EMAIL_TRIGGER: {
            URL: 'alert/deleteScheduledEmail'
        },
    }
}
