import {Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {AgmMap,GoogleMapsAPIWrapper} from "@agm/core";
import {model} from "../../../shared/common.constant";
import {API_CONSTANT} from "../../../shared/api.constants";
import {SessionService} from "../../../services/session.service";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ILatLng} from '../../../../Directive/direction.directive';
import {SocketService} from '../../../services/socket.service';
import { DatePipe ,formatDate } from '@angular/common';



declare var google: any;

@Component({
    selector: 'app-track-live-truck',
    templateUrl: './track-live-truck.component.html',
    styleUrls: ['./track-live-truck.component.scss']
})
export class TrackLiveTruckComponent implements OnInit {

    @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;
    @ViewChild('infoWindow', {static: false}) infoWindow: ElementRef;
    origin: ILatLng = {
        latitude: 0,
        longitude: 0
      };
      // New York City, NY, USA
    destination: ILatLng = {
        latitude: 0,
        longitude: 0
    };
    origin1: ILatLng = {
        latitude: 0,
        longitude: 0
      };
      // New York City, NY, USA
    destination1: ILatLng = {
        latitude: 0,
        longitude: 0
    };
    displayDirections = true;
    minZoom = 3;
    infoWindowOpened = null;
    previous_info_window = null;
    myModel = new model();
    apiConstant = API_CONSTANT;
    zoom = 2;
    assetValue = false;
    arrLatLong = [];
    private mapBounds: any;
    private mapInstance: any;
    private selectedAsset: any;
    public index: any;
    public address: any;
    public tempData;
    latLong = [76.7796, 30.7334];
    pastLocations = [];

    private id: any;
    public markers = [];
    public tempArr = [];
    token='';
    username='';
    private directionsService: any;
    private directionsRenderer: any;
    isCollapsed1 = true;
    isCollapsed2 = true;
    isCollapsed3 = true;
    isCollapsed4 = true;
    isCollapsed5 = true;
    activityLogs : any;
    tripStarted : any;
    vehicleStarted :any;
    vehicleStop :any;
    vehicleIdle :any;
    tripArray = [];
    tab = false;
    iconImage:any;
    currentMarker : any;
    infowindow : any;
    angle : any ;
    constructor(private sessionService: SessionService,
                private api: ApiService,
                private router: Router,
                private route: ActivatedRoute,
                private changeDetection: ChangeDetectorRef,
                private socket: SocketService,
                private datePipe: DatePipe) {
        this.route.queryParams.subscribe(param => {
            console.log(param);
            if(param['key']){
                this.token = param['key'];
                this.id = param['assignmentId'];
                this.username = param['username'];
                this.sessionService.set('username', this.username);
                this.sessionService.set('access_token', this.token);
                this.verifyToken();
            }
            else{
                this.id = param['assignmentId'];
                this.trackAsset();
                //this.trackLogs();
            }
        });

       
        this.sessionService.currentData.subscribe(res => {
            console.log('###############################')
            if (res) {
                this.tempData = res;
                console.log("socket data incomeing")
                console.log('socket data', res, this.myModel.alldata['assetData']);
                if (this.myModel.alldata['assetData'] && this.myModel.alldata['assetData'].imeiNumber === this.tempData.imeiNumber) {
                    if( this.myModel.alldata['assignmentId'] &&  this.tempData.assignmentId == this.myModel.alldata['assignmentId']){
                    //this.pastLocations.push({lat:this.tempData['currentLatLong'][1], lng:this.tempData['currentLatLong'][0]});
                    let lat = JSON.parse(this.tempData['currentLatLong'][1]);
                    let lng =  JSON.parse(this.tempData['currentLatLong'][0]);
                    this.pastLocations.push({lat: lat, lng: lng});
                    this.origin= {
                        latitude: this.tempData['currentLatLong'][1],
                        longitude: this.tempData['currentLatLong'][0]
                    };
                    this.myModel.alldata['assetData'].distance = this.myModel.alldata['assetData'].distance + this.calculateOdometerDistance( this.markers[1][1],  this.markers[1][0],this.origin.latitude,this.origin.longitude);
                    this.markers[1] = this.tempData.currentLatLong;
                    console.log(this.tempData)
                    this.address =  this.tempData.address;
                    this.myModel.alldata['assetData'].updatedAt = this.tempData.updatedAt;
                    console.log(this.tempData.direction,"**********************************")
                    this.setDirection(this.tempData.direction);
                   //this.mapInstance.setCenter({, lng: lng});
                  
               /*  let image = {
                    url: "/assets/img/orange-truck.svg",
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(50, 50)
                  };
                this.currentMarker = new google.maps.Marker({
                    position: {lat: this.origin.latitude, lng: this.origin.longitude},
                    map: this.mapInstance,
                    title: 'Hello World!',
                    icon:  image,
                    shape: { coords: [1, 1,1, 10, 20, 18, 20],
                        type: 'poly'}
                });*/

               
                   this.currentMarker.setPosition( {lat: this.markers[1][1], lng: this.markers[1][0]});
                   //this.currentMarker.setRotation(this.angle);
                   this.mapInstance.setCenter(new google.maps.LatLng(this.markers[1][1], this.markers[1][0]));
                   console.log(this.mapInstance.getZoom());
                   let zoom = this.mapInstance.getZoom();
                   this.mapInstance.setZoom(zoom);

                   this.directionsRenderer.setDirections({routes: []});
                     
                   this.directionsRenderer.setMap(this.mapInstance);
                   
                   this.directionsService.route({
                       origin: {lat: this.origin.latitude, lng: this.origin.longitude},
                       destination: {lat: this.destination.latitude, lng: this.destination.longitude},
                       waypoints: [],
                       optimizeWaypoints: true,
                       travelMode: google.maps.TravelMode.DRIVING,
                       provideRouteAlternatives : true
                   
                   }, (response, status) => {
                       if (status === 'OK') {
                           this.directionsRenderer.setDirections(response);
                           console.log("latlat",response)

                     /*   const middleStep = (response.routes[0].legs[0].steps.length / 2).toFixed();
                     const infowindow2 = new google.maps.InfoWindow();
                     var imeiNo = (this.myModel.alldata['assetData'].assetLabel ? this.myModel.alldata['assetData'].assetLabel : this.myModel.alldata['assetData'].imeiNumber)
                     var distance = (this.myModel.alldata['assetData'].distance ? this.myModel.alldata['assetData'].distance.toFixed(2) : '0');
                     var lastUpdated = (new Date(this.myModel.alldata['assetData'].updatedAt));
                     var address = this.address;
                     infowindow2.setContent(` <table class="table-responsive table" style="margin-bottom:0;"> <tbody>
                     <tr> <td class="imeiNo"> ${imeiNo}</td> </tr> <tr> <td>${address} </td> </tr> <tr> <td> ${distance} kms travelled </td></tr><tr><td> Updated At: ${this.datePipe.transform(new Date(this.myModel.alldata['assetData'].updatedAt), 'MMM d, y, h:mm:ss')}</td></tr>
                     </tbody>` );
                     infowindow2.setPosition({lat: this.markers[1][1], lng: this.markers[1][0]});
                     infowindow2.setMap(this.mapInstance);*/
                      // this.select_marker('infoWindow');
                      // this.openInfoWindow([this.markers[1][1], this.markers[1][0]]);   
                       } else {
                       }
                   });

                   
                      
                }
            }
              
            }
        });
    }

    ngOnInit() {

        this.tripStarted = {};
        this.vehicleStarted = {};
        this.vehicleStop = {};
        this.vehicleIdle ={};
    }
    
    ngAfterViewInit() {
        console.log('*************************************************************************')
        setTimeout(() => {
           
          
        }, 1000);
        this.agmMap.mapReady.subscribe(map => {
            this.mapInstance = map;
            this.mapBounds = new google.maps.LatLngBounds();
            this.directionsService = new google.maps.DirectionsService();
             this.currentMarker = new google.maps.Marker();
            this.directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#6B6B6B",
                  strokeOpacity: 0,
                  strokeWeight: 4,
                  icons: [{
                    icon: {
                      path: google.maps.SymbolPath.CIRCLE,
                      fillColor: '#6B6B6B',
                      fillOpacity: 1,
                      scale: 2,
                      strokeColor: '#6B6B6B',
                      strokeOpacity: 1,
                    },
                    offset: '0',
                    repeat: '10px',
                    fixedRotation:false
                  }]
                },
            });
            this.sessionService.calculateAndDisplayRoute();
            var contentString = 'hello';
            this.infowindow = new google.maps.InfoWindow({
                content: contentString
            });
            this.directionsRenderer.setMap(this.mapInstance);
           // this.directionsRenderer = map;
        });
    }

    verifyToken() {
        let obj = {};
        // obj['token'] = this.token;
        this.api.postData(this.apiConstant.VALIDATE.VALIDATING_USER.URL, obj, true).subscribe(res => {
            if (res) {
                this.sessionService.set('access_token', this.token);
                this.trackAsset();
            }
        }, error => {
            this.router.navigate(['/invalid-user']);
        });
    }


    close_window() {
        if (this.previous_info_window != null) {
            
           this.previous_info_window.close();
        }
    }

    select_marker(infoWindow) {
        if (this.previous_info_window == null)
            this.previous_info_window = infoWindow;
        else {
            this.infoWindowOpened = infoWindow;
           // this.previous_info_window.close();
        }
        this.previous_info_window = infoWindow;
    }

    calculateOdometerDistance(lat1, lon1, lat2, lon2) {
        return this.sessionService.calculateOdometerDistance(lat1, lon1, lat2, lon2);

    }

    assetInfo(i, type) {
        this.index = i;
        this.selectedAsset = this.arrLatLong[i];
        this.getaddress(this.selectedAsset);
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
        // this.mapInstance.setZoom(8);
        if (type === 'assetList') {
            // this.close_window();
        }
    }
    onOpenChange (value){
        
        if(!value){
            
            this.trackLogs();
        }
    }

    trackLogs() {
        
        let obj = {
            assignmentId: this.id
        }
        this.tripStarted = {isCollapsed : false };
        this.vehicleStarted = { };
        this.vehicleStop = {};
        this.vehicleIdle ={};
            this.api.postData(this.apiConstant.MAP.TRACK_ASSET_LOGS.URL, obj, true).subscribe(res => {
                if (res.data) {
                    if( res.data.tripArray) { 
                        this.tripArray = res.data.tripArray;
                    this.tripArray.forEach(e => {
                            if(e.movingStatus == 'idle') {
                            this.vehicleIdle = e;
                            this.vehicleIdle.isCollapsed = false;
                            }
                            if(e.movingStatus == 'parked') {
                            this.vehicleStop = e;
                            this.vehicleStop.isCollapsed = false;
                            }
                            if(e.movingStatus == 'moving') {
                                this.vehicleStarted = e;
                                this.vehicleStarted.isCollapsed = false;
                            }
                        // console.log(e);
                        }); 
                    }
                    this.tripStarted['isCollapsed'] = false;
                    
                    if( res.data.tripStarted){
                        this.tripStarted = res.data.tripStarted;
                        this.tripStarted['isCollapsed']  = false;
                       
                    } 
            }
            });
    }

    trackAsset() {
        let obj = {
            assignmentId: this.id
        }
        this.pastLocations = [];
        this.api.postData(this.apiConstant.MAP.TRACK_ASSET_LIVE.URL, obj, true).subscribe(res => {
            if (res.data) {
                this.myModel.alldata = res.data[0];
                this.myModel.alldata['assetData']['pastLocation'].forEach(e => {
                    this.pastLocations.push({lat:e[1], lng:e[0]});
                   // console.log(e);
                });
                this.markersArray();
                this.updateBounds();

              

                let image = {
                    url: "/assets/img/orange-icon-svg.svg",
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(50, 50)
                  };
                this.currentMarker = new google.maps.Marker({
                    position: {lat: this.markers[1][1], lng: this.markers[1][0]},
                    // map: this.mapInstance,
                    title: 'Hello World!',
                    icon:  image,
                    shape: { coords: [1, 1,1, 10, 20, 18, 20],
                        type: 'poly'},
                        rotatation: this.angle
                
                });
                 //this.currentMarker.setRotation(this.angle);

               
               
                this.currentMarker.addListener('click', function() {
                    console.log("click")
                    
                    this.infowindow.setPosition( new google.maps.LatLng(this.markers[1][1], this.markers[1][0]));
                    this.infowindow.open(this.mapInstance);
                });
                  
                  this.mapInstance.setCenter(new google.maps.LatLng(this.markers[1][1], this.markers[1][0]));
                  this.mapInstance.setZoom(15);
                  this.currentMarker.setMap(this.mapInstance);
                  setTimeout(() => {
                    this.setDirection(this.myModel.alldata['aassetData'].direction);
                }, 1000);
        
                
            }

           
            // console.log(this.myModel.alldata['assetData'].pastLocation,'array-pasttttttttttt');
        });

    }



    openInfoWindow(asset) {
        console.log('info_Window', asset)
        if (asset) {
            this.getaddress(asset);
            this.updateBounds();
            this.mapInstance.setCenter(new google.maps.LatLng(asset[1], asset[0]));
            this.mapInstance.setZoom(8);
        }
    }


    updateBounds() {
        this.mapBounds = new google.maps.LatLngBounds();
        let assetData = this.myModel.alldata['assetData']['pastLocation'];
        assetData.forEach(marker => {
            if (marker && marker && marker.latlong) {
                const tempPosition = new google.maps.LatLng(marker.latLong[1], marker.latLong[0]);
                this.mapBounds.extend(tempPosition);
            }

        });
        console.log(this.mapInstance, this.mapBounds);
        //this.mapInstance.fitBounds(this.mapBounds);
       // this.mapInstance.setZoom(5);
    }
    async getaddress(selectedAsset) {
        this.address = await this.sessionService.getAddressusingLatLong(selectedAsset[1], selectedAsset[0]);
    }
    markersArray() {
        let data = this.myModel.alldata
        console.log(data);
        let obj = {};
        let tempArr = [];
        obj['updatedAt'] = this.myModel.alldata['startDateTime']
        obj['latLong'] = data['sourceLatLong']
          // New York City, NY, USA
          console.log(data['destinationLatLong'][1],data['destinationLatLong'][0])
        this.destination = {
            latitude: data['destinationLatLong'][1],
            longitude: data['destinationLatLong'][0]
        };
        
        if(this.myModel.alldata['assetData'].pastLocation.length){
            this.destination1 = {
                latitude: this.myModel.alldata['assetData'].pastLocation[0][1],
                longitude: this.myModel.alldata['assetData'].pastLocation[0][0]
            };
            this.myModel.alldata['assetData'].pastLocation = [obj, ...this.myModel.alldata['assetData'].pastLocation]; 
            this.origin1= {
                latitude: data['sourceLatLong'][1],
                longitude: data['sourceLatLong'][0]
            };       
            this.origin= {
                latitude: data['assetData']['currentLatLong'][1],
                longitude: data['assetData']['currentLatLong'][0]
            };
        } else {
            this.origin= {
                latitude: data['sourceLatLong'][1],
                longitude: data['sourceLatLong'][0]
            };
        }
            this.directionsService = new google.maps.DirectionsService();
                   
            this.directionsService.route({
                origin: {lat: this.origin.latitude, lng: this.origin.longitude},
                destination: {lat: this.destination.latitude, lng: this.destination.longitude},
                waypoints: [],
                optimizeWaypoints: true,
                travelMode: google.maps.TravelMode.DRIVING,
                provideRouteAlternatives : true
            
            }, (response, status) => {
                if (status === 'OK') {
                    this.directionsRenderer.setDirections(response);
                    console.log("latlat",response)
                   // this.select_marker('infoWindow');
                   // this.openInfoWindow([this.markers[1][1], this.markers[1][0]]);
                    
                } else {
                    console.log('Directions request failed due to ' + status);
                }
            });
          // New York City, NY, USA
        this.markers.push(data['sourceLatLong']);
        this.markers.push(data['assetData']['currentLatLong']);
        this.markers.push(data['destinationLatLong']);
        console.log(this.markers, 'markersssssssssssssssss')       
        setTimeout(() => {
            this.setDirection(data['assetData'].direction);
          
        }, 1000);

    }
    
    
    setDirection(rotationArg){
        var loc1 = new google.maps.LatLng(this.origin.latitude,this.origin.longitude);
        var loc2 = new google.maps.LatLng(this.destination.latitude,this.destination.longitude);
   
        let angle = google.maps.geometry.spherical.computeHeading(loc1,loc2);
        let element ;
        element =  document.querySelectorAll('img[src="/assets/img/orange-icon-svg.svg"]');
        if(element.length){
            element[0].style.transform = ("rotate(" + rotationArg + "deg)");
        }
        if(angle<0){
            angle = 360 + angle;
        } 
        if(angle >360){
            angle= angle - 360;
        }
     //  this.mapInstance.setHeading(angle);
      
        this.angle = rotationArg;
        console.log("angle",this.angle)
    }
    
    /* getBearingBetweenTwoPoints1(lat11, lon11, lat22, lon22) {

        let lat1 = this.degreesToRadians(lat11);
        let  long1 = this.degreesToRadians(lon11);
        let lat2 = this.degreesToRadians(lat22);
        let long2 = this.degreesToRadians(lon22);


        let dLon = (long2 - long1);


        let y = Math.sin(dLon) * Math.cos(lat2);
        let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
                * Math.cos(lat2) * Math.cos(dLon);

                let radiansBearing = Math.atan2(y, x);


        return this.radiansToDegrees(radiansBearing);
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180.0;
    }

    radiansToDegrees(radians) {
        return radians * 180.0 / Math.PI;
    }*/
}
