import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {API_CONSTANT} from './../../shared/api.constants';
import Swal from "sweetalert2";
import {Router} from "@angular/router";

export enum SelectionType {
    single = "single",
    multi = "multi",
    multiClick = "multiClick",
    cell = "cell",
    checkbox = "checkbox"
}

@Component({
    selector: 'app-hardwares',
    templateUrl: './hardwares.component.html',
    styleUrls: ['./hardwares.component.scss']
})
export class HardwaresComponent implements OnInit {

    entries: number = 10;
    selected: any[] = [];
    temp = [];
    activeRow: any;
    apiConstant: any;
    rows = [];
    SelectionType = SelectionType;
    private currentRow: any;

    constructor(private api: ApiService,
                private router: Router) {
        // this.temp = this.rows.map((prop, key) => {
        //     return {
        //         ...prop,
        //         id: key
        //     };
        // });
        this.apiConstant = API_CONSTANT;

    }

    ngOnInit() {
        this.getListing();
    }

    getListing() {
        let arr = []
        let obj = {};
        this.api.getData(this.apiConstant.HARDWARE.LIST_HARDWARE.URL, '', true).subscribe(res => {
            if (res.data.length) {
                for (let ele of res.data) {
                    obj =
                    obj['imeiNumber'] = ele.imeiNumber;
                    obj['serialNumber'] = ele.serialNumber;
                    obj['deviceType'] = ele.deviceType;
                    obj['simCard'] = ele.simCardId;
                    arr.push(obj);
                }
                // res.data.forEach(ele => {
                //
                // });
                this.temp = arr.map((prop, key) => {
                    return {
                        ...prop,
                        id: key
                    };
                });
                this.rows = arr;
            }
        });
    }


    entriesChange($event) {
        this.entries = $event.target.value;
    }

    filterTable($event) {
        let val = $event.target.value;
        this.temp = this.rows.filter(function (d) {
            for (var key in d) {
                if (d[key].toLowerCase().indexOf(val) !== -1) {
                    return true;
                }
            }
            return false;
        });
    }

    onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    onActivate(event) {
        this.activeRow = event.row;
    }

    edit() {
        this.router.navigate(['/add-hardware'], {queryParams: {id: this.activeRow.simcardId}});
        // console.log('called')
    }

    confirmDelete() {
        this.currentRow = this.activeRow;
        console.log('deleted');
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this assignment!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.delete();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal.fire(
                //   'Cancelled',
                //   'Your assignment is safe :)',
                //   'error'
                // )
            }
        });
    }

    delete() {
        let body = {};
        body['hardwareId'] = this.currentRow.simcardId;
        body['isDeleted'] = !this.currentRow.isDeleted;
        this.api.postData(this.apiConstant.SIM_CARD.EDIT_SIM_CARD.URL, body, true).subscribe(res => {
            this.temp.splice(this.currentRow.id, 1);
            this.rows.splice(this.currentRow.id, 1);

        });
    }


    block() {
        let body = {};
        let title = '';
        this.currentRow = this.activeRow;
        body['hardwareId'] = this.currentRow.simcardId;
        body['status'] = !this.currentRow.status;
        // this.myModel.assignments.splice(this.index, 1);
        this.api.postData(this.apiConstant.SIM_CARD.EDIT_SIM_CARD.URL, body, true).subscribe(res => {
            if (!this.currentRow.status) {
                title = 'Blocked successfully';
            } else {
                title = 'Unblocked successfully';
            }
            Swal.fire(
                title,
                'success'
            );
        });
    }


}
