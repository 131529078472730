import {Injectable} from '@angular/core';
import {HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  config: Partial<IndividualConfig> = {
    timeOut: 1000
  };
  checkOfflineStatus = true;
  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    public router: Router,
    private sessionService: SessionService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    const token = this.sessionService.get('access_token');
    // const user_data = this.sessionService.get('user_data');
    // let token;
    // if (user_data) {
    //   token = user_data['accessToken'];
    // }
    if (token) {
      req = req.clone({headers: req.headers.set('authorization', 'bearer ' + token)});
    }
    if (req.reportProgress === true) {
      this.sessionService.loaderOn(true);
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.toastr.success(event.body.message);
          this.sessionService.loaderOn(false);
        }
      },
      (err: any) => {
        this.sessionService.loaderOn(false);
        if (err instanceof HttpErrorResponse) {
          if (err.error.statusCode === 401) {
            localStorage.removeItem('access_token');
            this.router.navigate(['invalid-user']);
            this.toastr.error('Session expired. Please Login again.');
          } else {
            if(err.error.message){
              this.toastr.error(err.error.message || 'Something went wrong', 'OOPS!');
            } else {
              this.sessionService.createOnline$().subscribe(isOnline => {
                if (isOnline && this.checkOfflineStatus) {
                    this.sessionService.getInternetStatus(true);
                    //this.toastr.success('Back to Online!');
                } else if (!isOnline) {
                    this.sessionService.getInternetStatus(false);
                    this.checkOfflineStatus = true;
                    this.toastr.error('Internet Connection Lost!');
                }
              });

            }
            
          }
        }
      }));
  }
}
