import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {SessionService} from "./services/session.service";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  
    private subscription: Subscription;
    public onlineEvent: Observable<Event>;
    public offlineEvent: Observable<Event>;
    public subscriptions: Subscription[] = [];
    public connectionStatusMessage: string;
    public connectionStatus: string;

    public loading: Boolean;
    // check offline staus then show back to online
    checkOfflineStatus = false;

    constructor(private router: Router,
                private sessionService: SessionService,
                private toastr: ToastrService) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                window.scrollTo(0, 0);
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

        this.sessionService.createOnline$().subscribe(isOnline => {
            if (isOnline && this.checkOfflineStatus) {
                this.sessionService.getInternetStatus(true);
               this.toastr.success('Back Online! Please refresh the page.');
            } else if (!isOnline) {
                this.sessionService.getInternetStatus(false);
                this.checkOfflineStatus = true;
                this.toastr.error('Internet Connection Lost!');
            }
        });
        
        this.loading = false;
        this.subscription = this.sessionService.loaderStatus.subscribe(status => {
            if(status!= null){
                this.loading = status;
            }
           
        });
    }


    ngOnInit() {
        window.scrollTo(0, 0);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
