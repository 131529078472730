import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { model } from "../../shared/common.constant";
import { API_CONSTANT } from "../../shared/api.constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { SessionService } from "../../services/session.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import Swal from "sweetalert2";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { fromEvent } from "rxjs";
import { AgmMap } from "@agm/core";
import { ClusterStyle } from "@agm/js-marker-clusterer/services/google-clusterer-types";
import { ActivatedRoute, Router } from "@angular/router";

declare var google: any;

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent implements OnInit {
  @ViewChild("searchElement", { static: true }) searchElement: ElementRef;
  @ViewChild("searchElementAsset", { static: true })
  searchElementAsset: ElementRef;
  @ViewChild("AgmMap", { static: false }) agmMap: AgmMap;
  modalOption: NgbModalOptions = null;
  public hasFullView = false;
  public ListingBtn = true;
  private isfullscreen = false;
  myModel = new model();
  alertName = "";
  bsValue = "";
  odometer: any = ["0", "0", "0", "0", ".", "0", "0"];
  apiConstant = API_CONSTANT;
  // index = 0;
  selectedImei: any;
  selectedwallet = "home";
  selectedAsset: any;
  address: any;
  private mapBounds: any;
  private mapInstance: any;
  private searchChangeObserver: any;
  private tempData: any;
  closeResult: string;
  public hour: any;
  locationForm: FormGroup;
  private minute: any;
  public selectedDate: Date;
  tripDate: any;
  public bsConfig: {};
  data: any;
  date: any;
  location: any;
  alertForm: FormGroup;
  tab = 1;
  errorMsg = false;
  step = 1;
  priorityType: any;
  filterValue: any;
  assets: any = {
    alldata: [],
  };
  alertsListing: any = [];
  alertsLogListing: any = [];
  arrLatLong: any = [];
  selectZOneType: any;
  masterSelected: boolean;
  masterContactSelected: boolean;
  checkedList = [];
  checkedListContact = [];
  notify: any = {
    sms: false,
    email: false,
    onScreen: false,
  };
  zoneAlertList: any = [];
  oneAtATime: boolean = true;
  contactList: any = [];
  items = ["Item 1", "Item 2", "Item 3"];
  keepInList = [];
  locationList = [];
  noGoList = [];
  routeList = [];
  entryLocationList = [];
  entryNoGoList = [];
  exitLocationList = [];
  exitKeepInList = [];
  errorAlertName = false;
  errorPrioritytype = false;
  errorSelectAssest = false;
  errorNotify = false;
  errorAlertZone = false;
  errorContact = false;
  searchContactValue = "";
  searchAssetValue = "";

  motions: any = [
    { key: "1", value: false, name: "Engine Off" },
    { key: "2", value: false, name: "Engine On" },
    { key: "3", value: false, name: "Motion Off" },
    { key: "4", value: false, name: "Motion On" },
  ];
  powers: any = [
    { key: "1", value: false, name: "Unit Powering On" },
    { key: "2", value: false, name: "Unit Powering off" },
    { key: "3", value: false, name: "Main Power Low" },
  ];

  trips: any = [
    { key: "1", value: false, name: "Start Trip" },
    { key: "2", value: false, name: "End Trip" },
  ];

  clusterStyles: ClusterStyle[];
  zoomIn = 15;
  minZoom = 3;
  zoom = 5;
  infoWindowOpened = null;
  previous_info_window = null;
  searchAlert = "";
  currentCountry: any;
  dropDownCountry: any;

  latLong = [76.7796, 30.7334];
  url =
    "https://www.google.com.sa/maps/search/" +
    30.7220257 +
    "," +
    76.8462109 +
    "," +
    "12.21z?hl + =en";
  openedWindow = 0;
  selectedCountry = "";

  constructor(
    private api: ApiService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.myModel = new model();
    this.tripDate = moment(new Date()).format("DD-MM-YYYY");
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
      }
    );
    this.date = moment(new Date()).format("DD-MM-YYYY");
    this.currentCountry = this.sessionService.get("selectedCountry");

    this.priorityType = [
      { name: "Low", value: "3" },
      { name: "Medium", value: "2" },
      { name: "High", value: "1" },
    ];
    this.masterSelected = false;
    this.masterContactSelected = false;
    this.checkedList = [];
    this.checkedListContact = [];
    this.clusterStyles = [
      {
        textColor: "#000000",
        url: "assets/img/map-icons/m1.png",
        height: 36,
        width: 58,
        backgroundPosition: "center",
      },
    ];

    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        console.log("change country", res);
        this.currentCountry = res;

        if (this.router.url === "/alerts") {
          this.getAlerts();
          this.step = 1;
        }

        //this.getAssets();
      }
    });
  }

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.myModel.myDate = "";

    this.getAlerts();
    // this.getAssets();
    this.step = 1;

    fromEvent(this.searchElement.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => {
          console.log(event, "eventttttt");
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2 || res.length === 0),
        // Time in milliseconds between key events
        debounceTime(1000)
        // If previous query is diffent from curren
        // distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.searchAlerts(text);
      });
    this.sessionService.currentData.subscribe((res) => {
      if (res) {
        this.tempData = res;
        console.log("socket data", res);
        this.arrLatLong.forEach((ele, index) => {
          if (ele.imeiNumber === this.tempData.imeiNumber) {
            // console.log(ele, index, 'before');
            Object.keys(this.tempData).forEach((key) => {
              ele[key] = this.tempData[key];
              this.arrLatLong[index][key] = this.tempData[key];
              // ele['currentLatLong'] = this.latLong;
            });
          }
        });
      }
    });

    this.sessionService.currentaAlertCount.subscribe((res) => {
      if (res) {
        console.log("change count", res);

        if (res.alertId) {
          this.alertsListing.forEach((ele, index) => {
            if (ele._id === res.alertId) {
              ele.alertCount = ele.alertCount + 1;
            }
          });
        }
      }
    });
  }

  addItem(): void {
    this.items.push(`Item ${this.items.length + 1}`);
  }

  restFilert() {
    this.searchElement.nativeElement.value = "";
    this.filterValue = "";
    this.myModel.myDate = "";
    this.searchAlert = "";
    this.bsValue = "";
    this.getAlerts();
  }
  checkUncheckAll() {
    for (let i = 0; i < this.myModel.alldata.length; i++) {
      this.myModel.alldata[i].isSelected = this.masterSelected;
    }
    this.checkedList = [];
    this.getCheckedItemList();
  }

  checkUncheckAllContact() {
    for (let i = 0; i < this.contactList.length; i++) {
      this.contactList[i].value = this.masterContactSelected;
    }
    this.checkedListContact = [];
    this.errorContact = false;
    this.getCheckedContactList();
  }

  isAllSelected() {
    this.masterSelected = this.myModel.alldata.every(function (item: any) {
      return item.isSelected == true;
    });
    this.checkedList = [];
    this.getCheckedItemList();
    this.errorSelectAssest = false;
  }
  isAllContactSelected() {
    this.masterContactSelected = this.contactList.every(function (item: any) {
      return item.value == true;
    });
    this.checkedListContact = [];
    this.getCheckedContactList();
  }

  getCheckedItemList() {
    //this.checkedList = [];
    for (let i = 0; i < this.myModel.alldata.length; i++) {
      if (
        this.myModel.alldata[i].isSelected &&
        this.checkedList.indexOf(this.myModel.alldata[i].imeiNumber) < 0
      ) {
        this.checkedList.push(this.myModel.alldata[i].imeiNumber);
        this.errorSelectAssest = false;
      }
    }
    console.log(this.checkedList);
    this.checkedList = this.checkedList;
  }
  getCheckedContactList() {
    // this.checkedListContact = [];
    for (let i = 0; i < this.contactList.length; i++) {
      if (
        this.contactList[i].value &&
        this.checkedListContact.indexOf(this.contactList[i].email) < 0
      ) {
        this.checkedListContact.push(this.contactList[i].email);
      }
    }
    console.log(this.checkedListContact);
    this.checkedListContact = this.checkedListContact;
  }

  getAlertAssestList(value) {
    let obj = {};
    if (value) {
      obj["alertId"] = value;
    }
    // obj["workingCountry"] = JSON.stringify(this.currentCountry);
    this.api
      .postData(this.apiConstant.ALERTS.GET_ALERT_ASSETS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.arrLatLong = res.data;
          if (this.arrLatLong.length) {
            if (!this.arrLatLong[0].currentLatLong) {
              this.arrLatLong[0].currentLatLong = [0, 0];
            }
          }
        }
      });
  }

  searchAssets(event) {
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    console.log(this.checkedList);
    let checkedListOld = this.checkedList;
    let obj = {};
    obj = {
      searchText: event.target.value,
    };
    obj["workingCountry"] = JSON.stringify(this.currentCountry);
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          res.data.forEach((e) => {
            checkedListOld.forEach((item) => {
              console.log(item, e.imeiNumber);
              if (e.imeiNumber == item) {
                e.isSelected = true;
              }
            });
          });
          this.myModel.alldata = res.data;
          this.masterSelected = this.myModel.alldata.every(function (
            item: any
          ) {
            return item.isSelected == true;
          });
          this.errorSelectAssest = false;
        }
      });
  }
  filterAssets(i, value) {
    let obj = {};
    if (this.searchAlert.length) {
      obj = {
        searchText: this.searchAlert,
      };
    }
    this.filterValue = value;
    if (this.myModel.myDate) {
      obj["date"] = moment(this.myModel.myDate).format("YYYY-MM-DD");
    }

    obj["priority"] = value;
    obj["workingCountry"] = JSON.stringify(this.currentCountry);
    this.api
      .getData(this.apiConstant.ALERTS.LIST_ALERTS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.alertsListing = res.data;
          this.alertsListing.forEach((e) => {
            e.isCollapsed = true;
          });
        }
      });
  }
  searchContact(event) {
    // if (this.myModel.searchItem.length < 3 || this.myModel.searchItem.length === 0) {
    //     return false;
    // }
    let checkedListContactOld = [];
    checkedListContactOld = this.checkedListContact;
    const obj = {
      searchText: event.target.value,
    };
    obj["workingCountry"] = JSON.stringify([this.selectedCountry]);
    this.api
      .getData(this.apiConstant.ALERTS.LIST_CONTACT.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          /* for (let i = 0; i < this.contactList.length; i++){
            this.contactList[i].value = false;
          }*/
          res.data.forEach((e) => {
            e.value = false;
            checkedListContactOld.forEach((item) => {
              if (item == e.email) {
                e.value = true;
              }
            });
          });

          this.contactList = res.data;
          this.masterContactSelected = this.contactList.every(function (
            item: any
          ) {
            return item.value == true;
          });
          this.errorContact = false;
        }
      });
  }

  searchAlerts(value) {
    let obj = {};
    obj = {
      searchText: value,
    };
    if (this.filterValue) {
      obj["priority"] = this.filterValue;
    }
    if (this.myModel.myDate) {
      obj["date"] = moment(this.myModel.myDate).format("YYYY-MM-DD");
    }
    obj["workingCountry"] = JSON.stringify(this.currentCountry);
    this.api
      .getData(this.apiConstant.ALERTS.LIST_ALERTS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.alertsListing = res.data;
          this.alertsListing.forEach((e) => {
            e.isCollapsed = true;
          });
          this.getAlertAssestList(res.data[0]._id);
        }
      });
  }

  getDate(event) {
    if (event) {
      this.myModel.myDate = event;
      let obj = {};
      if (this.searchAlert.length) {
        obj = {
          searchText: this.searchAlert,
        };
      }
      if (this.filterValue) {
        obj["priority"] = this.filterValue;
      }
      obj["date"] = moment(event).format("YYYY-MM-DD");
      obj["workingCountry"] = JSON.stringify(this.currentCountry);
      this.api
        .getData(this.apiConstant.ALERTS.LIST_ALERTS.URL, obj, true)
        .subscribe((res) => {
          if (res.data) {
            this.alertsListing = res.data;
            this.alertsListing.forEach((e) => {
              e.isCollapsed = true;
            });
          }
        });
    }
  }
  createform() {
    this.locationForm = this.fb.group({
      assignmentId: ["", Validators.required],
      expiredAt: ["", Validators.required],
      date: ["", Validators.required],
      time: ["", Validators.required],
      email: ["", Validators.required],
      minutes: [""],
    });

    this.alertForm = this.fb.group({
      alertName: ["", Validators.required],
    });
  }

  getAssets() {
    let obj = {};
    if (this.myModel.activeDevice) {
      obj["activeDevice"] = this.myModel.activeDevice;
    }
    obj["workingCountry"] = JSON.stringify([this.selectedCountry]);
    this.api
      .postData(this.apiConstant.ASSETS.ASSET_LISTING.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          // console.log(res.data)
          if (res.data[0] && res.data[0].imeiNumber) {
            this.selectedImei = res.data[0].imeiNumber;
          }
        }
      });
  }
  getZoneAlertList() {
    let obj = {};
    obj["workingCountry"] = JSON.stringify([this.selectedCountry]);
    this.api
      .postData(this.apiConstant.ZONES.ZONE_GET_ZONES_ALERTS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.zoneAlertList = res.data;
          this.clearZoneScreenData();
          this.selectZOneType = 1;
          for (let i = 0; this.zoneAlertList.length > i; i++) {
            if (this.zoneAlertList[i].zoneType == 1) {
              this.keepInList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              this.exitKeepInList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              for (let i = 0; i < this.keepInList.length; i++) {
                this.keepInList[i].value = false;
                this.exitKeepInList[i].value = false;
              }
            }
            if (this.zoneAlertList[i].zoneType == 2) {
              this.noGoList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              this.entryNoGoList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              for (let i = 0; i < this.noGoList.length; i++) {
                this.noGoList[i].value = false;
                this.entryNoGoList[i].value = false;
              }
            }
            if (this.zoneAlertList[i].zoneType == 3) {
              this.locationList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              this.entryLocationList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              this.exitLocationList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              for (let i = 0; i < this.locationList.length; i++) {
                this.locationList[i].value = false;
                this.entryLocationList[i].value = false;
                this.exitLocationList[i].value = false;
              }
            }
            if (this.zoneAlertList[i].zoneType == 4) {
              this.routeList = JSON.parse(
                JSON.stringify(this.zoneAlertList[i].data)
              );
              for (let i = 0; i < this.routeList.length; i++) {
                this.routeList[i].value = false;
              }
            }
          }

          // console.log(res.data);
        }
      });
  }
  getAlerts() {
    let obj = {};
    /* if (this.filterValue) {
      obj['filter'] = this.filterValue;
    } */
    if (this.filterValue) {
      obj["priority"] = this.filterValue;
    }
    if (this.myModel.myDate) {
      obj["date"] = moment(this.myModel.myDate).format("YYYY-MM-DD");
    }
    if (this.searchElement.nativeElement.value) {
      obj["searchText"] = this.searchElement.nativeElement.value;
    }
    this.alertsListing = [];
    obj["workingCountry"] = JSON.stringify(this.currentCountry);
    this.api
      .getData(this.apiConstant.ALERTS.LIST_ALERTS.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.alertsListing = res.data.reverse();
          this.alertsLogListing = [];
          this.alertsListing.forEach((e) => {
            e.isCollapsed = true;
          });

          this.getAlertAssestList(res.data[0]._id);
        }
      });
  }

  getAlertsListing(alert) {
    if (alert.getAlertsListing) {
      return false;
    }
    let value = alert._id;
    if (alert.isCollapsed) {
      return;
    } else {
      let isAlertPresent = false;
      this.alertsListing.forEach((e) => {
        if (e._id != value) {
          e.isCollapsed = true;
        } else {
          e.isCollapsed = false;
          if (e.alertCount > 0) {
            isAlertPresent = true;
          }
        }
      });
      this.alertsLogListing = [];
      let obj = {};
      if (value) {
        obj["alertId"] = value;
      }
      // obj["workingCountry"] = JSON.stringify(this.currentCountry);
      if (isAlertPresent) {
        this.api
          .postData(this.apiConstant.ALERTS.GET_ALERT_LOGS.URL, obj, true)
          .subscribe((res) => {
            if (res.data) {
              this.alertsLogListing = res.data;

              this.alertsLogListing.forEach((e) => {
                e.isCollapsed = true;
              });
              this.getAlertAssestList(value);
            }
          });
      }
    }
  }

  getContactListing() {
    let obj = {};
    obj["workingCountry"] = JSON.stringify([this.selectedCountry]);
    this.api
      .getData(this.apiConstant.ALERTS.LIST_CONTACT.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.contactList = res.data;
          for (let i = 0; i < this.contactList.length; i++) {
            this.contactList[i].value = false;
          }
          this.masterContactSelected = false;
          this.getCheckedContactList();
        }
      });
  }

  getAllImeiNumber(element) {
    this.errorMsg = false;
    if (this.myModel.imeiArray.length > 0) {
      const index: number = this.myModel.imeiArray.indexOf(element.imeiNumber);
      //  console.log(index);
      if (index !== -1) {
        this.myModel.imeiArray.splice(index, 1);
      } else {
        if (this.myModel.imeiArray.length >= 5) {
          this.errorMsg = true;
          // this.sessionService.warninglength();
          return false;
        }
        this.myModel.imeiArray.push(element.imeiNumber);
      }
    } else {
      this.myModel.imeiArray.push(element.imeiNumber);
    }
  }

  checkElement(element) {
    if (this.myModel.imeiArray.length > 0) {
      const index: number = this.myModel.imeiArray.indexOf(element.imeiNumber);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  next() {
    this.errorPrioritytype = false;
    this.errorAlertName = false;
    this.errorSelectAssest = false;
    this.errorNotify = false;
    this.errorAlertZone = false;
    this.errorContact = false;
    if (this.step === 0) {
      this.dropDownCountry.forEach((k, i) => {
        if (k.selected) {
          this.selectedCountry = k.country;
        }
      });
      if (!this.selectedCountry) {
        this.errorAlertName = true;
        return;
      } else {
        this.errorAlertName = false;
      }
      this.step = 1;
      this.getAssets();
      this.getCheckedItemList();
      this.getContactListing();
      this.setNotifyObject();
      this.getZoneAlertList();
      this.checkUncheckAll();
      return;
    }
    if (this.step < 4) {
      if (this.step == 1) {
        let assetLength = this.checkedList.length;
        this.alertName = this.alertName.trim();
        if (!this.alertName.length) {
          console.log("please enter alert name");
          this.errorAlertName = true;
          return;
        } else if (this.tab < 0) {
          console.log("please select  Priority");
          this.errorPrioritytype = true;
          return;
        } else if (!assetLength) {
          console.log("plesae select assest");
          this.errorSelectAssest = true;
          return;
        } else {
          let obj = {};
          if (this.alertName) {
            obj["name"] = this.alertName;
          }
          obj["workingCountry"] = JSON.stringify(this.currentCountry);
          this.api
            .postData(
              this.apiConstant.ALERTS.VALIDATE_ALERT_NAME.URL,
              obj,
              true
            )
            .subscribe((res) => {
              if (res.data) {
                this.step = this.step + 1;
                this.clearZoneScreenData();
                this.getZoneAlertList();
                return;
              }
            });
        }
      }
      if (this.step == 2) {
        let powerEvent = [];
        let motionEvent = [];
        let tripEvent = [];
        powerEvent = this.powers.filter(function (item) {
          return item.value == true;
        });
        if (powerEvent.length) {
          powerEvent = powerEvent.map(function (item) {
            if (item.value == true) {
              return item.key;
            }
          });
        }
        motionEvent = this.motions.filter(function (item) {
          return item.value == true;
        });
        if (motionEvent.length) {
          motionEvent = motionEvent.map(function (item) {
            if (item.value == true) {
              return item.key;
            }
          });
        }
        tripEvent = this.trips.filter(function (item) {
          return item.value == true;
        });
        if (tripEvent.length) {
          tripEvent = tripEvent.map(function (item) {
            if (item.value == true) {
              return item.key;
            }
          });
        }
        let keepInList = this.keepInList.filter(function (item) {
          return item.value == true;
        });
        if (keepInList.length) {
          keepInList = keepInList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let locationList = this.locationList.filter(function (item) {
          return item.value == true;
        });
        if (locationList.length) {
          locationList = locationList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let noGoList = this.noGoList.filter(function (item) {
          return item.value == true;
        });
        if (noGoList.length) {
          noGoList = noGoList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let entryLocationList = this.entryLocationList.filter(function (item) {
          return item.value == true;
        });
        if (entryLocationList.length) {
          entryLocationList = entryLocationList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let entryNoGoList = this.entryNoGoList.filter(function (item) {
          return item.value == true;
        });
        if (entryNoGoList.length) {
          entryNoGoList = entryNoGoList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let exitLocationList = this.exitLocationList.filter(function (item) {
          return item.value == true;
        });
        if (exitLocationList.length) {
          exitLocationList = exitLocationList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let exitKeepInList = this.exitKeepInList.filter(function (item) {
          return item.value == true;
        });
        if (exitKeepInList.length) {
          exitKeepInList = exitKeepInList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        let routeList = this.routeList.filter(function (item) {
          return item.value == true;
        });

        if (routeList.length) {
          routeList = routeList.map(function (item) {
            if (item.value == true) {
              return item.zoneId;
            }
          });
        }
        if (
          routeList.length ||
          powerEvent.length ||
          motionEvent.length ||
          tripEvent.length ||
          keepInList.length ||
          locationList.length ||
          noGoList.length ||
          exitKeepInList.length ||
          exitLocationList.length ||
          entryNoGoList.length ||
          entryLocationList.length
        ) {
          this.searchContactValue = "";
          this.checkedListContact = [];
          this.getContactListing();
          this.step = this.step + 1;
          return;
        } else {
          this.errorAlertZone = true;
          return;
        }
      }
      if (this.step == 3) {
        if (this.checkedListContact.length) {
          this.setNotifyObject();
          this.step = this.step + 1;
          return;
        } else {
          this.checkedListContact = [];
          this.errorContact = true;
        }
      }
    }
    if (this.step == 4) {
      let notify = [];
      if (this.notify.email) {
        notify.push("email");
      }
      if (this.notify.sms) {
        notify.push("sms");
      }
      if (this.notify.onScreen) {
        notify.push("onScreen");
      }
      if (notify.length) {
        this.createAlert();
      } else {
        this.errorNotify = true;
      }
    }
  }

  back() {
    if (this.step > 0) {
      this.step = this.step - 1;
    }
  }
  close() {
    let ele = document.getElementById("closeM");
    if (ele) {
      ele.click();
    }
  }

  setCountryFromRadio(status, i) {
    console.log(status, i);
    this.dropDownCountry.forEach((k) => {
      k.selected = false;
    });
    this.dropDownCountry[i].selected = true;
  }
  openModal() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    console.log(this.currentCountry);
    this.dropDownCountry = [];
    this.currentCountry.forEach((k, i) => {
      this.dropDownCountry.push({ country: k, selected: false });
    });
    this.dropDownCountry[0].selected = true;
    this.selectedCountry = "";
    this.step = 0;
    this.selectZOneType = 1;
    this.alertName = "";
    this.tab = 1;
    this.masterSelected = false;
    this.masterContactSelected = false;
    this.errorPrioritytype = false;
    this.errorAlertName = false;
    this.errorSelectAssest = false;
    this.errorNotify = false;
    this.errorAlertZone = false;
    this.errorContact = false;
    this.searchContactValue = "";
    this.searchAssetValue = "";

    let element = document.getElementById("createAlertModal") as HTMLElement;
    element.click();
  }

  open(content) {
    this.modalService
      .open(content, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = "Closed with: $result";
        },
        (reason) => {
          console.log("closed");
          this.closeResult = "Dismissed $this.getDismissReason(reason)";
        }
      );
  }

  sendLocation() {
    this.locationForm.controls["assignmentId"].setValue(
      this.selectedAsset.assignmentId
    );
    if (this.locationForm.valid) {
      this.locationForm.controls["expiredAt"].setValue(
        this.selectedDate.toISOString()
      );
      let obj = {};
      Object.assign(obj, this.locationForm.value);
      delete obj["date"];
      delete obj["time"];
      delete obj["minutes"];
      // console.log(this.locationForm.value, 'form_value');
      obj["workingCountry"] = JSON.stringify(this.currentCountry);
      this.api
        .postData(this.api.apiConstants.ASSETS.SHARE_LOCATION.URL, obj, true)
        .subscribe((res) => {
          let element = document.getElementById("closeModal") as HTMLElement;
          element.click();
          Swal.fire(
            "Sent Successfully",
            "Location sent successfully!",
            "success"
          );

          // this.setActivePath(typeConst.VERIFY_OTP);
        });
    } else {
      this.sessionService.setTouched(this.locationForm);
    }
  }
  getdateEvent(event: any) {
    if (event) {
      this.date = moment(event).format("LL");
      this.myModel.myDate = moment(event).format("YYYY-MM-DD");
    }
  }

  selectAsset(value: any) {
    this.selectedImei = value.imeiNumber;
  }
  checkNofify(value: any) {
    this.notify[value] = this.notify[value];
  }

  motionSelected(name: any) {
    this.motions.forEach((element) => {
      if (element.name === name) {
        element.value = !element.value;
      } else {
        element.value = false;
      }
    });
  }
  entryLocationSelected(value) {
    this.entryLocationList.forEach((element) => {
      if (element.zoneName === value) {
        element.value = !element.value;
      } else {
        element.value = false;
      }
    });
    this.changeDetectorRef.detectChanges();
  }
  exitLocationSelected(value) {
    this.exitLocationList.forEach((element) => {
      if (element.zoneName === value) {
        element.value = !element.value;
      } else {
        element.value = false;
      }
    });
    this.changeDetectorRef.detectChanges();
  }
  zoneLocationSelected(value) {
    this.locationList.forEach((element) => {
      if (element.zoneName === value) {
        element.value = !element.value;
      } else {
        element.value = false;
      }
    });
    this.changeDetectorRef.detectChanges();
  }
  selectZone(value) {
    this.selectZOneType = value;
  }
  createAlert() {
    let notify = [];
    let notifyOthers = [];
    let powerEvent = [];
    let motionEvent = [];
    let tripEvent = [];
    if (this.notify.email) {
      notify.push("email");
    }
    if (this.notify.sms) {
      notify.push("sms");
    }
    if (this.notify.onScreen) {
      notify.push("onScreen");
    }
    notifyOthers = this.checkedListContact;

    powerEvent = this.powers.filter(function (item) {
      return item.value == true;
    });
    if (powerEvent.length) {
      powerEvent = powerEvent.map(function (item) {
        if (item.value == true) {
          return item.key;
        }
      });
    }
    motionEvent = this.motions.filter(function (item) {
      return item.value == true;
    });
    if (motionEvent.length) {
      motionEvent = motionEvent.map(function (item) {
        if (item.value == true) {
          return item.key;
        }
      });
    }
    tripEvent = this.trips.filter(function (item) {
      return item.value == true;
    });
    if (tripEvent.length) {
      tripEvent = tripEvent.map(function (item) {
        if (item.value == true) {
          return item.key;
        }
      });
    }
    let keepInList = this.keepInList.filter(function (item) {
      return item.value == true;
    });
    if (keepInList.length) {
      keepInList = keepInList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let locationList = this.locationList.filter(function (item) {
      return item.value == true;
    });
    if (locationList.length) {
      locationList = locationList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let noGoList = this.noGoList.filter(function (item) {
      return item.value == true;
    });
    if (noGoList.length) {
      noGoList = noGoList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let entryLocationList = this.entryLocationList.filter(function (item) {
      return item.value == true;
    });
    if (entryLocationList.length) {
      entryLocationList = entryLocationList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let entryNoGoList = this.entryNoGoList.filter(function (item) {
      return item.value == true;
    });
    if (entryNoGoList.length) {
      entryNoGoList = entryNoGoList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let exitLocationList = this.exitLocationList.filter(function (item) {
      return item.value == true;
    });
    if (exitLocationList.length) {
      exitLocationList = exitLocationList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let exitKeepInList = this.exitKeepInList.filter(function (item) {
      return item.value == true;
    });

    if (exitKeepInList.length) {
      exitKeepInList = exitKeepInList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let routeList = this.routeList.filter(function (item) {
      return item.value == true;
    });

    if (routeList.length) {
      routeList = routeList.map(function (item) {
        if (item.value == true) {
          return item.zoneId;
        }
      });
    }
    let zoneEnterId = [
      { location: entryLocationList },
      { noGo: entryNoGoList },
    ];
    let zoneExitId = [
      { location: exitLocationList },
      { keepIn: exitKeepInList },
    ];
    let overSpeedZoneId = [
      { location: locationList },
      { keepIn: keepInList },
      { route: routeList },
      { noGo: noGoList },
    ];
    let isZoneEntry = false;
    if (entryLocationList.length || entryNoGoList.length) {
      isZoneEntry = true;
    }
    let isZoneExit = false;
    if (exitKeepInList.length || exitLocationList.length) {
      isZoneExit = true;
    }
    let isOverSpeedZone = false;
    if (
      locationList.length ||
      noGoList.length ||
      keepInList.length ||
      routeList.length
    ) {
      isOverSpeedZone = true;
    }
    let isZone = false;
    if (isZoneEntry || isZoneExit || isOverSpeedZone) {
      isZone = true;
    }
    const obj = {
      name: this.alertName,
      priority: this.tab,
      imeiNumber: this.checkedList,
      notifyVia: notify,
      notifyTo: notifyOthers,
      isZone: isZone,
      zoneEnter: isZoneEntry,
      zoneEnterId: zoneEnterId,
      zoneExit: isZoneExit,
      zoneExitId: zoneExitId,
      zoneOverSpeed: isOverSpeedZone,
      overSpeedZoneId: overSpeedZoneId,
      isTrip: tripEvent.length ? true : false,
      trip: tripEvent.length ? tripEvent : "",
      isPowerEvent: powerEvent.length ? true : false,
      powerEvent: powerEvent.length ? powerEvent : "",
      isMotionEvent: motionEvent.length ? true : false,
      motionEvent: motionEvent.length ? motionEvent : "",
    };
    console.log(obj);
    obj["workingCountry"] = JSON.stringify([this.selectedCountry]);
    this.api
      .postData(this.apiConstant.ALERTS.CREATE_ALERT.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          // console.log(res.data);

          this.getAlerts();
          this.close();
        }
      });
  }
  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  updateBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
    this.arrLatLong.forEach((marker) => {
      const tempPosition = new google.maps.LatLng(
        marker.currentLatLong[1],
        marker.currentLatLong[0]
      );
      this.mapBounds.extend(tempPosition);
    });
    console.log(this.mapInstance, this.mapBounds);
    this.mapInstance.fitBounds(this.mapBounds);
    //this.mapInstance.setZoom(8);
  }
  openNav() {
    this.hasFullView = false;
    document.getElementById("myNav").classList.toggle("toggleSideList");
    document.getElementById("myMap").classList.toggle("toggleMap");
    if (document.getElementById("myNav").classList.contains("toggleSideList")) {
      this.hasFullView = true;
    }
  }

  openMap() {
    this.ListingBtn = true;
    document.getElementById("myMap").classList.toggle("mapFullscreen");
    if (document.getElementById("myMap").classList.contains("mapFullscreen")) {
      this.ListingBtn = false;
    }
  }
  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }
  setTripArray() {
    this.trips = [
      { key: "1", value: false, name: "Start Trip" },
      { key: "2", value: false, name: "End Trip" },
    ];
  }
  setPowerArray() {
    this.powers = [
      { key: "1", value: false, name: "Unit Powering On" },
      { key: "2", value: false, name: "Unit Powering off" },
      { key: "3", value: false, name: "Main Power Low" },
    ];
  }
  setMotionsArray() {
    this.motions = [
      { key: "1", value: false, name: "Engine Off" },
      { key: "2", value: false, name: "Engine On" },
      { key: "3", value: false, name: "Motion Off" },
      { key: "4", value: false, name: "Motion On" },
    ];
  }
  setNotifyObject() {
    this.notify = {
      sms: false,
      email: false,
      onScreen: false,
    };
  }
  clearZoneScreenData() {
    this.keepInList = [];
    this.locationList = [];
    this.noGoList = [];
    this.routeList = [];
    this.entryLocationList = [];
    this.entryNoGoList = [];
    this.exitLocationList = [];
    this.exitKeepInList = [];
    this.setMotionsArray();
    this.setPowerArray();
    this.setTripArray();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      setTimeout(() => {
        this.mapBounds = new google.maps.LatLngBounds();
      }, 1000);
      this.agmMap.mapReady.subscribe((map) => {
        this.mapInstance = map;
        console.log(this.mapInstance, "mapInstance");
      });
    }, 2000);
  }
}
