import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AgmMap} from "@agm/core";
import {ClusterStyle} from "@agm/js-marker-clusterer/services/google-clusterer-types";
import {model} from "../../shared/common.constant";
import {API_CONSTANT} from "../../shared/api.constants";
import {SessionService} from "../../services/session.service";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute} from "@angular/router";

declare var google: any;

@Component({
    selector: 'app-track-asset',
    templateUrl: './track-asset.component.html',
    styleUrls: ['./track-asset.component.scss']
})
export class TrackAssetComponent implements OnInit {
    @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;
    @ViewChild('infoWindow', {static: false}) infoWindow: ElementRef;

    infoWindowOpened = null;
    previous_info_window = null;
    myModel = new model();
    apiConstant = API_CONSTANT;
    zoom = 2;
    assetValue = false;
    arrLatLong = [];
    private mapBounds: any;
    private mapInstance: any;
    private selectedAsset: any;
    public index: any;
    public address: any;
    public tempData;
    latLong = [76.7796, 30.7334];
    icon = [
        {
            url: '/assets/img/grey-truck-0degree.png',
            scaledSize: {width: 50, height: 70}
        },
        {
            url: '/assets/img/green-truck-0degree.png',
            scaledSize: {width: 50, height: 70}
        },
        {
            url: "/assets/img/orange-icon-svg.svg",
            scaledSize: {width: 70, height: 70}
        },
        {
            url: '/assets/img/from-location.png',
            scaledSize: {width: 50, height: 60}
        },
    ];
    private token: any;

    constructor(private sessionService: SessionService,
                private api: ApiService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(param => {
            this.token = param['token'];
            this.trackAsset();
        });
    }


    close_window() {
        if (this.previous_info_window != null) {
            this.previous_info_window.close();
        }
    }

    select_marker(infoWindow) {
        if (this.previous_info_window == null)
            this.previous_info_window = infoWindow;
        else {
            this.infoWindowOpened = infoWindow;
            this.previous_info_window.close();
        }
        this.previous_info_window = infoWindow;
    }

    calculateOdometerDistance(lat1, lon1, lat2, lon2) {
        return this.sessionService.calculateOdometerDistance(lat1, lon1, lat2, lon2);

    }

    assetInfo(i, type) {
        this.index = i;
        this.selectedAsset = this.arrLatLong[i];
        this.getaddress(this.selectedAsset);
        this.mapInstance.setCenter(new google.maps.LatLng(this.selectedAsset.currentLatLong[1], this.selectedAsset.currentLatLong[0]));
        // this.mapInstance.setZoom(8);
        if (type === 'assetList') {
            this.close_window();
        }
    }

    trackAsset() {
        let obj = {
            token: this.token
        }
        this.api.postData(this.apiConstant.ASSETS.TRACK_ASSET.URL, obj, true).subscribe(res => {
            if (res.data) {
                this.myModel.alldata = res.data;
                this.arrLatLong = res.data;
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.mapBounds = new google.maps.LatLngBounds();
        }, 1000);
        this.agmMap.mapReady.subscribe(map => {
            this.mapInstance = map;
            this.updateBounds();

            // this.updateMarkerList();
        });
    }

    updateBounds() {
        this.mapBounds = new google.maps.LatLngBounds();
        this.arrLatLong.forEach(marker => {
            const tempPosition = new google.maps.LatLng(marker.assetData.currentLatLong[1], marker.assetData.currentLatLong[0]);
            this.mapBounds.extend(tempPosition);

        });
        console.log(this.mapInstance, this.mapBounds);
        this.mapInstance.fitBounds(this.mapBounds);
        // this.mapInstance.setZoom(8);
    }

    async getaddress(selectedAsset) {
        this.address = await this.sessionService.getAddressusingLatLong(selectedAsset.assetData.currentLatLong[1], selectedAsset.assetData.currentLatLong[0]);
    }


}
