import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {BsDropdownModule} from "ngx-bootstrap";
import {ToastrModule} from "ngx-toastr";
import {TagInputModule} from "ngx-chips";
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {AgmCoreModule, InfoWindowManager, MarkerManager} from '@agm/core';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {AuthGuardService} from './services/authguard.service'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {AppComponent} from "./app.component";
import {AdminLayoutComponent} from "./layouts/admin-layout/admin-layout.component";
import {AppRoutingModule} from "./app-routing.module";
import {ComponentsModule} from "./components/components.module";
import {LoginComponent} from './views/login/login.component';
import {VehiclesComponent} from './views/vehicles/vehicles.component';
import {PaginationModule} from "ngx-bootstrap/pagination";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgxPrintModule} from "ngx-print";
import {AddVehicleComponent} from './views/add-vehicle/add-vehicle.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {HardwaresComponent} from './views/hardwares/hardwares.component';
import {SimComponent} from './views/sim/sim.component';
import {AddSimComponent} from './views/add-sim/add-sim.component';
import {AddHardwareComponent} from './views/add-hardware/add-hardware.component';
import {UserRolesComponent} from './views/user-roles/user-roles.component';
import {AddUserRoleComponent} from './views/add-user-role/add-user-role.component';
import {InterceptorService} from "./services/interceptor.service";
import {DeleteComponent} from "./shared/modals/delete/delete.component";
import {AgmJsMarkerClustererModule, ClusterManager} from "@agm/js-marker-clusterer";
import {AssetInfoComponent} from './views/asset-info/asset-info.component';
import {NgChartjsModule} from "ng-chartjs";
import {RedirectingComponent} from './views/redirecting/redirecting.component';
import {InvalidUserComponent} from './views/invalid-user/invalid-user.component';
import {AssetListingComponent} from './views/asset-listing/asset-listing.component';
import {TrackAssetComponent} from './views/track-asset/track-asset.component';
import {TripsComponent} from './views/trips/trips.component';
import {TrackLiveTruckComponent} from './views/agm-maps/track-live-truck/track-live-truck.component';
import {AgmMapsComponent} from './views/agm-maps/agm-maps.component';
import { AgmOverlays } from "agm-overlays"
import {ListFilterPipe} from '../Directive/search.pipe';
import {ZonesComponent} from './views/zones/zones.component';
import {FilterZonePipe} from "../Directive/search-zone.pipe";
import {NumberOnlyDirective} from "../Directive/numOnly.directive";
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import {NgxPaginationModule} from "ngx-pagination";
import { DriverComponent } from './views/driver/driver.component';
import { DriverLiveLocationComponent } from './views/driver/driver-live-location/driver-live-location.component';
import { DirectionDirective } from '../Directive/direction.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PastLocationDirective } from '../Directive/past-location.directive';
import { DatePipe } from '@angular/common';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ComponentsModule,
        NgChartjsModule.registerPlugin(),
        HttpClientModule,
        RouterModule,
        BsDropdownModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 3000,
            progressAnimation: 'increasing',
            preventDuplicates: true,
            // resetTimeoutOnDuplicate: true,
            progressBar: true,
        }),
        NgCircleProgressModule.forRoot({
            radius: 100,
            animationDuration: 300,
        }),
        CollapseModule.forRoot(),
        TagInputModule,
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgxDatatableModule,
        NgxPrintModule,
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule,
        AgmOverlays,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8',
                        // apiKey: 'AIzaSyDuGjsHa9ZXtwTGSQvP76c-DG2A-18VXJw',
            libraries: ['drawing', 'geometry','places','directions'],
        }),
        AgmJsMarkerClustererModule,
        NgbModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        TimepickerModule.forRoot()
    ],
    declarations: [AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        AgmMapsComponent,
        VehiclesComponent,
        AddVehicleComponent,
        HardwaresComponent,
        SimComponent,
        AddSimComponent,
        AddHardwareComponent,
        UserRolesComponent,
        AddUserRoleComponent,
        DeleteComponent,
        AssetInfoComponent,
        RedirectingComponent,
        InvalidUserComponent,
        AssetListingComponent,
        TrackAssetComponent,
        TripsComponent,
        ListFilterPipe,
        TrackLiveTruckComponent,
        ZonesComponent,
        FilterZonePipe,
        NumberOnlyDirective,
        PageNotFoundComponent,
        AlertsComponent,
        DriverComponent,
        DriverLiveLocationComponent,
        DirectionDirective,
        PastLocationDirective],
    providers: [
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },DatePipe
    ],
    exports: [
        ListFilterPipe,
        FilterZonePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
