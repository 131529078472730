import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {API_CONSTANT} from "../../shared/api.constants";
import {model} from "../../shared/common.constant";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-add-sim',
    templateUrl: './add-sim.component.html',
    styleUrls: ['./add-sim.component.scss']
})
export class AddSimComponent implements OnInit {

    model;
    hardwareForm: FormGroup;
    apiConstant;

    constructor(private fb: FormBuilder,
                private api: ApiService,
                private router: Router,
                private route: ActivatedRoute
    ) {
        this.model = new model();
        this.apiConstant = API_CONSTANT;

    }


    ngOnInit() {
        this.hardwareForm = this.fb.group({
            gsmNo: new FormControl(''),
            description: new FormControl(''),
            serialNumber: new FormControl(''),
            networkProvider: new FormControl(''),
            puk1Code: new FormControl(''),
            puk2Code: new FormControl(''),
            pin1: new FormControl(''),
            pin2: new FormControl(''),
        });
        this.route.queryParams.subscribe(param => {
            if (param) {
                this.model.editId = param.id;
                this.model.heading = 'Edit';
                this.getDataByID();
            }
        });
    }





    getDataByID() {
        let body = {};
        body['simcardId'] = this.model.editId;
        this.api.getData(this.apiConstant.SIM_CARD.LIST_SIM_CARD_BY_ID.URL, body, true).subscribe(res => {
            if (res.data.length) {
                this.model.alldata = res.data[0];
                this.patchValues();
            }
        });
    }


    patchValues() {
        this.hardwareForm.patchValue({
            gsmNo: this.model.alldata.gsmNo,
            description: this.model.alldata.description,
            serialNumber: this.model.alldata.serialNumber,
            networkProvider: this.model.alldata.networkProvider,
            puk1Code: this.model.alldata.puk1Code,
            puk2Code: this.model.alldata.puk2Code,
            pin1: this.model.alldata.pin1,
            pin2: this.model.alldata.pin2,
        });
    }

    onSubmit() {
        let url: any;
        let obj = {};
        obj = this.hardwareForm.value;
        if (this.model.editId) {
            url = this.apiConstant.SIM_CARD.EDIT_SIM_CARD.URL;
            obj['simcardId'] = this.model.editId;
        } else {
            url = this.apiConstant.SIM_CARD.ADD_SIM_CARD.URL;
        }
        this.api.postData(url, obj, true).subscribe(res => {
            this.router.navigate(['/sim-cards']);
            console.log('done');
        });
    }

}
