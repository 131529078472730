import { Injectable } from "@angular/core";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from "rxjs";
// import {Lightbox} from 'ngx-lightbox';
import { ApiService } from "./api.service";
import { resolve } from "q";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";

declare var google: any;

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private toastr: ToastrService) // private _lightbox: Lightbox,
  {}

  private dataSource = new BehaviorSubject(Array);
  currentData = this.dataSource.asObservable();

  private driverdataSource = new BehaviorSubject<any>(null);
  driverCurrentData = this.driverdataSource.asObservable();

  private assignData = new BehaviorSubject<any>(null);
  assignCurrentData = this.assignData.asObservable();

  private unassignData = new BehaviorSubject<any>(null);
  unassignCurrentData = this.unassignData.asObservable();

  private assignDriver = new BehaviorSubject<any>(null);
  assignCurrentDriver = this.assignDriver.asObservable();

  private unassignDriver = new BehaviorSubject<any>(null);
  unassignCurrentDriver = this.unassignDriver.asObservable();

  private loaderSubject = new BehaviorSubject<any>(null);
  public loaderStatus = this.loaderSubject.asObservable();

  // for internetConnection

  private checkOnline = new BehaviorSubject<any>(null);
  public onlineStatus = this.checkOnline.asObservable();

  private workingCountry = new BehaviorSubject<any>(null);
  currentWorkingCountry = this.workingCountry.asObservable();

  private alertCount = new BehaviorSubject<any>(null);
  currentaAlertCount = this.alertCount.asObservable();

  getInternetStatus(status) {
    this.checkOnline.next(status);
  }

  loaderOn(loaderStatus) {
    this.loaderSubject.next(loaderStatus);
  }

  get(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }

    return null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  setTouched(form) {
    Object.keys(form.controls).forEach((key) => {
      // if (form.controls[key].value.trim() === '') {
      //   form.controls[key].setValue('');
      // }
      // form.controls[key].value.trim();
      form.controls[key].markAsTouched({ onlySelf: true });
    });
  }

  added() {
    this.toastr.success("Added Successfully");
  }
  edited() {
    this.toastr.success("Edited Successfully");
  }

  deleted() {
    this.toastr.success("Deleted Successfully");
  }

  send() {
    this.toastr.success(
      "We have sent the OTP on your registered mobile number"
    );
  }
  error(msg) {
    this.toastr.error(msg);
  }
  warninglength() {
    this.toastr.error("Maximum length exceeded! Max length is 5");
  }
  driverNotRegistered() {
    this.toastr.error("Driver not registered on driver application.");
  }

  noLoactionFound() {
    this.toastr.error("Driver is not registered on driver application.");
  }
  // openLightBox(file) {
  //   let albums = [];
  //   if (file) {
  //
  //     const album = {
  //       src: file,
  //       thumb: file
  //     };
  //     albums.push(album);
  //     this._lightbox.open(albums, 0);
  //     // return true;
  //   }
  // }

  checkfileType(sFileName) {
    console.log(sFileName);
    let _validFileExtensions;
    let blnValid;
    if (sFileName) {
      _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      blnValid = false;
      for (let j = 0; j < _validFileExtensions.length; j++) {
        const sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          console.log("returned", sCurExtension);
          return true;
        }
      }
    }
    return false;
  }

  checkTime() {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getAddressusingLatLong(lat1, long1) {
    return new Promise((resolve) => {
      let lat = parseFloat(lat1);
      let lng = parseFloat(long1);
      let latlng = new google.maps.LatLng(lat, lng);
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            resolve(results[1].formatted_address);

            // console.log(results[1])
            // return results[1].formatted_address;
            // alert("Location: " + results[1].formatted_address);
          }
        }
      });
    });
  }

  calculateAndDisplayRoute() {
    let directionsService = new google.maps.DirectionsService();
    let directionsRenderer = new google.maps.DirectionsRenderer();
    return new Promise((resolve) => {
      directionsService.route(
        {
          origin: {
            query: "gallup, nm",
          },
          destination: {
            query: "los angeles, ca",
          },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            resolve(directionsRenderer.setDirections(response));
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    });
  }

  calculateOdometerDistance(lat1, lon1, lat2, lon2) {
    // lat1, lon1 = startLatlong
    // lat2, lon2 = currentLatlong
    let unit = "K";
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  onNewData(data) {
    console.log(data, "data");
    this.dataSource.next(data);
  }
  // onAssignNewData(data) {
  //   console.log(data, "data");
  //   this.assignData.next(data);
  // }
  // onUnassignNewData(data) {
  //   console.log(data, "data");
  //   this.unassignData.next(data);
  // }
  // onAssignDriver(data) {
  //   console.log(data, "data");
  //   this.assignDriver.next(data);
  // }
  // onUnassignDriver(data) {
  //   console.log(data, "data");
  //   this.unassignDriver.next(data);
  // }


  onNewDriverData(data) {
    console.log(data, "data driver");
    this.driverdataSource.next(data);
  }

  onNewAlertCount(data) {
    console.log(data, "alertCount");
    this.alertCount.next(data);
  }
  updateWorkingCountry(data) {
    console.log(data, "workingCountry");
    this.workingCountry.next(data);
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
