import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {AuthGuardService} from './services/authguard.service'
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';

import {LoginComponent} from './views/login/login.component';
import {VehiclesComponent} from './views/vehicles/vehicles.component';
import {AddVehicleComponent} from './views/add-vehicle/add-vehicle.component';
import {HardwaresComponent} from './views/hardwares/hardwares.component';
import {SimComponent} from './views/sim/sim.component';
import {AddSimComponent} from './views/add-sim/add-sim.component';
import {AddHardwareComponent} from './views/add-hardware/add-hardware.component';
import {UserRolesComponent} from './views/user-roles/user-roles.component';
import {AddUserRoleComponent} from './views/add-user-role/add-user-role.component';
import {AgmMapsComponent} from "./views/agm-maps/agm-maps.component";
import {AssetInfoComponent} from "./views/asset-info/asset-info.component";
import {RedirectingComponent} from "./views/redirecting/redirecting.component";
import {InvalidUserComponent} from './views/invalid-user/invalid-user.component';
import {TrackAssetComponent} from "./views/track-asset/track-asset.component";
import {TripsComponent} from "./views/trips/trips.component";
import {TrackLiveTruckComponent} from "./views/agm-maps/track-live-truck/track-live-truck.component";
import { DriverComponent } from './views/driver/driver.component';
import { DriverLiveLocationComponent } from './views/driver/driver-live-location/driver-live-location.component';

import {ZonesComponent} from "./views/zones/zones.component";
import {PageNotFoundComponent} from "./views/page-not-found/page-not-found.component";
import {AlertsComponent} from "./views/alerts/alerts.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'invalid-user',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'track-asset-live-location',
        component: TrackAssetComponent
    },
    {
        path: 'redirecting-user',
        component: RedirectingComponent
    },
    {
        path: 'invalid-user',
        component: InvalidUserComponent
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'hardware-devices',
                component: HardwaresComponent
            },
            {
                path: 'add-hardware',
                component: AddHardwareComponent
            },
            {
                path: 'sim-cards',
                component: SimComponent
            },
            {
                path: 'add-sim-card',
                component: AddSimComponent
            },
            {
                path: 'user-roles',
                component: UserRolesComponent
            },
            {
                path: 'add-user-role',
                component: AddUserRoleComponent
            },
            {
                path: 'maps',
                component: AgmMapsComponent,
                //canActivate: [AuthGuardService]
            },
            {
                path: 'drivers',
                component: DriverComponent,
                //canActivate: [AuthGuardService]
            },

            {
                path: 'asset-info',
                component: AssetInfoComponent,
                canActivate: [AuthGuardService]
            },

            {
                path: 'zones',
                component: ZonesComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'trips',
                component: TripsComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'track-truck',
                component: TrackLiveTruckComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'track-driver',
                component: DriverLiveLocationComponent,
                canActivate: [AuthGuardService]
            },
            
            {
                path: 'alerts',
                component: AlertsComponent,
                canActivate: [AuthGuardService]
            },

        ]
    },
    {
        path: '**',
        redirectTo: 'page-not-found'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [RouterModule],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule {
}
