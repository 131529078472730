import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AgmMap } from "@agm/core";
import { ClusterStyle } from "@agm/js-marker-clusterer/services/google-clusterer-types";
import { model } from "../../shared/common.constant";
import { API_CONSTANT, EMAIL_REGEX } from "../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { SocketService } from "../../services/socket.service";
import { SessionService } from "../../services/session.service";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { $ } from "protractor";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import * as moment from "moment";
import { IndividualConfig, ToastrService } from "ngx-toastr";

declare var google: any;

@Component({
  selector: "app-zones",
  templateUrl: "./zones.component.html",
  styleUrls: ["./zones.component.scss"],
})
export class ZonesComponent implements OnInit {
  @ViewChild("AgmMap", { static: false }) agmMap: AgmMap;
  @ViewChild("infoWindow", { static: false }) infoWindow: ElementRef;
  modalOption: NgbModalOptions = null;

  minZoom = 3;
  myModel;
  apiConstant = API_CONSTANT;
  zoom = 5;
  public heading = "Add";
  public address: any;
  latLong = [0, -180];
  center: any = {
    lat: 24.886,
    lng: -70.268,
  };
  private map: any;
  public zoneForm: FormGroup;
  private closeResult: any;
  public allZone: any;
  private currentEvent: any;
  public selectedZoneId: any;
  private drawingManager: any;
  private mapBounds: any;
  private mapInstance: any;
  oldIndex: any;
  date;
  bsValue = "";
  maxDate = new Date();
  public bsConfig: {};
  tab = 4;
  maxsize = 2;
  zoneType = "";
  currentCountry: any;

  constructor(
    private api: ApiService,
    private socket: SocketService,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.myModel = new model();
    // this.date = moment(new Date()).format('DD-MM-YYYY');
    // this.myModel.myDate = moment(this.date).format('YYYY-MM-DD');

    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
        isAnimated: true,
        containerClass: "theme-orange",
      }
    );

    this.sessionService.currentWorkingCountry.subscribe((res) => {
      if (res) {
        console.log("change country", res);
        this.currentCountry = res;

        if (this.router.url === "/zones") {
          this.getZoneType();
          this.createForm();
          this.getAllZones();
          this.undoMaps();
        }
        this.selectedZoneId = "";
        //this.getAssets();
      }
    });
  }

  ngOnInit() {
    this.currentCountry = this.sessionService.get("selectedCountry");
    this.getZoneType();
    this.createForm();
    this.getAllZones();
  }

  addclass(index) {
    // const elem: HTMLElement = document.getElementById('mySidenav-right');
    // if (elem) {
    //     elem.hasClass
    // }

    console.log("opened", this.oldIndex);
    // if( document.getElementById('submenu' + index))
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
    }
    this.oldIndex = index;
    document.getElementById("submenu" + index).classList.add("show");
    // document.getElementById('mobile-logo').classList.add('intro');
  }

  removeClass(index) {
    document.getElementById("submenu" + index).classList.remove("show");
    document.getElementById("mainmenu").classList.remove("show");
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
    }
  }

  removeClassMain() {
    document.getElementById("mainmenu").classList.remove("show");
    if (this.oldIndex) {
      document
        .getElementById("submenu" + this.oldIndex)
        .classList.remove("show");
    }
  }

  createForm() {
    let onlyNumber = "^[0-9]*$";
    this.zoneForm = this.fb.group({
      zoneId: [""],
      zoneType: [""],
      zoneName: ["", Validators.required],
      maxSpeed: new FormControl("", [Validators.required]),
      workingCountry: ["", Validators.required],
      status: [1, Validators.required],
      location: [""],
      contactEmail: new FormControl("", [
        Validators.required,
        this.sessionService.noWhitespaceValidator,
        Validators.pattern(EMAIL_REGEX),
      ]),
    });
  }

  openModal() {
    if (!this.selectedZoneId) {
      this.heading = "Add";
    } else {
      this.heading = "Edit";
    }
    console.log("modal called");
    const element = document.getElementById(
      "shareLiveLocationModal"
    ) as HTMLElement;
    element.click();
  }

  open(content) {
    this.modalService
      .open(content, {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "lg",
      })
      .result.then(
        (result) => {
          this.closeResult = "Closed with: $result";
        },
        (reason) => {
          console.log("closed");
          this.closeResult = "Dismissed $this.getDismissReason(reason)";
        }
      );
  }

  addZone() {
    if (this.zoneForm.valid) {
      let obj = {};
      obj = this.zoneForm.value;
      if (this.selectedZoneId) {
        obj["id"] = this.selectedZoneId;
      }
      // obj['workingCountry'] = this.currentCountry;
      let apiName = this.apiConstant.ZONES.CREATE_ZONE.URL;
      if (this.selectedZoneId) {
        apiName = this.apiConstant.ZONES.EDIT_ZONE.URL;
        delete obj["zoneId"];
      }
      if (parseFloat(obj["maxSpeed"]) <= 0) {
        this.toastr.error("Maximum speed cannot be 0 or less", "OOPS!");
        return false;
      }
      this.api.postData(apiName, obj, true).subscribe((res) => {
        this.tab = 4;
        if (res.data) {
          if (this.selectedZoneId) {
            this.sessionService.edited();
          } else {
            this.sessionService.added();
          }

          this.undoMaps();
          const element = document.getElementById("closeModal") as HTMLElement;
          element.click();
          this.getAllZones();
          this.createForm();
          this.selectedZoneId = "";
        }
      });
    } else {
      this.sessionService.setTouched(this.zoneForm);
    }
  }

  onMapReady(map) {
    this.map = map;
  }

  editZone(zone) {
    this.undoMaps();
    this.heading = "Edit";
    this.selectedZoneId = zone._id;
    const polygonCoords = [];
    this.zoneType = zone.zoneType;
    let currentCountryIndex = 0;
    zone.countrySortName.forEach((x, i) => {
      if (this.currentCountry.indexOf(x) >= 0) {
        currentCountryIndex = i;
      }
    });
    this.zoneForm.patchValue({
      workingCountry: zone.countrySortName[currentCountryIndex],
      zoneType: zone.zoneType,
      zoneName: zone.zoneName,
      maxSpeed: zone.maxSpeed.toString(),
      contactName: zone.contactName,
      contactNumber: zone.contactNumber,
      status: zone.status,
      contactEmail: zone.contactEmail,
      location: JSON.stringify(zone.location),
    });

    if (zone.zoneType === "polygon") {
      zone.location[0].forEach((ele, key) => {
        polygonCoords.push({ lat: "", lng: "" });
      });
      zone.location[0].forEach((ele, key) => {
        polygonCoords[key].lat = ele[1];
        polygonCoords[key].lng = ele[0];
      });
      this.updateBounds(zone, "polygon");

      this.myModel.edit_drawing = new google.maps.Polygon({
        strokeColor: "#FF0000",
        strokeOpacity: 0,
        strokeWeight: 3,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        editable: true,
        paths: polygonCoords,
      });

      this.myModel.edit_drawing.setMap(this.map);
      google.maps.event.addListener(
        this.myModel.edit_drawing.getPath(),
        "insert_at",
        (event) => {
          this.finalArrayPolygon(
            this.myModel.edit_drawing.getPath().getArray()
          );
          console.log(
            this.myModel.edit_drawing.getPath().getArray(),
            "inserting new"
          );
          // google.maps.event.addListener(this.myModel.edit_drawing, 'insert_at',);
        }
      );
      google.maps.event.addListener(
        this.myModel.edit_drawing.getPath(),
        "set_at",
        (event) => {
          console.log(this.myModel.edit_drawing.getPath().getArray(), "setAt");
          this.finalArrayPolygon(
            this.myModel.edit_drawing.getPath().getArray()
          );
        }
      );

      // console.log(this.myModel.edit_drawing.getPath().getArray(), 'setAt');
      // this.currentEvent = this.myModel.edit_drawing;
    } else if (zone.zoneType === "circular") {
      this.zoneForm.addControl(
        "radius",
        new FormControl("", Validators.required)
      );
      this.zoneForm.patchValue({
        radius: zone.radius.toFixed(2),
      });
      this.updateBounds(zone, "circular");
      let radius;
      const mainArr = [];
      const arr = [];
      const center = { lat: zone.location[1], lng: zone.location[0] };
      console.log(zone.location, "location");
      this.myModel.edit_drawing = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
        center,
        radius: zone.radius,
        editable: true,
      });
      console.log(zone.location, "location-2");
      google.maps.event.addListener(
        this.myModel.edit_drawing,
        "radius_changed",
        (event) => {
          radius = this.myModel.edit_drawing.getRadius();

          this.zoneForm.controls.radius.setValue(radius);
          console.log(this.myModel.edit_drawing.getRadius());
        }
      );
      google.maps.event.addListener(
        this.myModel.edit_drawing,
        "center_changed",
        (event) => {
          arr.push(
            this.myModel.edit_drawing.center.lng(),
            this.myModel.edit_drawing.center.lat()
          );
          // console.log(this.myModel.edit_drawing.center.lat(),this.myModel.edit_drawing.center.lng());
          // mainArr.push(arr);
          this.zoneForm.controls.location.setValue(JSON.stringify(arr));
        }
      );
    } else {
      console.log("polyline");
      zone.location.forEach((ele, key) => {
        polygonCoords.push({ lat: "", lng: "" });
      });

      zone.location.forEach((ele, key) => {
        polygonCoords[key].lat = ele[1];
        polygonCoords[key].lng = ele[0];
      });
      console.log(polygonCoords, "polygonCoords");
      this.updateBounds(zone, "polyline");
      this.myModel.edit_drawing = new google.maps.Polyline({
        path: polygonCoords,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        editable: true,
      });

      this.myModel.edit_drawing.setMap(this.map);

      google.maps.event.addListener(
        this.myModel.edit_drawing.getPath(),
        "insert_at",
        (event) => {
          this.finalArrayPolyline(
            this.myModel.edit_drawing.getPath().getArray()
          );
          console.log(
            this.myModel.edit_drawing.getPath().getArray(),
            "inserting new"
          );
          // google.maps.event.addListener(this.myModel.edit_drawing, 'insert_at',);
        }
      );
      google.maps.event.addListener(
        this.myModel.edit_drawing.getPath(),
        "set_at",
        (event) => {
          console.log(this.myModel.edit_drawing.getPath().getArray(), "setAt");
          this.finalArrayPolyline(
            this.myModel.edit_drawing.getPath().getArray()
          );
        }
      );
    }
  }

  drawPolygon() {
    this.zoneType = "polygon";
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        // drawingModes: ['circle']
      },
      polygonOptions: {
        draggable: true,
        strokeWeight: 0,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (this.drawingManager.getDrawingMode()) {
          this.drawingManager.setDrawingMode(null);
          this.drawingManager.setMap(null);
          this.drawingManager.setOptions({
            drawingControl: false,
          });
        }
        // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          this.finalArrayPolygon(event.overlay.getPath().getArray())
        );
        this.openModal();
        this.currentEvent = event;
      }
    );
  }

  finalArrayPolygon(data) {
    let arr = [];
    const mainArr = [];
    const wrapArr = [];
    data.forEach((latlng, key) => {
      arr = [];
      const lat = latlng.lat();
      const lon = latlng.lng();
      arr.push(lon, lat);
      mainArr.push(arr);
    });
    mainArr.push(mainArr[0]);
    wrapArr.push(mainArr);
    console.log(mainArr, "final array");
    this.zoneForm.controls.location.setValue(JSON.stringify(wrapArr));
  }

  finalArrayPolyline(data) {
    let arr = [];
    const mainArr = [];
    // const wrapArr = []
    data.forEach((latlng, key) => {
      arr = [];
      const lat = latlng.lat();
      const lon = latlng.lng();
      arr.push(lon, lat);
      mainArr.push(arr);
    });

    console.log(mainArr, "final array");
    this.zoneForm.controls.location.setValue(JSON.stringify(mainArr));
  }

  drawCircle() {
    this.zoneType = "circular";
    this.zoneForm.addControl(
      "radius",
      new FormControl("", Validators.required)
    );
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
        // drawingModes: ['circle']
      },
      circleOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
      drawingMode: google.maps.drawing.OverlayType.CIRCLE,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (this.drawingManager.getDrawingMode()) {
          this.drawingManager.setDrawingMode(null);
          this.drawingManager.setMap(null);
          this.drawingManager.setOptions({
            drawingControl: false,
          });
        }
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          this.finalArrayCircle(event.overlay)
        );
        this.currentEvent = event;
      }
    );
  }

  finalArrayCircle(event) {
    console.log(event, "circular event");
    let radius;
    const arr = [];
    const mainArr = [];
    radius = event.getRadius();
    arr.push(event.getCenter().lng(), event.getCenter().lat());
    // mainArr.push(arr);
    radius = radius.toFixed(2);
    this.zoneForm.controls.radius.setValue(radius);
    this.zoneForm.controls.location.setValue(JSON.stringify(arr));
    this.openModal();
  }

  drawPolyline() {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYLINE],
        // drawingModes: ['circle']
      },
      polygonOptions: {
        draggable: true,
        strokeWeight: 0,
        fillOpacity: 0.45,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (this.drawingManager.getDrawingMode()) {
          this.drawingManager.setDrawingMode(null);
          this.drawingManager.setMap(null);
          this.drawingManager.setOptions({
            drawingControl: false,
          });
        }
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          this.finalArrayPolyline(event.overlay.getPath().getArray())
        );
        this.openModal();
        this.currentEvent = event;
      }
    );
  }

  getZoneType() {
    let obj = {};
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ZONES.GET_ZONE_TYPES.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.myModel.alldata = res.data;
          this.myModel.alldata.forEach((ele) => {
            if (ele.subTypes.length) {
              ele.toggle = "dropdown";
            } else {
              ele.toggle = "";
            }
          });
        }
        console.log(this.myModel.alldata, "after edit");
      });
  }

  getAllZones() {
    let obj = {};
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ZONES.LIST_ZONES.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.allZone = res.data;
        }
      });
  }

  getAssets() {
    let obj = {};
    obj["skip"] = this.myModel.skip;
    obj["limit"] = this.myModel.limit;
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ZONES.ZONE_ASSETS.URL, obj, true)
      .subscribe((res) => {
        if (res && res.data && res.data.list) {
          res.data.list.forEach((ele) => {
            ele["zoneIdName"] = this.checkZoneType(ele["zoneId"]);
          });
          this.myModel.zone_assets = res.data.list;
          this.myModel.totalData = res.data.totalData;
          // this.allZone = res.data;
        }
      });
  }

  getActivites() {
    let obj = {};
    obj["date"] = this.myModel.myDate;
    obj["skip"] = this.myModel.skip;
    obj["limit"] = this.myModel.limit;
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ZONES.ZONE_ACTIVITY.URL, obj, true)
      .subscribe((res) => {
        if (res && res.data && res.data.list) {
          res.data.list.forEach((ele) => {
            ele["zoneIdName"] = this.checkZoneType(ele["zoneId"]);
          });
          this.myModel.zone_activity = res.data.list;
          this.myModel.totalData = res.data.totalData;
          // this.allZone = res.data;
        }
      });
  }

  getdateEvent(event) {
    console.log(event);
    if (event) {
      // this.date = moment(event).format('LL');
      this.myModel.myDate = event;
      let obj = {};
      obj["date"] = moment(event).format("YYYY-MM-DD");
      obj["skip"] = this.myModel.skip;
      obj["limit"] = this.myModel.limit;
      obj["workingCountry"] = this.currentCountry;
      this.api
        .postData(this.apiConstant.ZONES.ZONE_ACTIVITY.URL, obj, true)
        .subscribe((res) => {
          if (res && res.data && res.data.list) {
            res.data.list.forEach((ele) => {
              ele["zoneIdName"] = this.checkZoneType(ele["zoneId"]);
            });
            this.myModel.zone_activity = res.data.list;
            this.myModel.totalData = res.data.totalData;
            // this.allZone = res.data;
          }
        });
    }
  }
  restDateFilert() {
    this.bsValue = null;
    this.myModel.myDate = "";
    this.getActivites();
  }

  getType(type, subtypeIndex, typeIndex) {
    console.log(typeIndex, "typeeeeeeeeeee");
    let value;
    // value = this.getTYPEID(type);
    this.zoneForm.controls["zoneId"].setValue(type.zoneId);
    if (subtypeIndex === 1) {
      this.zoneForm.controls["zoneType"].setValue("circular");
      this.drawCircle();
    } else if (subtypeIndex === 0) {
      this.zoneForm.controls["zoneType"].setValue("polygon");
      this.drawPolygon();
    } else if (typeIndex === 2) {
      this.zoneForm.controls["zoneType"].setValue("polyLine"); // keeping the key same for index because the subtype can't be 2
      this.drawPolyline();
    }
  }

  getTYPEID(type) {
    switch (type.zoneType) {
      case "KEEP IN ZONE":
        return 1;
      case "NO-GO ZONE":
        return 2;
      case "LOCATION":
        return 3;
      case "ROUTE":
        return 4;
    }
    // zoneId =  1 = KEEP IN ZONE, 2 = NO-GO ZONE, 4 = LOCATION, 3 = ROUTE
  }

  confirmDelete(index) {
    // this.index = index;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover the zone!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.delete(index);
        this.selectedZoneId = "";
        this.myModel.edit_drawing.setMap();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your assignment is safe :)',
        //   'error'
        // )
      }
    });
  }

  delete(index) {
    const obj = {};
    obj["delete"] = true;
    obj["id"] = this.allZone[index]._id;
    obj["workingCountry"] = this.currentCountry;
    this.api
      .postData(this.apiConstant.ZONES.EDIT_ZONE.URL, obj, true)
      .subscribe((res) => {
        if (res.data) {
          this.getAllZones();
          Swal.fire("Deleted", "Zone deleted successfully.", "success");
          // this.allZone[index].
        }
      });
  }

  undoMaps() {
    if (this.currentEvent) {
      this.currentEvent.overlay.setMap(null);
    }
    if (this.myModel.edit_drawing) {
      this.myModel.edit_drawing.setMap(null);
    }
    if (this.drawingManager) {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setMap(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    }
    this.selectedZoneId = "";
    this.createForm();
  }

  closeModal() {
    this.undoMaps();
    this.createForm();
    if (!this.selectedZoneId) {
      this.heading = "Add";
    } else {
      this.heading = "Edit";
    }
    this.selectedZoneId = "";
  }

  updateBounds(zone, type) {
    console.log(this.mapInstance, "mapinsatnce");
    this.mapBounds = new google.maps.LatLngBounds();
    if (type === "polygon") {
      zone.location[0].forEach((marker) => {
        const tempPosition = new google.maps.LatLng(marker[1], marker[0]);
        this.mapBounds.extend(tempPosition);
      });
    } else if (type === "circular") {
      const tempPosition = new google.maps.LatLng(
        zone.location[1],
        zone.location[0]
      );
      this.mapBounds.extend(tempPosition);
    } else {
      zone.location.forEach((marker) => {
        const tempPosition = new google.maps.LatLng(marker[1], marker[0]);
        this.mapBounds.extend(tempPosition);
      });
    }
    // this.myModel.alldata.forEach(marker => {
    //     const tempPosition = new google.maps.LatLng(marker.currentLatLong[1], marker.currentLatLong[0]);
    //     this.mapBounds.extend(tempPosition);
    //
    // });
    // console.log(this.mapInstance, this.mapBounds);
    this.map.fitBounds(this.mapBounds);
    this.map.setZoom(6);
  }

  reset() {
    this.myModel.pageNumber = 1;
    this.myModel.skip = 0;
    this.myModel.totalData = 0;
  }

  getPageChangeEvent(event) {
    this.myModel.pageNumber = event.page;
    this.myModel.skip = (this.myModel.pageNumber - 1) * 10;
    if (this.tab === 2) {
      this.getActivites();
    } else if (this.tab === 2) {
      this.getAssets();
    }
  }

  checkZoneType(id) {
    // 1 = KEEP IN ZONE, 2 = NO-GO ZONE, 3 = LOCATION, 4 = ROUTE
    switch (id) {
      case 1:
        return "KEEP IN ZONE";
      case 2:
        return "NO-GO ZONE";
      case 3:
        return "LOCATION";
      case 4:
        return "ROUTE";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // setTimeout(() => {
      //     this.mapBounds = new google.maps.LatLngBounds();
      // }, 1000);
      this.agmMap.mapReady.subscribe((map) => {
        this.mapInstance = map;
        console.log(this.mapInstance, "mapInstance");
      });
    }, 2000);
  }
}
