
export class model {
    alldata = [];
    imeiArray = [];
    startDate: any;
    endDate: any;
    searchItem: any;
    myDate: any;
    date: any;
    singleAssetData: any;
    multipleAssetData: any;
    show = false;
    editId = '';
    heading = '';
    activeDevice = '';
    // lat: number = 51.678418;
    // lng: number = 7.809007;
    lat = 24.774265;
    lng = 46.738586;
    tab = 'filter';
    skip = 0;
    pageNumber = 1
    limit = 10;
    bsValue = new Date();
    zoneStatus = [{value: 0, name: 'Inactive'}, {value: 1, name: 'Active'}]
    edit_drawing: any;
    color_combo = [
        '#1985ff',
        '#0cff0e',
        '#b979ff',
        '#ff2a08',
        '#ffc731'
    ];
    totalData = 0
    zone_activity:any;
    zone_assets:any;
    workingCountries = [
      { value: "UAE", isSelected: false },
      { value: "PAK",  isSelected: false },
      { value: "KSA",  isSelected: false },
    ];
    TruckArray = [
      {text:"Pickups",value:"1",sub:[{text:"1 Ton Pickup",value:"14"},{text:"3 Tons Open Pickup",value:"15"},{text:"3 Tons Closed Pickup",value:"16"},{text:"4.2 Tons Closed Pickup",value:"17"},{text:"7 Tons Open Pickup",value:"18"},{text:"10 Tons Pickup",value:"19"},{text:"10 Tons Open Pickup",value:"20"},{text: "Suzuki Pickup",value: '35'},{text: "Shehzore Pickup",value: '36'}]},
      {text: "Flatbeds",value: '2', sub:[{text:"12m Flatbed",value:"21"},{text:"13.6m Flatbed",value:"22"},{text:"15.3 Flatbeds",value:"23"}]},
      {text: "Curtain Side",value: '3', sub:[{text:"13.6m Curtain side",value:"24"},{text:"15.3m Curtain side",value:"25"}]},
      {text: "Closed Box",value: '4', sub:[{text:"13.6m Closed Box",value:"26"},{text:"15.3m Closed Box",value:"27"}]},
      {text: "Reefers",value: '5', sub:[{text:"13.6m Reefer",value:"28"},{text:"15.3m Reefer",value:"29"}]},
      {text: "Lowbed",value: '6', sub:[{text:"40 Ton Lowbed",value:"30"},{text:"60 Ton Lowbed",value:"31"},{text:"80 Ton Lowbed",value:"32"}]},
      {text: "Tipper",value: '7', sub:[{text:"40 Ton Tipper",value:"33"},{text:"80 Ton Tipper",value:"34"}]},
     {text:"Others",value:"0",sub:[{text: "Container Trucks",value: '1'},{text: "Curtain Trailer",value: '2'},{text: "Dyna Trucks-10 Tons",value: '3'},{text: "Flatbed",value: '4'},{text: "Pickup Trucks",value: '6'},{text: "Pickup Truck-10 Tons",value: '7'},{text: "Pickup Truck-3 Tons",value: '8'},{text: "Reefers",value: '9'},{text: "Trailer(40ft)",value: '10'},{text: "Big Truck",value: '11'},{text: "Small Truck",value: '12'},{text: "Box Truck",value: '13'},{text: "Trailer(20ft)",value: '37',},
     {text: "Mazda(16ft)",value: '38'},]},
    ];
    multipleMarkers = [
        {
            url: '/assets/img/multi-asset-markers/blue.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/blue1.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/green.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/green1.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/purple.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/purple1.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/source.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/destination.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/yellow.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/multi-asset-markers/yellow1.png',
            // scaledSize: {width: 50, height: 60}
        },
        {
          url: '/assets/img/path.png',
          scaledSize: {width: 10, height: 10}
        },
    ]
    startIcon = [
        {
            url: '/assets/img/markers/yellow1.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/start1.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/start2.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/start3.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/start4.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/start5.png',
            scaledSize: {width: 50, height: 60}
        },
    ];

    destinationIcons = [
        {
            url: '/assets/img/markers/yellow.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/end1.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/end2.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/end3.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/end4.png',
            scaledSize: {width: 50, height: 60}
        },
        {
            url: '/assets/img/markers/end5.png',
            scaledSize: {width: 50, height: 60}
        },
    ];

    icon = [
        {
            // 0
            url: '/assets/img/grey-truck-0degree.png',
            scaledSize: {width: 60, height: 60}
        },
        {
            // 1
            url: '/assets/img/green-truck-0degree.png',
            scaledSize: {width: 60, height: 60}
        },
        {
            // 2
            url: '/assets/img/orange-icon-svg.svg',
            scaledSize: {width: 60, height: 60}
        },

        {
            // 3
            url: '/assets/img/from-location.png',
            scaledSize: {width: 50, height: 50}
        },
        {
            // 4
            url: '/assets/img/to-location.png',
            scaledSize: {width: 50, height: 50}
        },
        {
            // 5
            url: '/assets/img/red-marker.png',
            scaledSize: {width: 50, height: 50}
        },
        {
            // 6
            url: '/assets/img/source.png',
            scaledSize: {width: 20, height: 20}
        },
        {
            // 7
            url: '/assets/img/destination.png',
            scaledSize: {width: 20, height: 20}
        },
        {
            // 8
            url: '/assets/img/yellow-icon.png',
            scaledSize: {width: 25, height: 25}
        },
        {
            // 9
            url: '/assets/img/grey-icon.png',
            scaledSize: {width: 25, height: 25}
        },
        {
            // 10
            url: '/assets/img/grey-icon.png',
            scaledSize: {width: 10, height: 10},
            scale: 1
        },
        {
          // 11
          url: '/assets/img/red-driver.png',
          scaledSize: {width: 50, height: 50}
       },
       {
        // 12
        url: '/assets/img/green-driver.png',
        scaledSize: {width: 50, height: 50}
      },
      {
        // 13
        url: "/assets/img/orange-icon-svg.svg",
        scaledSize: {width: 40, height: 40}
    }, {path:  "/assets/img/test_truck.png",
        geodesic: true,
        strokeColor: "#33E0FF",
        strokeOpacity: 1.0,
        strokeWeight: 1,
        scale: 1,
        fillColor: 'orange',
        fillOpacity: 0.8,}
        ];
    reporting_time = [
        'UTC -12:00', 'UTC -11:00', 'UTC -10:00', 'UTC -09:00', 'UTC -08:00', 'UTC -07:00', 'UTC -06:00', 'UTC -05:00', 'UTC -04:00',
        'UTC -03:00', 'UTC -02:00', 'UTC -01:00', 'UTC 00:00', 'UTC +01:00', '+UTC 02:00', 'UTC 03:00', 'UTC 03:30', 'UTC 04:00', 'UTC 03:00',
        'UTC +03:30', 'UTC +04:00', 'UTC +04:30', 'UTC 05:00', 'UTC 05:30', 'UTC 05:45', 'UTC 06:00', 'UTC 06:30', 'UTC 07:00',
        'UTC +08:30', 'UTC +08:00', 'UTC +08:30', 'UTC 09:00', 'UTC 09:30', 'UTC 10:00', 'UTC 10:30', 'UTC 11:00',
        'UTC +12:00', 'UTC +12:45', 'UTC +13:00', 'UTC +14:00'
    ];
    ignitionSensing = ['Off default', 'Input 1', 'Input 2', 'Input 3', 'Input 4', 'Software', 'Motion on/off'];
    odometerOveride = ['No (use hardware calculated odometer - Recommended)', 'Yes (use software calculated odometer - Experimental)'];
    hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
        '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
    minutes = ['05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    mapStyleSilver = [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "geometry.stroke",
          "stylers": [
            {
          
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]

}

