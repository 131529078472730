import {Component, OnInit} from '@angular/core';
import {API_CONSTANT} from "../../shared/api.constants";
import {ApiService} from './../../services/api.service';
import Swal from "sweetalert2";
import {Router} from "@angular/router";

export enum SelectionType {
    single = "single",
    multi = "multi",
    multiClick = "multiClick",
    cell = "cell",
    checkbox = "checkbox"
}

@Component({
    selector: 'app-sim',
    templateUrl: './sim.component.html',
    styleUrls: ['./sim.component.scss']
})
export class SimComponent implements OnInit {

    entries: number = 10;
    selected: any[] = [];
    temp = [];
    activeRow: any;
    rows: any = [];
    SelectionType = SelectionType;
    private apiConstant: any;
    isSelected: any;
    show = false;
    private currentRow: any;

    constructor(private api: ApiService,
                private router: Router) {
        this.apiConstant = API_CONSTANT;
        this.getListing();
    }

    getListing() {
        let arr = [];
        let obj = {};

        this.api.getData(this.apiConstant.SIM_CARD.LIST_SIM_CARD.URL, '', true).subscribe(res => {
            if (res.data.length) {
                for (let ele of res.data) {
                    obj = {};
                    obj['gsmNo'] = ele.gsmNo;
                    obj['networkProvider'] = ele.networkProvider;
                    obj['serialNumber'] = ele.serialNumber;
                    obj['_id'] = ele._id;
                    obj['simcardId'] = ele.simcardId;
                    obj['isDeleted'] = ele.isDeleted;
                    arr.push(obj);
                }

                // res.data.forEach(ele => {
                //
                // });
                this.temp = arr.map((prop, key) => {
                    return {
                        ...prop,
                        id: key
                    };
                });
                this.rows = arr;
            }
        });
    }

    entriesChange($event) {
        this.entries = $event.target.value;
    }

    filterTable($event) {
        let val = $event.target.value;
        this.temp = this.rows.filter(function (d) {
            for (var key in d) {
                if (d[key].toLowerCase().indexOf(val) !== -1) {
                    return true;
                }
            }
            return false;
        });
    }

    onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    onActivate(event) {
        this.activeRow = event.row;
        // console.log(this.activeRow)
    }

    ngOnInit() {
    }

    edit() {
        this.router.navigate(['/add-sim-card'], {queryParams: {id: this.activeRow.simcardId}});
        // console.log('called')
    }

    confirmDelete() {
        this.currentRow = this.activeRow;
        console.log('deleted');
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this assignment!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.delete();

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal.fire(
                //   'Cancelled',
                //   'Your assignment is safe :)',
                //   'error'
                // )
            }
        });
    }

    delete() {
        let body = {};
        body['simcardId'] = this.currentRow.simcardId;
        body['isDeleted'] = !this.currentRow.isDeleted;
        this.api.postData(this.apiConstant.SIM_CARD.EDIT_SIM_CARD.URL, body, true).subscribe(res => {
            this.temp.splice(this.currentRow.id, 1);
            this.rows.splice(this.currentRow.id, 1);

        });
    }


    block() {
        let body = {};
        let title = '';
        this.currentRow = this.activeRow;
        body['simcardId'] = this.currentRow.simcardId;
        body['status'] = !this.currentRow.status;
        // this.myModel.assignments.splice(this.index, 1);
        this.api.postData(this.apiConstant.SIM_CARD.EDIT_SIM_CARD.URL, body, true).subscribe(res => {
            if (!this.currentRow.status) {
                title = 'Blocked successfully';
            } else {
                title = 'Unblocked successfully';
            }
            Swal.fire(
                title,
                'success'
            );
        });
    }
}
