import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'filterZone'
})
export class FilterZonePipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        if (list) {
            return list ? list.filter(item => item.zoneName.search(new RegExp(filterText, 'i')) > -1) : [];
        }
    }

}
