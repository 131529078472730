import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.scss']
})
export class InvalidUserComponent implements OnInit {
  enviroment = environment;
  constructor() { }

  ngOnInit() {
  }

}
