import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { API_CONSTANT } from "./../../shared/api.constants";
import { ApiService } from "../../services/api.service";
import { SessionService } from "../../services/session.service";

@Component({
  selector: "app-redirecting",
  templateUrl: "./redirecting.component.html",
  styleUrls: ["./redirecting.component.scss"],
})
export class RedirectingComponent implements OnInit {
  token: any;
  selectedCountry: any;
  apiConstant = API_CONSTANT;
  redirect = true;
  username: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.token = params["key"];
      //this.username = params['username'];
      this.selectedCountry = params["country"];
      //  this.sessionService.set('username', this.username);
      this.sessionService.set("access_token", this.token);
      this.sessionService.set(
        "selectedCountry",
        JSON.parse(this.selectedCountry)
      );
      this.verifyToken();
    });
  }

  verifyToken() {
    let obj = { workingCountry: this.selectedCountry };
    // obj['token'] = this.token;
    this.api
      .postData(this.apiConstant.VALIDATE.VALIDATING_USER.URL, obj, true)
      .subscribe(
        (res) => {
          if (res) {
            this.redirect = true;
            this.username = res.data.name;
            this.sessionService.set(
              "userWorkingCountry",
              res.data.countrySortName
            );
            this.sessionService.set("username", this.username);
            this.sessionService.set("access_token", this.token);
            this.router.navigate(["/maps"]);
          }
        },
        (error) => {
          this.redirect = false;
          this.router.navigate(["/invalid-user"]);
        }
      );
  }
}
